import { Fragment, useState,useEffect,useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useContext } from "react"
import { AuthContext } from "../../../Context"
import { toast } from 'react-toastify';

import { Listbox } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { CheckIcon,XMarkIcon } from '@heroicons/react/24/outline'

import FileUpload from '../../FileUpload';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ModalProduct(props) {
  const cancelButtonRef = useRef(null)

  const {cadastrarProduto, editarProduto, carregarProduto} = useContext(AuthContext)

  const [title,setTitle]= useState('')
  const [description,setDescription]= useState('')
  const [price,setPrice]= useState('')
  const [clausulas, setClausulas] = useState('')
  
  const [duration,setDuration]= useState('1')

  const [selectedFileFoto, setSelectedFileFoto] = useState(null);
  const [selectedFileArquivo, setSelectedFileArquivo] = useState(null);
  
  const [userSelected, setUserSelected] = useState(props.states.users)
  const [companySelected, setCompanySelected] = useState(props.states.companies)

  const [disabled , setDisabled] = useState(false)
  const [idsUserError,setIdsUserError] = useState(false)
  const [idsCompanyError, setIdsCompanyError] = useState(false)

  const [titleError,setTitleError]= useState(false)
  const [descriptionError,setDescriptionError]= useState(false)
  const [priceError,setPriceError]= useState(false)
  const [clausulaError, setClausulaError] = useState(false)

  let [usersList , setUsersList] = useState([])
  let [companyList, setCompanyList] = useState([])

  const addUserOnClick = (e,selected) =>
  {
    try{
      if(!(selected==''))
      {
        e.preventDefault()
        let canAdd = true
        for (let element of usersList)
        {
          if(element.ID == selected.ID)
          {
            canAdd = false
          }
        }
        if(canAdd)
        {
          setUsersList(user => [...user, selected])
          setIdsUserError(false)
        }
        
        setUserSelected('')
      }
    }
    catch(err)
    {
      toast.error(err, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }

  const deleteUserOnClick = (e,idUser) =>
  {
    try{
      e.preventDefault()
      let arrayAux = []
      for (const element of usersList)
      {
        if(!(element.ID == idUser))
        {
          arrayAux.push(element)
        }
      }
      setUsersList(arrayAux)
    }
    catch(err)
    {
      toast.error(err, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }
  
  const addCompanyOnClick = (e,selected) =>
  {
    try{
      if(!(selected==''))
      {
        e.preventDefault()
        let canAdd = true
        for (let element of companyList)
        {
          if(element.ID == selected.ID)
          {
            canAdd = false
          }
        }
        if(canAdd)
        {
          setCompanyList(company => [...company, selected])
          setIdsCompanyError(false)
        }
        
        setCompanySelected('')
      }
    }
    catch(err)
    {
      toast.error(err, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }

  const deleteCompanyOnClick = (e,idCompany) =>
  {
    try{
      e.preventDefault()
      let arrayAux = []
      for (const element of companyList)
      {
        if(!(element.ID == idCompany))
        {
          arrayAux.push(element)
        }
      }
      setCompanyList(arrayAux)
    }
    catch(err)
    {
      toast.error(err, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }

  const onSubmitForm = async (e) =>
  {
    try{
      if(props.states.productSelected!= '')
      {
        editarProdutoOnSubmit(e)
      }
      else
      {
        cadastrarProdutoOnSubmit(e)
      }
    }
    catch(err)
    {
      toast.error(err, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }

  const handleChange = (inputValue) => {
    try {
      if (inputValue.length > 0) {
        const value = inputValue.replace(/[^0-9]/g, '')
        const arrPrice = value.split('')
        if (arrPrice.length > 2) {
          arrPrice.splice(-2, 0, ',')
          const valueString = arrPrice.join('')
          setPrice(valueString)
        } else {
          setPrice(value)
        }
      }
      else {
        setPrice('')
      }
    }
    catch (err) {
      toast.error(err, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const cadastrarProdutoOnSubmit= async (e) =>
  {
    try{
      e.preventDefault()

      let arrayIdsUser = []
      for (const element of usersList)
      {
        let id = element.ID
        arrayIdsUser.push(id)
      }

      let arrayIdsCompany = []
      for (const element of companyList)
      {
        let id = element.ID
        arrayIdsCompany.push(id)
      }

      const preco = parseFloat(price.replace(',', '.'))

      const product = {title: title, description: description, price: preco*100, duration: duration, idsUser: arrayIdsUser, idsCompany: arrayIdsCompany, clausulas: clausulas}
      const verificar = await verificarStatesModalProduct(product)
      if(verificar == true)
      {
        const resultadoLista = await verificarListas()
        if(resultadoLista.length == 0)
        {
          const result = await cadastrarProduto(product, selectedFileFoto, selectedFileArquivo)
          if(result.status == true)
          {
            toast.success('Produto cadastrado com sucesso!', {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
              props.setters.setModalIsOpen(false)
          }
          else
          {
            toast.error(result.msg, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
          }
        }
        else
        {
          toast.error('As empresas dos seguintes vendedores não possuem esse produto. Favor remover o(s) vendedor(es): '+ resultadoLista, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        }
      }
      else
      {
          toast.error('Produto não pôde ser cadastrado!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
      }
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }

  const editarProdutoOnSubmit= async (e) =>
  {
    try{
      e.preventDefault()
  
      let arrayIds = []
      for (const element of usersList)
      {
        let id = element.ID
        arrayIds.push(id)
      }

      let arrayIdsCompany = []
      for (const element of companyList)
      {
        let id = element.ID
        arrayIdsCompany.push(id)
      }
      const preco = parseFloat(price.replace(',', '.'))
      
      const product = {title: title, description: description, price: preco*100, duration: duration, idsUser: arrayIds, idsCompany: arrayIdsCompany, clausulas: clausulas}
      
      const verificar = await verificarStatesModalProduct(product)
      if(verificar == true)
      {
        const resultadoLista = await verificarListas()
        if(resultadoLista.length == 0)
        {
          const result = await editarProduto(props.id,product,selectedFileFoto, selectedFileArquivo)
          if(result.status == true)
          {
            toast.success('Produto editado com sucesso!', {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
              props.setters.setModalIsOpen(false)
          }
          else
          {
            toast.error(result.msg, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
          }
        }
        else
        {
          toast.error('As empresas dos seguintes vendedores não possuem esse produto. Favor remover o(s) vendedor(es): '+ resultadoLista, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        }
      }
      else
      {
          toast.error('Produto não pôde ser editado!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
      }
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }

  const limparStatesModalProduct = () =>
  {
    setTitle('')  
    setDescription('')  
    setPrice('')  
    setClausulas('')
    setSelectedFileFoto('')
    setSelectedFileArquivo('')
    
    setDuration('1')  
    setTitleError(false)
    setDescriptionError(false)
    setPriceError(false)
    setClausulaError(false)
    
    setDisabled(false)

    setUserSelected(``)
    setCompanySelected('')

    setUsersList(``)
    setIdsUserError(false)
    setCompanySelected('')
    setCompanyList('')
    setIdsCompanyError(false)
  }

  const verificarStatesModalProduct = async (product) =>
  {
    try{
      let resultado = true
      for await(const key of Object.keys(product)) {
        if(product[key] == '' || product[key] == undefined || product[key] == null)
        {
          if(key == 'title')   
          {
            resultado = false
            setTitleError(true)
          }
          else if(key== 'description')
          {
            resultado = false
            setDescriptionError(true)
          }
          else if(key == 'price')
          {
            resultado=false
            setPriceError(true)
          }
          else if(key== 'idsCompany')
          {
            resultado = false
            setIdsCompanyError(true)
          }
          else if(key== 'clausulas')
          {
            resultado = false
            setClausulaError(true)
          }
        }
        else
        {
          if(key == 'title')
          {
            setTitleError(false)
          }
          else if(key == 'description')
          {
            setDescriptionError(false)
          }
          else if(key == 'price')
          {
            if(product[key]>1000)
            {
              setPriceError(false)
            }
            else
            {
              resultado = false
              setPriceError(true)
            }
          }
          else if(key== 'idsCompany')
          {
            setIdsCompanyError(false)
          }
          else if(key== 'clausulas')
          {
            setClausulaError(false)
          }
        }
      }
      return resultado
    }
    catch(err)
    {
      return false
    }
  }

  const listarSelectsAsync = async() => {
    try{
      await props.listarUsuarios('All','All','All')
      await props.listarEmpresas('All','All')
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }

  const verificarListas = async() =>
  {
    let usuariosIncorretos = []
    for await(const usuario of usersList)
    {
      let resultado = false;
      const idEmpresa = usuario.CompanyID;
      for (const empresa of companyList) {
        if (empresa.ID === idEmpresa) {
          resultado = true;
          break;
        }
      }
      if (!resultado) {
        usuariosIncorretos.push(usuario.Email);
      }
    }
    return usuariosIncorretos
  }

  useEffect(() => {
    try{
      if(props.states.modalIsOpen === false)
      {
        limparStatesModalProduct()
      }
      else
      {
        listarSelectsAsync()
        if(!(props.id == null || props.id == undefined || props.id == ''))
        {
          carregarProduto(props.id)
        }
        else
        {
          props.setters.setProductSelected('')
          props.setters.setProductSelectedUser('')
        }
      }
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }, [props.states.modalIsOpen]);

  useEffect(() => {
    try{
      if(props.states.productSelected!= '')
      {
        setTitle(props.states.productSelected.Title)
        setDescription(props.states.productSelected.Description)
        setPrice((props.states.productSelected.Price/100).toString().replace('.',','))
        setDuration(props.states.productSelected.Duration)
        setClausulas(props.states.productSelected.Clausulas)
      }
    }
    catch(err)
    {
      toast.error(err, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }, [props.states.productSelected]);

  useEffect(() => {
    try{
      if(props.states.productSelectedUser!= '')
      {
        setUsersList(props.states.productSelectedUser)
      }
    }
    catch(err)
    {
      toast.error(err, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }, [props.states.productSelectedUser]);

  useEffect(() => {
    try{
      if(props.states.productSelectedUser!= '')
      {
        setCompanyList(props.states.productSelectedCompany)
      }
    }
    catch(err)
    {
      toast.error(err, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }, [props.states.productSelectedCompany]);
  
  return (
    <Transition.Root show={props.states.modalIsOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={()=>props.setters.setModalIsOpen(false)} >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"> */}
              <Dialog.Panel className="lg:w-[80%] md:w-[85%] xsm:w-[90%] transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
              <form className="space-y-8 divide-y divide-gray-200" onSubmit={e => onSubmitForm(e)} >
                  <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                    <div className="space-y-6 sm:space-y-5">
                        <div className='flex-col'>
                          <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Produto</h3>
                          <p className="flex mt-1 text-sm text-gray-500 justify-center">
                            Informações do produto
                          </p>
                        </div>

                        <div className="space-y-6 sm:space-y-5">
                          {/* 1 LINHA */}
                          <div className="sm:grid sm:grid-cols-2 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">

                          <div className="mt-2 sm:col-span-1 sm:mt-0">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                            Título
                          </label>
                            <div className="">
                              <input
                                type="text"
                                name="title"
                                id="tile"
                                autoComplete="title"
                                placeholder='Digite o título'
                              //   disabled={disabled}
                                value={title}
                                onChange={e => setTitle(e.target.value)}
                                className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                            {
                              titleError === true && 
                              <p class="mt-2 ml-2 text-pink-600 text-sm">
                                  É obrigatório o preenchimento do título.
                              </p>
                          }
                          </div>

                          <div className="mt-2 sm:col-span-1 sm:mt-0">
                            <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                              Preço
                            </label>
                            <div className="relative mt-2 rounded-md shadow-sm">
                              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <span className="text-gray-500 sm:text-sm">R$</span>
                              </div>
                              <input
                                type="text"
                                name="price"
                                id="price"
                                className="block w-full rounded-md border-0 py-1.5 pl-8 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="0,00"
                                value={price}
                                onChange={e => handleChange(e.target.value, 'price')}
                                // onChange={e => setPrice(e.target.value)}
                                aria-describedby="price-currency"
                              />
                            </div>
                            {
                                priceError === true && 
                                <p class="mt-2 ml-2 text-pink-600 text-sm">
                                    É obrigatório o preenchimento de um preço maior que R$ 10,00.
                                </p>
                              }
                          </div>

                          <div className="mt-2 sm:col-span-2 sm:mt-0">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 p-1">
                            Descrição
                          </label>
                            <div className="">
                              <input
                                  type="text"
                                  name="description"
                                  id="description"
                                  autoComplete="description"
                                  placeholder='Digite a descrição'
                                //   disabled={disabled}
                                  value={description}
                                  onChange={e => setDescription(e.target.value)}
                                className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                            {
                              descriptionError === true && 
                              <p class="mt-2 ml-2 text-pink-600 text-sm">
                                  {/* Forneça um endereço de e-mail válido. */}
                                  É obrigatório o preenchimento da descrição.
                              </p>
                              }
                          </div>

                          <div className="mt-2 sm:col-span-1 sm:mt-0">
                              <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                Foto do produto
                              </label>
                              <div className='pr-1 pl-1'>
                                <div className=''>
                                  <FileUpload selectedFile={selectedFileFoto} setSelectedFile={setSelectedFileFoto} type={'image'}/>
                                </div>
                              </div>
                          </div>

                          <div className="mt-2 sm:col-span-1 sm:mt-0">
                              <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                Arquivo de Condições Gerais
                              </label>
                              <div className='pr-1 pl-1'>
                                <div className=''>
                                  <FileUpload selectedFile={selectedFileArquivo} setSelectedFile={setSelectedFileArquivo} type={'file'}/>
                                </div>
                              </div>
                          </div>

                          <div className="sm:col-span-full sm:gap-4 sm:border-t sm:border-gray-200" />

                          <div className="mt-2 sm:col-span-full sm:mt-0">
                              <Listbox value={userSelected} onChange={setUserSelected}>
                                {({ open }) => (
                                  <>
                                    {/* <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">Inserir produtos</Listbox.Label> */}
                                    <div className="flex items-center justify-between">
                                      <h2 className="block text-sm font-medium leading-6 text-gray-900">Selecionar vendedor(es)</h2>
                                      <button disabled={disabled} type="button" className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500" onClick={e => addUserOnClick(e,userSelected)}>
                                          Adicionar vendedor
                                      </button>
                                    </div>
                                    <div className="relative mt-2">
                                      <Listbox.Button className="flex flex-1 relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                                      {!(userSelected == '' || userSelected == undefined || userSelected == null)?
                                        <span className="inline-flex w-full truncate">
                                          <span className="truncate"> {userSelected.Email} </span>
                                          <span className="ml-2 truncate text-gray-500"> {userSelected.Permission}</span>
                                        </span>
                                        :
                                        <span className="inline-flex w-full truncate">
                                          <span className="truncate">Selecione ...</span>
                                        </span>
                                        }
                                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </span>
                                      </Listbox.Button>
                                      

                                      <Transition
                                        show={open}
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                          {props.states.users.length>0 && props.states.users.map((user) => (
                                            (user.IsDeleted == 0 && user.Permission == `Vendedor`) &&
                                            <Listbox.Option
                                              key={user.ID}
                                              className={({ active }) =>
                                                classNames(
                                                  active ? 'bg-blue-500 text-white' : 'text-gray-900',
                                                  'relative cursor-default select-none py-2 pl-3 pr-9'
                                                )
                                              }
                                              value={user}>
                                                {({ selected, active }) => (
                                                  <>
                                                    <div className="flex">
                                                      <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'truncate')}>
                                                        {user.Email}
                                                      </span>
                                                      <span className={classNames(active ? 'text-indigo-200' : 'text-gray-500', 'ml-2 truncate')}>
                                                        {user.Permission}
                                                      </span>
                                                    </div>

                                                    {selected ? (
                                                      <span
                                                        className={classNames(
                                                          active ? 'text-white' : 'text-indigo-600',
                                                          'absolute inset-y-0 right-0 flex items-center pr-4'
                                                        )}
                                                      >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                      </span>
                                                    ) : null}
                                                  </>
                                                )}
                                            </Listbox.Option>
                                          )
                                          
                                          )}
                                        </Listbox.Options>
                                      </Transition>

                                    </div>
                                    
                                  </>
                                )}
                              </Listbox>
                              {
                                  idsUserError === true && 
                                  <p class="mt-2 ml-1 text-pink-600 text-sm">
                                      É obrigatório o preenchimento de pelo menos um vendedor.
                                  </p>
                                }
                          </div>

                          {usersList.length > 0 &&
                            <div className="sm:col-span-full sm:mt-0">
                              <h2 className="block text-sm font-small leading-6 text-gray-700">Vendedor(es) adicionado(s)</h2>
                              <div className="mt-6 overflow-hidden border border-gray-300 rounded-md">
                                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                  <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                                    <table className="w-full text-left">
                                      <tbody>
                                        {usersList.map((user) => (
                                          <Fragment key={user.ID}>
                                              <tr >
                                                <td className="relative py-5 pr-6">
                                                  
                                                  <div className="flex gap-x-6">
                                                    <div className="flex-auto">
                                                      <div className="flex items-start gap-x-8">
                                                      
                                                      <div className="text-sm leading-6 text-gray-900">{user.Email}</div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                                                  <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                                                </td>
                                                <td className="hidden py-5 pr-6 sm:table-cell">
                                                  <div className="text-sm leading-6 text-gray-900">{user.Permission}</div>
                                                </td>
                                                {disabled == false &&
                                                  <td className="py-5 text-right">
                                                    <div className="flex justify-end">
                                                      <a className="text-sm font-medium leading-6 text-indigo-600 hover:text-indigo-500">
                                                        <button type="button" className="sm:inline p-2" onClick={e => deleteUserOnClick(e, user.ID)}>
                                                            <XMarkIcon className="w-3.5" aria-hidden="true" />  
                                                        </button>
                                                      </a>
                                                    </div>
                                                  </td>
                                                }
                                              </tr>
                                          </Fragment>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          }

                          <div className="mt-2 sm:col-span-full sm:mt-0">
                              <Listbox value={companySelected} onChange={setCompanySelected}>
                                {({ open }) => (
                                  <>
                                    {/* <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">Inserir produtos</Listbox.Label> */}
                                    <div className="flex items-center justify-between">
                                      <h2 className="block text-sm font-medium leading-6 text-gray-900">Selecionar empresa(s)</h2>
                                        <button disabled={disabled} type="button" className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500" onClick={e => addCompanyOnClick(e,companySelected)}>
                                            Adicionar empresa
                                        </button>
                                    </div>
                                    <div className="relative mt-2">
                                      <Listbox.Button className="flex flex-1 relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                                      {!(companySelected == '' || companySelected == undefined || companySelected == null)?
                                        <span className="inline-flex w-full truncate">
                                          <span className="truncate"> {companySelected.Nome} </span>
                                          <span className="ml-2 truncate text-gray-500"> 
                                          {companySelected.CNPJ.slice(0, 2) + '.' + companySelected.CNPJ.slice(2, 5) + '.' + companySelected.CNPJ.slice(5, 8) + '/' + 
                                            companySelected.CNPJ.slice(8, 12)+ '-' + companySelected.CNPJ.slice(12, 14)}
                                          </span>
                                        </span>
                                        :
                                        <span className="inline-flex w-full truncate">
                                          <span className="truncate">Selecione ...</span>
                                        </span>
                                        }
                                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </span>
                                      </Listbox.Button>
                                      

                                      <Transition
                                        show={open}
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                          {props.states.companies.length>0 && props.states.companies.map((company) => (
                                            (company.IsDeleted == 0) &&
                                            <Listbox.Option
                                              key={company.ID}
                                              className={({ active }) =>
                                                classNames(
                                                  active ? 'bg-blue-500 text-white' : 'text-gray-900',
                                                  'relative cursor-default select-none py-2 pl-3 pr-9'
                                                )
                                              }
                                              value={company}>
                                                {({ selected, active }) => (
                                                  <>
                                                    <div className="flex">
                                                      <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'truncate')}>
                                                        {company.Nome}
                                                      </span>
                                                      <span className={classNames(active ? 'text-indigo-200' : 'text-gray-500', 'ml-2 truncate')}>
                                                        {company.CNPJ.slice(0, 2) + '.' + company.CNPJ.slice(2, 5) + '.' + company.CNPJ.slice(5, 8) + '/' + 
                                                        company.CNPJ.slice(8, 12)+ '-' + company.CNPJ.slice(12, 14)}
                                                      </span>
                                                    </div>

                                                    {selected ? (
                                                      <span
                                                        className={classNames(
                                                          active ? 'text-white' : 'text-indigo-600',
                                                          'absolute inset-y-0 right-0 flex items-center pr-4'
                                                        )}
                                                      >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                      </span>
                                                    ) : null}
                                                  </>
                                                )}
                                            </Listbox.Option>
                                          )
                                          
                                          )}
                                        </Listbox.Options>
                                      </Transition>

                                    </div>
                                    
                                  </>
                                )}
                              </Listbox>
                              {
                                  idsCompanyError === true && 
                                  <p class="mt-2 ml-1 text-pink-600 text-sm">
                                      É obrigatório o preenchimento de pelo menos uma empresa.
                                  </p>
                                }
                          </div>

                          {companyList.length > 0 &&
                            <div className="sm:col-span-full sm:mt-0">
                              <h2 className="block text-sm font-small leading-6 text-gray-700">Empresas adicionadas</h2>
                              <div className="mt-6 overflow-hidden border border-gray-300 rounded-md">
                                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                  <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                                    <table className="w-full text-left">
                                      <tbody>
                                        {companyList.map((company) => (
                                          <Fragment key={company.ID}>
                                              <tr >
                                                <td className="relative py-5 pr-6">
                                                  
                                                  <div className="flex gap-x-6">
                                                    <div className="flex-auto">
                                                      <div className="flex items-start gap-x-8">
                                                      
                                                      <div className="text-sm leading-6 text-gray-900">{company.Nome}</div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                                                  <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                                                </td>
                                                <td className="hidden py-5 pr-6 sm:table-cell">
                                                  <div className="text-sm leading-6 text-gray-900">{company.CNPJ.slice(0, 2) + '.' + company.CNPJ.slice(2, 5) + '.' + company.CNPJ.slice(5, 8) + '/' + 
                                                        company.CNPJ.slice(8, 12)+ '-' + company.CNPJ.slice(12, 14)}</div>
                                                </td>
                                                {disabled == false &&
                                                  <td className="py-5 text-right">
                                                    <div className="flex justify-end">
                                                      <a className="text-sm font-medium leading-6 text-indigo-600 hover:text-indigo-500">
                                                        <button type="button" className="sm:inline p-2" onClick={e => deleteCompanyOnClick(e, company.ID)}>
                                                            <XMarkIcon className="w-3.5" aria-hidden="true" />  
                                                        </button>
                                                      </a>
                                                    </div>
                                                  </td>
                                                }
                                              </tr>
                                          </Fragment>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          }

                          <div className="mt-2 sm:col-span-full sm:mt-0">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                Condições
                            </label>
                            <div className="mt-2">
                            <textarea
                                rows={6}
                                name="clausulas"
                                id="clausulas"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder='Digite as condições'
                                value={clausulas}
                                onChange={e => setClausulas(e.target.value)}
                                defaultValue={''}
                                />
                                {
                                    clausulaError === true && 
                                    <p class="mt-2 ml-1 text-pink-600 text-sm">
                                        É obrigatório o preenchimento das condições.
                                    </p>
                                }
                            </div>
                          </div>

                          </div>
                        </div>

                    </div>
                  </div>

                  <div className="pt-4" >
                    <div className="flex justify-end gap-x-3">
                      <button
                        type="button"
                        className="rounded-md bg-white py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={()=>props.setters.setModalIsOpen(false)}
                      >
                        Cancelar
                      </button>
                      <button
                        type="submit"
                        className="inline-flex justify-center rounded-md bg-blue-500 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
              </form>
                
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
