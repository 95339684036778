import { useContext,useEffect, useState } from 'react'
import Footer from '../../Components/Footer/footer'
import Navbar from '../../Components/Navbar/navbar'
import { useNavigate } from 'react-router-dom'
import Spinner from '../../Components/Spinner'
import Error from '../../Components/EmptyGrid'
import { AuthContext } from "../../Context"
import { toast } from 'react-toastify';


const statuses = {
    0: 'text-green-700 bg-green-50 ring-green-600/20',
    1: 'text-red-700 bg-red-50 ring-red-600/10',
  }

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
    const {states, setters, listarProdutos, listarVendas, listarClientes, verificaPermissao,listarEstatisticasHome, getPath} = useContext(AuthContext)
    const navigate = useNavigate()

    useEffect(()=>{
        setValues()
        listarPermissoesAsync()
    },[])

    const setValues = async() =>
    {
        await setters.setProducts('')
        await setters.setSales('')
        await setters.setClients('')
    }

    useEffect(()=>{
        try{
            if(states.isAdmin == true)
            {
                listarEstatisticasHome()
            }
        }
        catch(err)
        {
            toast.error(err.response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
        }
    },[states.isAdmin])

    useEffect(()=>{
        if(states.products == '') listarProdutosAsync()
    },[states.products])

    useEffect(()=>{
        if(states.sales == '') listarVendasAsync()
    },[states.sales])

    useEffect(()=>{
        if(states.clients == '') listarClientesAsync()
    },[states.clients])

    useEffect(()=>{
      if(localStorage.getItem('permission')=='Admin')
      {
        listarProdutosAsync()
        listarVendasAsync()
        listarClientesAsync()
      }
      else
      {
        navigate('/produtos')
      }
    },[states.isAdmin, states.isVendedor, states.isComum])

    const listarPermissoesAsync = async() => {
        try
        {
            await verificaPermissao()
        }
        catch(err)
        {
            toast.error(err.response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
        }
    }
    
    const listarProdutosAsync = async() => {
        try{
            await listarProdutos(states.isVendedor,states.isAdmin,3,0)
        }
        catch(err)
        {
            toast.error(err.response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
        }   
    }

    const listarVendasAsync = async() => {
        try{
            await listarVendas(3,0)
        }
        catch(err)
        {
            toast.error(err.response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
        }   
    }

    const listarClientesAsync = async() => {
        try{
            await listarClientes(3,0)
        }
        catch(err)
        {
            toast.error(err.response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
        }   
    }

    const verTodos = (origem) => 
    {
        if(origem == 'produtos')
        {
            setters.setProducts('')
            navigate('/produtos')
        }
        else if(origem=='vendas')
        {
            setters.setSales('')
            navigate('/vendas')
        }
        else if(origem == 'clientes')
        {
            setters.setClients('')
            navigate('/clientes')
        }
    }

    const [imagem, setImagem] = useState('')

    useEffect(()=>{
        constroiLogo()
    },[])

    const constroiLogo = async () =>
    {
        const origin = await getPath(window)
        setImagem(origin + '/logo.png')
    }
      
    return (
    <>
        <Navbar/>

        <main>
            <div className="relative isolate overflow-hidden">

                <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5" hidden={!states.isAdmin}>
                <dl className="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
                    {states.estatisticasHome.map((stat, statIdx) => (
                    <div
                        key={stat.id}
                        className={classNames(
                        statIdx % 2 === 1 ? 'sm:border-l' : statIdx === 2 ? 'lg:border-l' : '',
                        'flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8'
                        )}
                    >
                        <div className='flex flex-1'>
                            <dt className="flex flex-1 text-sm font-medium leading-6 text-gray-500 py-1">{stat.name}</dt>
                            <dd className="text-2xl font-medium  tracking-tight text-gray-900">
                                {stat.value}
                            </dd>
                        </div>
                    </div>
                    ))}
                </dl>
                </div>

                <div
                className="absolute left-0 top-full -z-10 mt-96 origin-top-left translate-y-40 -rotate-90 transform-gpu opacity-20 blur-3xl sm:left-1/2 sm:-ml-96 sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu sm:opacity-50"
                aria-hidden="true"
                >
                <div
                    className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-blue-200 to-blue-600"
                    style={{
                    clipPath:
                        'polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)',
                    }}
                />
                </div>
            </div>

            <div className="space-y-16 py-16 xl:space-y-10 pt-6">
                {/* 1 */}
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                        <div className="flex items-center justify-between">
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Produtos ofertados</h2>
                            <button className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500" onClick={()=> verTodos('produtos')}>
                                    Ver todos
                            </button>
                        </div>

                        {states.loading == true?
                            <Spinner/>
                        :
                        states.products.length != 0 ?
                        <div> 
                            <ul role="list" className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                                {states.products.map((product) => (
                                    (product.IsDeleted == '0' || states.isAdmin) &&
                                    <li key={product.ID} className="overflow-hidden rounded-xl border border-gray-200 hover:bg-gray-50 hover:shadow">
                                    <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                                        <img
                                        src={product.Url == '' ? imagem : product.Url}
                                        className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
                                        crossOrigin="anonymous"
                                        /> 
                                        
                                        <div className="text-sm font-medium leading-6 text-gray-900 capitalize">{product.Title.toLowerCase()}</div>

                                    </div>
                                    <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                                        <div className="flex justify-between gap-x-4 py-3">
                                            <dt className="text-gray-500">Descrição</dt>
                                            <dd className="text-gray-700">
                                                {product.Description}
                                                {/* <time dateTime={client.lastInvoice.dateTime}>{client.lastInvoice.date}</time> */}
                                            </dd>
                                        </div>

                                        <div className="flex justify-between gap-x-4 py-3">
                                            <dt className="text-gray-500">Valor</dt>
                                            <dd className="flex items-start gap-x-2">
                                            <div className="font-medium text-gray-900">R$ {parseFloat(product.Price/100).toFixed(2).toString().replace('.',',')} </div>
                                                {/* <div className="font-medium text-gray-900">R$ {product.Price/100},00 </div> */}
                                            </dd>
                                        </div>

                                        {/* {!states.isVendedor &&
                                        <div className="flex justify-between gap-x-4 py-3">
                                            <dt className="text-gray-500">Empresa</dt>
                                            <dd className="flex items-start gap-x-2">
                                            <div className="font-medium text-gray-900">{product.CompanyID}</div>
                                            </dd>
                                        </div>
                                        } */}

                                        <div className="flex justify-between gap-x-4 py-3">
                                            <dt className="text-gray-500">Duração</dt>
                                            <dd className="flex items-start gap-x-2">
                                                <div className="font-medium text-gray-900">{product.Duration} meses </div>
                                            </dd>
                                        </div>

                                        <div className="flex justify-between gap-x-4 py-3">
                                            <dt className="text-gray-500">Status</dt>
                                            <dd className="flex items-start gap-x-2">
                                                <div
                                                    className={classNames(
                                                    statuses[product.IsDeleted],
                                                    'rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset'
                                                    )}
                                                >
                                                    {product.IsDeleted ? 'Inativo':'Ativo'}
                                                </div>
                                            </dd>
                                        </div>

                                    </dl>
                                    </li>
                                )) 
                                }
                            </ul>
                            
                           <hr className='mt-10'/>
                            
                        </div>
                        :
                            <Error origin={'produtos a serem exibidos'}/>
                        }
                    </div>
                </div>
                {/* 2 */}
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                        <div className="flex items-center justify-between">
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Vendas efetuadas</h2>
                            <button className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500" onClick={()=> verTodos('vendas')}>
                                    Ver todos
                            </button>
                        </div>

                        {states.loading == true?
                        <Spinner/>
                    :
                    states.sales.length !=0  ?
                    <div>
                      <ul role="list" className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                        {states.sales.map((sale) => (
                            <li key={sale.ID} className="overflow-hidden rounded-xl border border-gray-200 hover:bg-gray-50 hover:shadow">
                            <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                                <img
                                src={imagem}
                                crossOrigin="anonymous"
                                className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
                                />
                                <div className="text-sm font-medium leading-6 text-gray-900">{sale.Origin}</div>
                            </div>
                            <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                                <div className="flex justify-between gap-x-4 py-3">
                                    <dt className="text-gray-500">Observação</dt>
                                    <dd className="text-gray-700">
                                        {sale.Observation}
                                    </dd>
                                </div>

                                <div className="flex justify-between gap-x-4 py-3">
                                    <dt className="text-gray-500">Valor</dt>
                                    <dd className="flex items-start gap-x-2">
                                        <div className="font-medium text-gray-900">
                                        R$ {(sale.Value/100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</div>
                                    </dd>
                                </div>


                                <div className="flex justify-between gap-x-4 py-3">
                                    <dt className="text-gray-500">Status</dt>
                                    <dd className="flex items-start gap-x-2">
                                        <div
                                            className={classNames(
                                            statuses[sale.IsDeleted],
                                            'rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset'
                                            )}
                                        >
                                            {sale.IsDeleted == 1 ? 'Inativo':'Ativo'}
                                        </div>
                                    </dd>
                                </div>

                            </dl>
                            </li>
                        ))}
                      </ul>
                      <hr className='mt-10'/>
                    </div>
                    :
                      <Error origin={'vendas a serem exibidas'}/>
                    }
                    </div>
                </div>
                {/* 3 */}
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                    <div className="flex items-center justify-between">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Clientes cadastrados</h2>
                        <button className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500" onClick={()=> verTodos('clientes')}>
                                Ver todos
                        </button>
                    </div>

                    {states.loading == true?
                  <Spinner/>
              :
              states.clients.length !=0 ?
              <div>
                <ul role="list" className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                  {states.clients.map((client) => (
                      <li key={client.ID} className="overflow-hidden rounded-xl border border-gray-200 hover:bg-gray-50 hover:shadow">
                      <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                          <div className="text-sm font-medium leading-6 text-gray-900 capitalize">{client.Name.toLowerCase()}</div>
                      </div>
                      <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                          <div className="flex justify-between gap-x-4 py-3">
                              <dt className="text-gray-500">Telefone</dt>
                              <dd className="text-gray-700">
                                  {(client.Phone).replace(/\D/g,'').replace(/(\d{2})(\d)/,"($1) $2").replace(/(\d)(\d{4})$/,"$1-$2")}
                                  {/* <time dateTime={client.lastInvoice.dateTime}>{client.lastInvoice.date}</time> */}
                              </dd>
                          </div>

                          <div className="flex justify-between gap-x-4 py-3">
                              <dt className="text-gray-500">CPF</dt>
                              <dd className="flex items-start gap-x-2">
                                  <div className="font-medium text-gray-900">
                                  {client.CPF.slice(0, 3) + '.' + client.CPF.slice(3, 6) + '.' + client.CPF.slice(6, 9) + '-' + 
                                               client.CPF.slice(9, 11)}
                                  </div>
                              </dd>
                          </div>

                          <div className="flex justify-between gap-x-4 py-3">
                              <dt className="text-gray-500">RG</dt>
                              <dd className="flex items-start gap-x-2">
                                  <div className="font-medium text-gray-900">
                                  {client.RG}
                                  </div>
                              </dd>
                          </div>

                          <div className="flex justify-between gap-x-4 py-3">
                              <dt className="text-gray-500">Status</dt>
                              <dd className="flex items-start gap-x-2">
                                  <div
                                      className={classNames(
                                      statuses[client.IsDeleted],
                                      'rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset'
                                      )}
                                  >
                                      {client.IsDeleted == 1 ? 'Inativo':'Ativo'}
                                  </div>
                              </dd>
                          </div>

                      </dl>
                      </li>
                  ))}
                </ul>
              </div>
              : 
                <Error origin={'clientes a serem exibidos'}/>
                }
                </div>
                </div>
            </div>
        </main>

        <Footer/>
    </>
    )
}

