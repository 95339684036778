import { Fragment, useState,useEffect,useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useContext } from "react"
import { AuthContext } from "../../../Context"
import { toast } from 'react-toastify';


export default function ModalClient(props) {
  
  const cancelButtonRef = useRef(null)

  const {carregarCliente,carregarUsuario} = useContext(AuthContext)

  const [email,setEmail]= useState('')
  const [nome,setNome]= useState('')
  const [cpf,setCpf]= useState('')
  const [rg,setRg]= useState('')
  const [telefone,setTelefone]= useState('')
  const [endereco,setEndereco]= useState('')


  const limparStatesModalClient = () =>
  {
    setEmail('')
    setNome('')
    setCpf('')
    setRg('')
    setTelefone('')
    setEndereco('') 
  }

  const buscaAbreviacao = (estado)=>
  {
    if ( estado =='Acre'){ return 'AC'}
    else if ( estado =='Alagoas'){ return 'AL'}
    else if ( estado =='Amapá'){ return 'AP'}
    else if ( estado =='Amazonas'){ return 'AM'}
    else if ( estado =='Bahia'){ return 'BA'}
    else if ( estado =='Ceará'){ return 'CE'}
    else if ( estado =='Distrito Federal'){ return 'DF'}
    else if ( estado =='Espírito Santo'){ return 'ES'}
    else if ( estado =='Goiás'){ return 'GO'}
    else if ( estado =='Maranhão'){ return 'MA'}
    else if ( estado =='Mato Grosso'){ return 'MT'}
    else if ( estado =='Mato Grosso do Sul'){ return 'MS'}
    else if ( estado =='Minas Gerais'){ return 'MG'}
    else if ( estado =='Pará'){ return 'PA'}
    else if ( estado =='Paraíba'){ return 'PB'}
    else if ( estado =='Paraná'){ return 'PR'}
    else if ( estado =='Pernambuco'){ return 'PE'}
    else if ( estado =='Piauí'){ return 'PI'}
    else if ( estado =='Rio de Janeiro'){ return 'RJ'}
    else if ( estado =='Rio Grande do Norte'){ return 'RN'}
    else if ( estado =='Rio Grande do Sul'){ return 'RS'}
    else if ( estado =='Rondônia'){ return 'RO'}
    else if ( estado =='Roraima'){ return 'RR'}
    else if ( estado =='Santa Catarina'){ return 'SC'}
    else if ( estado =='São Paulo'){ return 'SP'}
    else if ( estado =='Sergipe'){ return 'SE'}
    else if ( estado =='Tocantins'){ return 'TO'}
    else{return estado}
  }

  useEffect(() => {
    try{
      if(props.states.modalIsOpen === false)
      {
        limparStatesModalClient()
      }
      else
      {
        if(!(props.id == null || props.id == undefined || props.id == ''))
        {
          carregarCliente(props.id)
        }
      }
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
    
  }, [props.states.modalIsOpen]);

  useEffect(() => {
    try{
      if(props.states.clientSelected!= '')
      {
          carregarUsuario(props.states.clientSelected.UserID)
          setNome(props.states.clientSelected.Name)
          setCpf(props.states.clientSelected.CPF)
          setRg(props.states.clientSelected.RG)
          setTelefone(props.states.clientSelected.Phone)
          setEndereco(JSON.parse(props.states.clientSelected.Address))
      }
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }, [props.states.clientSelected]);

  useEffect(() => {
    try{
      if(props.states.clientSelectedUser!= '')
      {
          setEmail(props.states.clientSelectedUser.Email)
      }
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }, [props.states.clientSelectedUser]);

  const enviarEmail = async() =>
  {
    props.setters.setNomeEmail(nome)
    props.setters.setEnderecoEmail(email)
    props.setters.setModalIsOpenAux(true)
  }

  return (
    <Transition.Root show={props.states.modalIsOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={()=>props.setters.setModalIsOpen(false)} >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <form className="space-y-8" >
              {/* onSubmit={()=> cadastrarAssociado()} */}
                      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                        <div className="space-y-6 sm:space-y-5">
                            <div className='flex-col'>
                              <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Cliente</h3>
                              <p className="flex mt-1 text-sm text-gray-500 justify-center">
                                Informações do cliente
                              </p>
                            </div>

                            <div className="space-y-6 sm:space-y-5">
                              {/* 1 LINHA */}
                              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">

                              <div className="mt-2 sm:col-span-1 sm:mt-0">
                              <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                Nome
                              </label>
                                <div className="flex max-w-lg rounded-md shadow-sm">
                                  <input
                                    type="text"
                                    name="nome"
                                    id="username"
                                    autoComplete="nome"
                                    readOnly={true}
                                    value={nome}
                                    className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>

                              <div className="mt-2 sm:col-span-1 sm:mt-0">
                              <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                CPF
                              </label>
                                <div className="flex max-w-lg rounded-md shadow-sm">
                                  <input
                                     type="text"
                                     name="cpf"
                                     id="cpf"
                                     autoComplete="cpf"
                                     readOnly={true}
                                     value={cpf.replace(/\D/g,"").replace(/(\d{3})(\d)/,"$1.$2").replace(/(\d{3})(\d)/,"$1.$2").replace(/(\d{3})(\d{1,2})$/,"$1-$2")}
                                    className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>

                              <div className="mt-2 sm:col-span-1 sm:mt-0">
                              <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                RG
                              </label>
                                <div className="flex max-w-lg rounded-md shadow-sm">
                                  <input
                                     type="text"
                                     name="rg"
                                     id="rg"
                                     autoComplete="rg"
                                     readOnly={true}
                                     value={rg}
                                    className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>

                              </div>
                            </div>

                            <div className="space-y-6 sm:space-y-5">
                              {/* 2 LINHA */}
                              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 ">

                                <div className="mt-2 sm:col-span-1 sm:mt-0">
                                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                    Rua
                                </label>
                                    <div className="flex max-w-lg rounded-md shadow-sm">
                                    <input
                                        type="text"
                                        name="street"
                                        id="street"
                                        autoComplete="street"
                                        readOnly={true}
                                        value={endereco.street}
                                        className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    </div>
                                </div>

                                <div className="mt-2 sm:col-span-1 sm:mt-0">
                                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                    Nº
                                </label>
                                    <div className="flex max-w-lg rounded-md shadow-sm">
                                    <input
                                        type="text"
                                        name="number"
                                        id="number"
                                        autoComplete="number"
                                        readOnly={true}
                                        value={endereco.number}
                                        className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    </div>
                                </div>

                                <div className="mt-2 sm:col-span-1 sm:mt-0">
                                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                    Complemento
                                </label>
                                    <div className="flex max-w-lg rounded-md shadow-sm">
                                    <input
                                        type="text"
                                        name="complement"
                                        id="complement"
                                        autoComplete="complement"
                                        readOnly={true}
                                        value={endereco.complement == '' ? 'N/A' : endereco.complement}
                                        className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    </div>
                                </div>

                                <div className="mt-2 sm:col-span-1 sm:mt-0">
                                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                    Bairro
                                </label>
                                    <div className="flex max-w-lg rounded-md shadow-sm">
                                    <input
                                        type="text"
                                        name="neighbourhood"
                                        id="neighbourhood"
                                        autoComplete="neighbourhood"
                                        readOnly={true}
                                        value={endereco.neighbourhood}
                                        className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    </div>
                                </div>

                                <div className="mt-2 sm:col-span-1 sm:mt-0">
                                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                    CEP
                                </label>
                                    <div className="flex max-w-lg rounded-md shadow-sm">
                                    <input
                                        type="text"
                                        name="cep"
                                        id="cep"
                                        autoComplete="cep"
                                        readOnly={true}
                                        value={endereco.cep}
                                        className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    </div>
                                </div>

                                <div className="mt-2 sm:col-span-1 sm:mt-0">
                                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                    Cidade/Estado
                                </label>
                                    <div className="flex max-w-lg rounded-md shadow-sm">
                                    <input
                                        type="text"
                                        name="city"
                                        id="city"
                                        autoComplete="city"
                                        readOnly={true}
                                        value={endereco.city+'/'+ buscaAbreviacao(endereco.state)}
                                        className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    </div>
                                </div>

                              </div>
                            </div>
                        </div>
                      </div>

                      <div className="space-y-8 sm:space-y-5">
                        <div className="space-y-6 sm:space-y-5">
                            <div className='flex-col'>
                              <p className="flex mt-1 text-sm text-gray-500 justify-center">
                                Informações de Contato
                              </p>
                            </div>

                            <div className="space-y-6 sm:space-y-5">
                              {/* 1 LINHA */}
                              <div className="sm:grid sm:grid-cols-2 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">

                              <div className="mt-2 sm:col-span-1 sm:mt-0">
                              {/* <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                Email
                              </label> */}
                              <div className="flex items-center justify-between">
                                  <h2 className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">Email</h2>
                                  <button type="button" className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500" onClick={()=> enviarEmail()}>
                                      Enviar email
                                  </button>
                                </div>
                                <div className="flex max-w-lg rounded-md shadow-sm">
                                  <input
                                    type="text"
                                    name="email"
                                    id="username"
                                    autoComplete="email"
                                    readOnly={true}
                                    value={email}
                                    className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>

                              <div className="mt-2 sm:col-span-1 sm:mt-0">
                              <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                Telefone
                              </label>
                                <div className="flex max-w-lg rounded-md shadow-sm">
                                  <input
                                     type="text"
                                     name="phone"
                                     id="username"
                                     autoComplete="phone"
                                     readOnly={true}
                                     value={(telefone).replace(/\D/g,'').replace(/(\d{2})(\d)/,"($1) $2").replace(/(\d)(\d{4})$/,"$1-$2")}
                                    className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>

                              </div>
                            </div>

                        </div>
                      </div>

                      <div className="pt-5" >
                        <div className="flex justify-end gap-x-3">
                          <button
                            type="button"
                            className="rounded-md bg-white py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            onClick={()=>props.setters.setModalIsOpen(false)}
                          >
                            Fechar
                          </button>
                        </div>
                      </div>
              </form>
                
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
