import { useContext } from "react"
import { AuthContext } from "../../Context"

export default function SpinnerOverlay() {
  const {states} = useContext(AuthContext)

  return (
    <div
    hidden={!states.spinner}
    style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // A cor do overlay (50% de transparência)
        zIndex: 9999, // Coloque o overlay acima dos outros elementos da página
    }}
>
    <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-24 w-24 border-t-4 border-green-500 border-solid"></div>
    </div>

</div>
  )
}