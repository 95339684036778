import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import { Fragment, useState ,useContext,useEffect } from 'react'
import ModalProduct from '../../Components/Modal/Product'
import { Menu, Transition } from '@headlessui/react'
import Footer from '../../Components/Footer/footer'
import Navbar from '../../Components/Navbar/navbar'
import { Link } from 'react-router-dom'
import Spinner from '../../Components/Spinner'
import Error from '../../Components/EmptyGrid'
import { AuthContext } from "../../Context"
import { toast } from 'react-toastify';
import tinycolor from 'tinycolor2'
import SpinnerOverlay from '../../Components/SpinnerOverlay'
import { ShoppingCartIcon} from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'

const statuses = {
    0: 'text-green-700 bg-green-50 ring-green-600/20',
    1: 'text-red-700 bg-red-50 ring-red-600/10',
  }

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
    const {states, setters, listarProdutos, excluirProduto, ativarProduto, verificaPermissao,listarEstatisticasHome,listarUsuarios, listarEmpresas, getPath} = useContext(AuthContext)
    const [idProductSelected, setIdProductSelected] = useState('')
    const [offset, setOffset] = useState(0)
    const navigate = useNavigate()

    const [verMais, setVerMais] = useState(false)

    useEffect(()=>{
        setValues()
        listarPermissoesAsync()
    },[])

    const setValues = async() =>
    {
        await setters.setProducts('')
    }

    useEffect(()=>{
        if(states.modalIsOpen == false)
        {
            setters.setProducts('')
            setOffset(0)
        }
    },[states.modalIsOpen])

    useEffect(()=>{
        try{
            if(states.isAdmin == true)
            {
                listarEstatisticasHome()
            }
        }
        catch(err)
        {
            toast.error(err.response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
        }
    },[states.isAdmin])

    useEffect(()=>{
        if(states.products == '') listarProdutosAsync()
    },[states.products])

    useEffect(()=>{
        if(localStorage.getItem('permission')=='Admin' || localStorage.getItem('permission')=='Vendedor' || localStorage.getItem('permission')=='Comum')
        {
            listarProdutosAsync(false)
        }
        else
        {
            navigate('/produtos/externo')
        }
        
    },[states.isAdmin, states.isVendedor, states.isComum])

    useEffect(()=>{
        listarProdutosAsync(verMais)
    },[offset])

    const listarPermissoesAsync = async() => {
        try
        {
            await verificaPermissao()
        }
        catch(err)
        {
            toast.error(err.response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
        }
    }
    
    const listarProdutosAsync = async() => {
        try{
            await listarProdutos(states.isVendedor,states.isAdmin,6,offset, verMais)
            setVerMais(false)
        }
        catch(err)
        {
            toast.error(err.response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
        }   
    }

    const activateProductOnClick = async (id) => {
        try{
            const result = await ativarProduto(id)
            if(result.status == true)
            {
                setters.setProducts('')
                setOffset(0)
                toast.success('Produto ativado com sucesso!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
            else
            {
                toast.error(result.msg, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        }
        catch(err)
        {
            toast.error(err.response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
        }
    }

    const deleteProductOnClick = async (id) => {
        try{
            const result = await excluirProduto(id)
            if(result.status == true)
            {
                setters.setProducts('')
                setOffset(0)
                toast.success('Produto inativado com sucesso!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
            else
            {
                toast.error(result.msg, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        }
        catch(err)
        {
            toast.error(err.response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
        }
    }
      
    const editProductOnClick = (id) => {
        setters.setModalIsOpen(true)
        setIdProductSelected(id)
    }

    const addProductOnClick = () => {
        setters.setModalIsOpen(true)
        setIdProductSelected('')
    }

    const handleVerMais = (e) =>
    {
        e.preventDefault()
        setOffset(offset+1)
        setVerMais(true)
    }

    const [imagem, setImagem] = useState('')

    useEffect(()=>{
        constroiLogo()
    },[])

    const constroiLogo = async () =>
    {
        const origin = await getPath(window)
        setImagem(origin + '/logo.png')
    }

    return (
    <>
        <Navbar/>

        <main>
            <div className="space-y-16 py-16 xl:space-y-20 pt-6">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                    <div className="flex items-center justify-between mb-6">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Produtos ofertados</h2>
                        {(states.isAdmin || states.isVendedor || states.isComum) &&
                            // <button href="#" className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500" onClick={()=> addProductOnClick()} hidden={!states.isAdmin}>
                            //     Adicionar produto
                            // </button>
                            <div className="mt-2 sm:col-span-1 sm:mt-0">
                                <div className="flex max-w rounded-md">
                                
                                <div className="flex-1 flex bg-white pl-2 justify-end items-center">
                                    <button className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                                    onClick={()=> addProductOnClick()} hidden={!states.isAdmin}  >
                                    Adicionar produto
                                    </button>
                                </div> 

                                </div>
                            </div>
                        }
                    </div>

                    {states.loading == true?
                        <Spinner/>
                    :
                    states.products.length != 0 ?
                    <div> 
                        <ul role="list" className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                        {states.products.map((product) => (
                                (product.IsDeleted == '0' || states.isAdmin) &&
                                <li key={product.ID} className="overflow-hidden rounded-xl border border-gray-200 hover:bg-gray-50 hover:shadow">
                                <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                                    <img
                                    src={product.Url == '' ? imagem : product.Url}
                                    className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
                                    crossOrigin="anonymous"
                                    />
                                    <div className="text-sm font-medium leading-6 text-gray-900">{product.Title}</div>

                                    {states.isComum ?
                                        <Link to="/vendas/cadastro" className="relative ml-auto">
                                            <ShoppingCartIcon className="h-6 w-6 hover:text-gray-500" aria-hidden="true" onClick={()=> sessionStorage.setItem("product", JSON.stringify(product))}/>
                                        </Link> :
                                        <Link to="/clientes/cadastro" className="relative ml-auto" hidden={states.isVendedor || states.isAdmin} onClick={()=> setters.setCompraDeslogado(true)}>
                                            <ShoppingCartIcon className="h-6 w-6 hover:text-gray-500" aria-hidden="true" />
                                        </Link>
                                    }

                                    <Menu as="div" className="relative ml-auto" hidden={!states.isAdmin}>
                                    <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                                        <span className="sr-only">Abrir opções</span>
                                        <EllipsisHorizontalIcon className="h-6 w-6" aria-hidden="true" />
                                    </Menu.Button>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        {product.IsDeleted == 0 ? 
                                        <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                            <Menu.Item>
                                                {({ active }) => (
                                                <a
                                                    href="#"
                                                    className={classNames(
                                                    active ? 'bg-gray-50' : '',
                                                    'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                    )}
                                                    onClick={()=>editProductOnClick(product.ID)}
                                                    hidden={true}
                                                >
                                                    Editar<span className="sr-only">, {product.Title}</span>
                                                </a>
                                                )}
                                            </Menu.Item>

                                            <Menu.Item>
                                                {({ active }) => (
                                                <a
                                                    href="#"
                                                    className={classNames(
                                                    active ? 'bg-gray-50' : '',
                                                    'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                    )}
                                                    onClick={()=>deleteProductOnClick(product.ID)}
                                                >
                                                    Excluir<span className="sr-only">, {product.Title}</span>
                                                </a>
                                                )}
                                            </Menu.Item>

                                        </Menu.Items>
                                    :
                                    <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                        <Menu.Item>
                                            {({ active }) => (
                                            <a
                                                href="#"
                                                className={classNames(
                                                active ? 'bg-gray-50' : '',
                                                'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                )}
                                                onClick={()=>activateProductOnClick(product.ID)}
                                                hidden={true}
                                            >
                                                Ativar<span className="sr-only">, {product.Title}</span>
                                            </a>
                                            )}
                                        </Menu.Item>
                                    </Menu.Items>}
                                        
                                    </Transition>
                                    </Menu>
                                </div>
                                <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                                    
                                    <div className="flex justify-between gap-x-4 py-3">
                                        <dt className="text-gray-500">Descrição</dt>
                                        <dd className="text-gray-700">
                                            {product.Description}
                                            {/* <time dateTime={client.lastInvoice.dateTime}>{client.lastInvoice.date}</time> */}
                                        </dd>
                                    </div>

                                    <div className="flex justify-between gap-x-4 py-3">
                                        <dt className="text-gray-500">Valor</dt>
                                        <dd className="flex items-start gap-x-2">
                                        <div className="font-medium text-gray-900">R$ {parseFloat(product.Price/100).toFixed(2).toString().replace('.',',')} </div>
                                            {/* <div className="font-medium text-gray-900">R$ {product.Price/100},00 </div> */}
                                        </dd>
                                    </div>

                                    {!states.isVendedor &&
                                    <div className="flex justify-between gap-x-4 py-3">
                                        <dt className="text-gray-500">Empresa</dt>
                                        <dd className="flex items-start gap-x-2">
                                        <div className="font-medium text-gray-900">{product.Company}</div>
                                        </dd>
                                    </div>
                                    }

                                    <div className="flex justify-between gap-x-4 py-3">
                                        <dt className="text-gray-500">Duração</dt>
                                        <dd className="flex items-start gap-x-2">
                                            <div className="font-medium text-gray-900">{product.Duration} meses </div>
                                        </dd>
                                    </div>

                                    <div className="flex justify-between gap-x-4 py-3">
                                        <dt className="text-gray-500">Status</dt>
                                        <dd className="flex items-start gap-x-2">
                                            <div
                                                className={classNames(
                                                statuses[product.IsDeleted],
                                                'rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset'
                                                )}
                                            >
                                                {product.IsDeleted ? 'Inativo':'Ativo'}
                                            </div>
                                        </dd>
                                    </div>

                                </dl>
                                </li>
                            )) 
                            }
                        </ul>
                        
                        {states.loadMoreProducts && 
                            <nav className="flex items-center justify-between px-4 sm:px-0 mt-4">
                                <div className="-mt-px flex w-0 flex-1"/>

                                <div className="hidden md:-mt-px md:flex">
                                    <button
                                    className="inline-flex items-center px-4 pt-4 text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
                                    onClick={e=> handleVerMais(e)}>
                                    Ver mais
                                    </button>
                                </div>

                                <div className="-mt-px flex w-0 flex-1 justify-end"/>
                            </nav>
                        }
                    </div>

                    :
                        <Error origin={'produtos a serem exibidos'}/>
                    }
                </div>
                </div>

            
                {/* <Spinner show={loading}/> */}
                
            </div>
        </main>

        <Footer/>

        

        <ModalProduct states={states} setters={setters} id={idProductSelected} listarUsuarios={listarUsuarios} listarEmpresas={listarEmpresas}/>
        <SpinnerOverlay />
    </>
    )
}

