import { useContext, useEffect, useState } from 'react'
import { AuthContext } from "../../Context/index"
import { toast } from 'react-toastify';
// import { AuthContextApi } from '../../Context/api'
import { useNavigate } from 'react-router-dom';


export default function Forgot() {
    const { forgotPassword, getPath } = useContext(AuthContext)
    const hist = useNavigate()

    const [email,setEmail] = useState('')
    const [errorEmail,setErrorEmail] = useState(false)

    const enviarMudanca = async(e) =>
    {
        try{
            e.preventDefault()
            if(email == '')
            {
                setErrorEmail(true)
            }
            else
            {
                setErrorEmail(false)
                const result = await forgotPassword(email)
                if(result.status == true)
                {
                    toast.success('Solicitação criada com sucesso!', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });
                    hist('/')
                }
                else{
                    toast.error(result.msg, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });
                }
            }
        }
        catch(err)
        {
            toast.error(err.response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
        }
    }

    const [imagem, setImagem] = useState('')

    useEffect(()=>{
        constroiLogo()
    },[])

    const constroiLogo = async () =>
    {
        const origin = await getPath(window)
        setImagem(origin + '/logo.png')
    }

    return(
        <div className="flex min-h-screen bg-gray-100 flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
         
  
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
            <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
              <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img
                  className="mx-auto h-16 w-32"
                  src={imagem}
                  crossOrigin="anonymous"
                  alt="Your Company"
                />
                <h2 className="mt-6 text-center text-xl font-bold leading-9 tracking-tight text-gray-900">
                    Digite o email para efetuar a troca de senha
                </h2>
              </div>
              <form className="space-y-6" action="#" method="POST">
                <div>
                  <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Email
                  </label>
                  <div className="mt-2">
                    <input
                        required
                        type="email"
                        name="email"
                        id="email"
                        autoComplete="email"
                        placeholder='Digite o email ..'
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  {
                        errorEmail === true && 
                        <p class="mt-2 text-pink-600 text-sm">
                            É obrigatório o preenchimento do email.
                        </p>
                    }
                </div>

  
                <div>
                  <button
                    type="button"
                    className="flex w-full justify-center rounded-md bg-blue-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    onClick={e=> enviarMudanca(e)}
                  >
                    Enviar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
    )

}