  
  export default function Error(props) {
    return (
        <div className="mt-40 mb-40 text-center">
          <svg
              className="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
          >
              <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z" />
          </svg>
          <h3 className="mt-4 text-sm font-semibold text-gray-900">Sem informações</h3>
          <p className="mt-1 text-sm text-gray-500">Não existem {props.origin}</p>
          <div className="mt-6">
          </div>
        </div>
    )
  }
  