import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'

const ModalDependente = ({ add, isOpen, close }) => {

    const cancelButtonRef = useRef(null)
    const [open, setOpen] = useState(false)

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [cpf, setCpf] = useState('')
    const [parentesco, setParentesco] = useState('')
    const [nameError, setNameError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [cpfError, setCpfError] = useState(false)
    const [parentescoError, setParentescoError] = useState(false)

    const formatCPF = (cpf) => {
        if (!cpf) {
            return '';
        }

        const cleanedCPF = cpf.replace(/\D/g, '');

        if (cleanedCPF.length !== 11) {
            return cpf; // Retorna o valor original se não for possível formatar
        }

        const cpfRegex = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
        const formattedCPF = cleanedCPF.replace(cpfRegex, '$1.$2.$3-$4');

        return formattedCPF;
    }

    const addDependente = () => {
        if (!name) setNameError(true)
        if (name) setNameError(false)
        if (!email) setEmailError(true)
        if (email) setEmailError(false)
        if (!cpf) setCpfError(true)
        if (cpf) setCpfError(false)
        if (!parentesco) setParentescoError(true)
        if (parentesco) setParentescoError(false)
        if (!name || !email || !cpf || !parentesco) return

        add({ name, email, cpf, parentesco })
    }


    useEffect(() => {
        setOpen(isOpen)
    }, [isOpen])

    useEffect(() => {
        if (!open) close(false)
        if (open) {
            setCpfError(false)
            setNameError(false)
            setEmailError(false)
            setParentescoError(false)
            setParentesco('')
            setCpf('')
            setEmail('')
            setName('')
        }
    }, [open])


    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed mb-32 sm:mb-0 inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div className="mx-auto flex h-12 w-full items-center justify-center ">
                                        Adicionar Dependente
                                    </div>
                                    <div className="mt-5 sm:mt-6">
                                        <div className="mb-4">
                                            <label htmlFor="nome" className="block text-sm font-medium text-gray-700">Nome</label>
                                            <input
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                type="text"
                                                name="nome"
                                                id="nome"
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            />
                                            {
                                                nameError && <p className="text-red-500 text-xs italic">Campo obrigatório</p>
                                            }
                                        </div>
                                        <div className="mb-4">
                                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                                            <input
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                type="email"
                                                name="email"
                                                id="email"
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            />
                                            {
                                                emailError && <p className="text-red-500 text-xs italic">Campo obrigatório</p>
                                            }
                                        </div>
                                        <div className="mb-6">
                                            <label htmlFor="cpf" className="block text-sm font-medium text-gray-700">CPF</label>
                                            <input
                                                value={formatCPF(cpf)}
                                                onChange={(e) => {
                                                    const onlyNumber = e.target.value.replace(/\D/g, '')
                                                    if (onlyNumber.length > 11) return
                                                    setCpf(onlyNumber)
                                                }}
                                                type="text"
                                                name="cpf"
                                                id="cpf"
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            />
                                            {
                                                cpfError && <p className="text-red-500 text-xs italic">Campo obrigatório</p>
                                            }
                                        </div>
                                        <div className="mb-6">
                                            <label htmlFor="cpf" className="block text-sm font-medium text-gray-700">Parentesco</label>
                                            <select
                                                value={parentesco}
                                                onChange={(e) => setParentesco(e.target.value)}
                                                type="text"
                                                name="parentesco"
                                                id="parentesco"
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            >
                                                <option value="">Selecione</option>
                                                <option value="filho">Filho</option>
                                                <option value="filha">Filha</option>
                                                <option value="pai">Pai</option>
                                                <option value="mae">Mãe</option>
                                                <option value="avo">Avô</option>
                                                <option value="avo">Avó</option>
                                                <option value="irmao">Irmão</option>
                                                <option value="irma">Irmã</option>
                                                <option value="conjuge">Cônjuge</option>
                                            </select>
                                            {
                                                parentescoError && <p className="text-red-500 text-xs italic">Campo obrigatório</p>
                                            }
                                        </div>
                                        <div className="sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                            <button
                                                onClick={() => addDependente()}
                                                type="submit"
                                                className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                                            >
                                                Concluir
                                            </button>
                                            <button
                                                type="button"
                                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                                onClick={() => setOpen(false)}
                                            >
                                                Cancelar
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}


export default ModalDependente