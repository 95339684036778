import { toast } from 'react-toastify';
import { AuthContext } from "../../../Context"
import { useNavigate, Link } from 'react-router-dom'
import { Fragment, useState, useContext, useEffect } from 'react'

import { Listbox } from '@headlessui/react'
import { Transition } from '@headlessui/react'
import { TrashIcon } from '@heroicons/react/20/solid'
import { CheckIcon } from '@heroicons/react/24/outline'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import ModalDep from '../../../Components/Modal/Dependente'
import Navbar from '../../../Components/Navbar/navbar'
import Footer from "../../../Components/Footer/footer"

import InputMask from "react-input-mask";

import SpinnerOverlay from '../../../Components/SpinnerOverlay';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function CadastroVenda(props) {
    const { states, cadastrarVenda, listarProdutos, verificaPermissao, validarCpf, listarClientePorToken, getPath } = useContext(AuthContext)
    const [pagarBoleto, setPagarBoleto] = useState(false)
    const hist = useNavigate()

    const pagarBoletoOnClick = () => {
        if (!pagarBoleto) {
            setPagarBoleto(true)
        }
        else {
            setPagarBoleto(false)
        }
    }

    useEffect(() => {
        listarPermissoesAsync()

        var produtoClicado = JSON.parse(sessionStorage.getItem("product"));
        if (produtoClicado != null) {
            addProdutoOnLoad(produtoClicado)
        }

    }, []);

    useEffect(() => {
        if (states.isComum) {
            listarSelectsAsync()
        }
        else {
            hist('../produtos')
        }

    }, [states.isAdmin, states.isVendedor, states.isComum])

    const [number, setNumber] = useState('')
    const [name, setName] = useState('')
    const [date, setDate] = useState('')
    const [cvv, setCvv] = useState(0)

    const [errorNumber, setErrorNumber] = useState(false)
    const [errorName, setErrorName] = useState(false)
    const [errorDate, setErrorDate] = useState(false)
    const [errorCvv, setErrorCvv] = useState(false)

    useEffect(() => {
        let numeroCartao = number

        numeroCartao = numeroCartao.replace(/\D/g, '') // Permite apenas dígitos
        numeroCartao = numeroCartao.replace(/(\d{4})/g, '$1 ') // Coloca um ponto a cada 4 caracteres
        numeroCartao = numeroCartao.replace(/\.$/, '') // Remove o ponto se estiver sobrando
        numeroCartao = numeroCartao.substring(0, 19) // Limita o tamanho

        setNumber(numeroCartao)

    }, [number])

    useEffect(() => {
        let dataExpiracao = date

        dataExpiracao = dataExpiracao.replace(/\D/g, '') // Permite apenas dígitos
        dataExpiracao = dataExpiracao.replace(/(\d{2})/g, '$1/') // Coloca um ponto a cada 4 caracteres
        dataExpiracao = dataExpiracao.substring(0, 5) // Limita o tamanho

        setDate(dataExpiracao)

    }, [date])

    const [productSelected, setProductSelected] = useState(states.products)
    const [observacao, setObservacao] = useState('')

    const [idsProductError, setIdsProductError] = useState(false)
    const [observationError, setObservationError] = useState(false)

    let [productsList, setProductsList] = useState('')

    const [disabled, setDisabled] = useState(false)

    const [valorFinal, setValorFinal] = useState('0')

    const onSubmitForm = async (e) => {
        e.preventDefault()
        try {
            let arrayIds = []
            let totalPrice = 0
            for await (const produto of productsList) {
                let id = produto.ID
                arrayIds.push({ id })

                totalPrice += produto.Price
            }

            let cartao
            if (!pagarBoleto) {
                cartao = {
                    number: number.replaceAll(' ', ''),
                    ccv: cvv,
                    holderName: name,
                    expiryMonth: date.split('/')[0],
                    expiryYear: date.split('/')[1],
                    name: nomeTitular,
                    email: emailTitular,
                    cpfCnpj: cpfTitular,
                    postalCode: cepTitular,
                    addressNumber: numeroEnderecoTitular,
                    addressComplement: complementoEnderecoTitular,
                    phone: telefoneTitular
                }
            }

            const sale = { idClient: '', origin: 'Site', dependentes, value: valorFinal, observation: observacao, idsProduct: arrayIds }
            const verificar = await verificarStates(sale)
            if (verificar == true) {
                let verificarCartao
                if (!pagarBoleto) {
                    verificarCartao = await verificarStatesCartao(cartao)
                }
                else {
                    verificarCartao = true
                }

                if (verificarCartao == true) {
                    let creditCard
                    let creditCardHolderInfo

                    if (!pagarBoleto) {
                        creditCard = {
                            number: number.replaceAll(' ', ''),
                            ccv: cvv,
                            holderName: name,
                            expiryMonth: date != '' ? date.split('/')[0] : '',
                            expiryYear: date != '' ? date.split('/')[1] : ''
                        }

                        creditCardHolderInfo = {
                            name: nomeTitular,
                            email: emailTitular,
                            cpfCnpj: cpfTitular.replaceAll('.', '').replaceAll('-', ''),
                            postalCode: cepTitular.replaceAll('-', ''),
                            addressNumber: numeroEnderecoTitular,
                            addressComplement: complementoEnderecoTitular == '' ? null : complementoEnderecoTitular,
                            phone: telefoneTitular.replaceAll(' ', '').replaceAll('-', '').replaceAll('(', '').replaceAll(')', '')
                        }
                    }
                    else {
                        creditCard = null
                        creditCardHolderInfo = null
                    }

                    const result = await cadastrarVenda(sale, creditCard, creditCardHolderInfo)
                    if (result.status == true) {
                        toast.success('Venda cadastrada com sucesso', {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        hist('/home')
                    }
                    else {
                        toast.error(result.msg, {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                }
                else {
                    toast.error('Venda não pôde ser cadastrada', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }
            else {
                toast.error('Venda não pôde ser cadastrada', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
        catch (err) {
            toast.error(err.response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const verificarStates = async (sale) => {
        let resultado = true
        for await (const key of Object.keys(sale)) {
            if (sale[key] == '') {
                if (key == 'idsProduct') {
                    resultado = false
                    setIdsProductError(true)
                }
                else if (key == 'observation') {
                    resultado = false
                    setObservationError(true)
                }
            }
            else {
                if (key == 'idsProduct') {
                    setIdsProductError(false)
                }
                else if (key == 'observation') {
                    setObservationError(false)
                }
            }
        }
        return resultado
    }

    const verificarStatesCartao = async (cartao) => {
        try {
            let resultado = true
            for await (const key of Object.keys(cartao)) {
                if (cartao[key] == '' || cartao[key] == ' ') {
                    if (key == 'number') {
                        resultado = false
                        setErrorNumber(true)
                    }
                    else if (key == 'ccv') {
                        resultado = false
                        setErrorCvv(true)
                    }
                    else if (key == 'holderName') {
                        resultado = false
                        setErrorName(true)
                    }
                    else if (key == 'expiryMonth' || key == 'expiryYear') {
                        resultado = false
                        setErrorDate(true)
                    }
                    else if (key == 'name') {
                        resultado = false
                        setErrorNomeTitular(true)
                    }
                    else if (key == 'email') {
                        resultado = false
                        setErrorEmailTitular(true)
                    }
                    else if (key == 'cpfCnpj') {
                        resultado = false
                        setErrorCpfTitular(true)
                    }
                    else if (key == 'postalCode') {
                        resultado = false
                        setErrorCepTitular(true)
                    }
                    else if (key == 'addressNumber') {
                        resultado = false
                        setErrorNumeroEnderecoTitular(true)
                    }
                    else if (key == 'phone') {
                        resultado = false
                        setErrorTelefoneTitular(true)
                    }
                }
                else {
                    if (key == 'number') {
                        if (number.replaceAll(' ', '').length != 16) {
                            resultado = false
                            setErrorNumber(true)
                        }
                        else {
                            setErrorNumber(false)
                        }
                    }
                    else if (key == 'ccv') {
                        setErrorCvv(false)
                    }
                    else if (key == 'holderName') {
                        setErrorName(false)
                    }
                    else if (key == 'expiryMonth' || key == 'expiryYear') {
                        if (date == '00/00' || date == '') {
                            resultado = false
                            setErrorDate(true)
                        }
                        else {
                            setErrorDate(false)
                        }
                    }
                    else if (key == 'name') {
                        setErrorNomeTitular(false)
                    }
                    else if (key == 'email') {
                        setErrorEmailTitular(false)
                    }
                    else if (key == 'cpfCnpj') {
                        setErrorCpfTitular(false)
                    }
                    else if (key == 'postalCode') {
                        setErrorCepTitular(false)
                    }
                    else if (key == 'addressNumber') {
                        setErrorNumeroEnderecoTitular(false)
                    }
                    else if (key == 'phone') {
                        setErrorTelefoneTitular(false)
                    }
                }
            }
            return resultado
        }
        catch (err) {
            return false
        }
    }

    const addProdutoOnClick = (e, selected) => {
        try {
            e.preventDefault()
            if (productsList.length == 0) {
                setProductsList(product => [...product, selected])
                setValorFinal(parseFloat(valorFinal) + parseFloat(selected.Price))
                setIdsProductError(false)
            }
            else {
                setProductSelected('')
                toast.error('Só pode ser comprado um produto por vez', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            // let canAdd = true
            // for (let element of productsList)
            // {
            //     if(element.ID == selected.ID)
            //     {
            //         canAdd = false
            //     }
            // }
            // if(canAdd)
            // {
            //     setProductsList(product => [...product, selected])
            //     setValorFinal(parseFloat(valorFinal) + parseFloat(selected.Price))
            //     setIdsProductError(false)
            // }
            // else
            // {
            //     toast.error('Produto já adicionado',{
            //         position: "top-right",
            //         autoClose: 3000,
            //         hideProgressBar: false,
            //         closeOnClick: true,
            //         pauseOnHover: true,
            //         draggable: true,
            //         progress: undefined,
            //         theme: "light",
            //         });
            // }

            // setProductSelected('')
        }
        catch (err) {
            toast.error(err, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const addProdutoOnLoad = (selected) => {
        try {
            let canAdd = true
            for (let element of productsList) {
                if (element.ID == selected.ID) {
                    canAdd = false
                }
            }
            if (canAdd) {
                setProductsList(product => [...product, selected])
                setValorFinal(parseFloat(valorFinal) + parseFloat(selected.Price))
                setIdsProductError(false)
            }
            else {
                toast.error('Produto já adicionado', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }

            setProductSelected('')
        }
        catch (err) {
            toast.error(err, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const deleteProdutoOnClick = async (e, idProduto) => {
        try {
            let arrayAux = []
            let valorFinal = 0
            for await (const produto of productsList) {
                if (!(produto.ID == idProduto)) {
                    arrayAux.push(produto)
                    valorFinal += (parseFloat(valorFinal) + parseFloat(produto.Price))
                }
            }
            setValorFinal(valorFinal)
            setProductsList(arrayAux)
        }
        catch (err) {
            toast.error(err, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const listarSelectsAsync = async () => {
        try {
            await listarProdutos(states.isVendedor, states.isAdmin, 'All', 'All')
        }
        catch (err) {
            toast.error(err.response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const listarPermissoesAsync = async () => {
        try {
            await verificaPermissao()
        }
        catch (err) {
            toast.error(err.response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const verificarCpf = () => {
        const resultado = validarCpf(cpfTitular)
        setErrorCpfTitular(!resultado.status)
    }

    const verificarTelefone = () => {
        const resultado = (telefoneTitular.replaceAll(`_`, ``).replaceAll(`-`, ``).replaceAll(`(`, ``).replaceAll(`)`, ``).replaceAll(` `, ``).length == 11) ? true : false
        setErrorTelefoneTitular(!resultado)
    }

    const buscarDadosCliente = async () => {
        try {
            const result = await listarClientePorToken()
            if (result.status == true) {
                setNomeTitular(result.client.nome)
                setEmailTitular(result.client.email)
                setCpfTitular(result.client.cpf)
                setCepTitular(result.client.cep)
                setNumeroEnderecoTitular(result.client.numero)
                setComplementoEnderecoTitular(result.client.complemento)
                setTelefoneTitular(result.client.telefone)

                setErrorNomeTitular(false)
                setErrorEmailTitular(false)
                setErrorCpfTitular(false)
                setErrorCepTitular(false)
                setErrorNumeroEnderecoTitular(false)
                setErrorTelefoneTitular(false)

                toast.success('Dados carregados com sucesso!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                });
            } else {
                toast.error(result.msg, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                });
            }
        }
        catch (err) {
            toast.error(err, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
        }
    }


    const [nomeTitular, setNomeTitular] = useState('')
    const [emailTitular, setEmailTitular] = useState('')
    const [cpfTitular, setCpfTitular] = useState('')
    const [cepTitular, setCepTitular] = useState('')
    const [numeroEnderecoTitular, setNumeroEnderecoTitular] = useState('')
    const [complementoEnderecoTitular, setComplementoEnderecoTitular] = useState('')
    const [telefoneTitular, setTelefoneTitular] = useState('')

    const [errorNomeTitular, setErrorNomeTitular] = useState('')
    const [errorEmailTitular, setErrorEmailTitular] = useState('')
    const [errorCpfTitular, setErrorCpfTitular] = useState('')
    const [errorCepTitular, setErrorCepTitular] = useState('')
    const [errorNumeroEnderecoTitular, setErrorNumeroEnderecoTitular] = useState('')
    const [errorTelefoneTitular, setErrorTelefoneTitular] = useState('')

    const [isChecked, setChecked] = useState(false);

    const handleCheckboxChange = () => {
        setChecked(!isChecked);
    };


    const [imagem, setImagem] = useState('')

    useEffect(() => {
        constroiLogo()
    }, [])

    const constroiLogo = async () => {
        const origin = await getPath(window)
        setImagem(origin + '/logo.png')
    }

    const [dependentes, setDependentes] = useState([])
    const [modalDep, setModalDep] = useState(false)
    const addDependente = (dependente) => {
        const checkCpf = dependentes.filter((dep) => dep.cpf == dependente.cpf)
        if (checkCpf.length > 0) {
            toast.error('CPF já cadastrado', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light"
            });
            return
        }
        setValorFinal(parseFloat(valorFinal) + parseFloat(1000))
        setDependentes(dependentes => [...dependentes, dependente])
        setModalDep(false)
    }



    const removeDependente = (data) => {
        setValorFinal(parseFloat(valorFinal) - parseFloat(1000))
        setDependentes(dependentes.filter((dep) => dep.cpf != data.cpf))
    }


    return (
        <>
            <Navbar />

            <ModalDep add={addDependente} isOpen={modalDep} close={setModalDep} />
            <div className="">
                <div className="mx-auto max-w-2xl px-4 pb-24 pt-16 sm:px-6 lg:max-w-7xl lg:px-8">
                    <h2 className="sr-only">Checkout</h2>

                    <form className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16" onSubmit={e => onSubmitForm(e)}>
                        <div>
                            <div className="border-gray-200">
                                <h2 className="text-lg font-medium text-gray-900">Informações dos produtos</h2>
                                <div className="mt-2">
                                    <div className="">
                                        <div className="sm:col-span-2 sm:mt-0">
                                            <Listbox value={productSelected} onChange={setProductSelected}>
                                                {({ open }) => (
                                                    <>
                                                        {/* <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">Inserir produtos</Listbox.Label> */}
                                                        <div className="flex items-center justify-between">
                                                            <h2 className="block text-sm font-medium leading-6 text-gray-900">Selecionar produto</h2>
                                                            <button href="#" disabled={disabled} className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500" onClick={e => addProdutoOnClick(e, productSelected)}>
                                                                Adicionar produto
                                                            </button>
                                                        </div>
                                                        <div className="relative mt-2">
                                                            <Listbox.Button className="flex flex-1 relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                                                                {!(productSelected == '' || productSelected == undefined || productSelected == null) ?
                                                                    <span className="inline-flex w-full truncate">
                                                                        <span className="truncate">{productSelected.Title}</span>
                                                                        {!(productSelected.Price == '' || productSelected.Price == undefined || productSelected.Price == null)
                                                                            ?
                                                                            <span className="ml-2 truncate text-gray-500"> R$ {(productSelected.Price / 100).toLocaleString('pt-br', { minimumFractionDigits: 2 })}</span> :
                                                                            <span className="truncate text-gray-500"> Selecione ... </span>
                                                                        }
                                                                    </span>
                                                                    :
                                                                    <span className="inline-flex w-full truncate">
                                                                        <span className="truncate">Selecione ...</span>
                                                                    </span>
                                                                }
                                                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                </span>
                                                            </Listbox.Button>


                                                            <Transition
                                                                show={open}
                                                                as={Fragment}
                                                                leave="transition ease-in duration-100"
                                                                leaveFrom="opacity-100"
                                                                leaveTo="opacity-0"
                                                            >
                                                                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                    {states.products.length > 0 && states.products.map((product) => (
                                                                        product.IsDeleted == 0 &&
                                                                        <Listbox.Option
                                                                            key={product.ID}
                                                                            className={({ active }) =>
                                                                                classNames(
                                                                                    active ? 'bg-blue-500 text-white' : 'text-gray-900',
                                                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                                )
                                                                            }
                                                                            value={product}>
                                                                            {({ selected, active }) => (
                                                                                <>
                                                                                    <div className="flex">
                                                                                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'truncate')}>
                                                                                            {product.Title}
                                                                                        </span>
                                                                                        <span className={classNames(active ? 'text-indigo-200' : 'text-gray-500', 'ml-2 truncate')}>
                                                                                            R$ {(product.Price / 100).toLocaleString('pt-br', { minimumFractionDigits: 2 })}
                                                                                        </span>
                                                                                    </div>

                                                                                    {selected ? (
                                                                                        <span
                                                                                            className={classNames(
                                                                                                active ? 'text-white' : 'text-indigo-600',
                                                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                            )}
                                                                                        >
                                                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                                        </span>
                                                                                    ) : null}
                                                                                </>
                                                                            )}
                                                                        </Listbox.Option>
                                                                    )

                                                                    )}
                                                                </Listbox.Options>
                                                            </Transition>

                                                        </div>

                                                    </>
                                                )}
                                            </Listbox>
                                            {
                                                idsProductError === true &&
                                                <p class="mt-2 ml-1 text-pink-600 text-sm">
                                                    É obrigatório o preenchimento de pelo menos um produto.
                                                </p>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-2'>
                                    <div>
                                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                            Observações
                                        </label>
                                        <div className="mt-2">
                                            <textarea
                                                rows={6}
                                                name="comment"
                                                id="comment"
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                placeholder='Digite a observação'
                                                value={observacao}
                                                onChange={e => setObservacao(e.target.value)}
                                                defaultValue={''}
                                            />
                                            {
                                                observationError === true &&
                                                <p class="mt-2 ml-1 text-pink-600 text-sm">
                                                    É obrigatório o preenchimento da observação.
                                                </p>
                                            }
                                        </div>
                                    </div>
                                </div>


                                <div className="flex items-center justify-between mt-4">
                                    {/* <h2 className="block text-sm font-medium leading-6 text-gray-900">Selecionar produto</h2> */}
                                    <div className="flex items-center relative">
                                        <input
                                            id="checkboxRemember"
                                            name="remember-me"
                                            type="checkbox"
                                            checked={pagarBoleto}
                                            className="h-4 w-4 rounded border-gray-300 text-blue focus:ring-blue-600"
                                            onChange={() => pagarBoletoOnClick()} />
                                        <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                                            Pagar com boleto
                                        </label>
                                    </div>
                                    <p className="text-center text-sm text-gray-500">
                                        Folha de pagamento?{' '}
                                        {/* to="/clientes/cadastro"  */}
                                        <Link className="font-semibold leading-6 text-blue-500 hover:text-blue-600">
                                            Entre em contato
                                        </Link>
                                        {' '}com a SOMA
                                    </p>
                                </div>

                                <body class='flex flex-col justify-center mt-4 mb-4'>
                                    <div class="rounded-lg overflow-hidden shadow-lg" hidden={pagarBoleto}>
                                        <div class="flex justify-center p-10 bg-gradient-to-r from-green-300 via-blue-500 to-purple-600">
                                            <div class="w-64 h-40 bg-gradient-to-r from-blue-700 via-blue-800 to-gray-900 rounded-lg shadow-lg">
                                                <div class="flex justify-between m-2">

                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="34"
                                                        height="34"
                                                        viewBox="0 0 24 24"
                                                        stroke-width="1.5"
                                                        stroke="#ffffff"
                                                        fill="none"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    >
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <rect x="3" y="5" width="18" height="14" rx="3" />
                                                        <line x1="3" y1="10" x2="21" y2="10" />
                                                        <line x1="7" y1="15" x2="7.01" y2="15" />
                                                        <line x1="11" y1="15" x2="13" y2="15" />
                                                    </svg>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="34"
                                                        height="34"
                                                        viewBox="0 0 24 24"
                                                        stroke-width="1.5"
                                                        stroke="#ffffff"
                                                        fill="none"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    >
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <circle cx="9.5" cy="9.5" r="5.5" fill="#fff" />
                                                        <circle cx="14.5" cy="14.5" r="5.5" />
                                                    </svg>
                                                </div>
                                                <div class="flex justify-center mt-4">
                                                    <h1 class="text-gray-400 font-thin font-os">
                                                        {number == '' ? '**** **** **** ****' : number}
                                                    </h1>
                                                </div>
                                                <div class="flex flex-col justfiy-end mt-1 p-4 text-gray-400 font-quick">
                                                    <p class="font-bold text-xs">{cvv == 0 ? '000' : cvv}</p>
                                                    <p class="font-bold text-xs">{date}</p>
                                                    <h4 class="uppercase tracking-wider font-semibold text-xs">
                                                        {name == '' ? 'Nome impresso' : name}
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='flex-col'>
                                            <h3 className="text-base font-semibold leading-6 text-gray-900 text-center mt-4">Cartão</h3>
                                            <p className="flex mt-1 text-sm text-gray-500 justify-center">
                                                Informe os dados do seu cartão de crédito
                                            </p>
                                        </div>

                                        <div className="space-y-6 sm:space-y-5 p-5">
                                            <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                                <div className="mt-2 sm:col-span-1  sm:mt-0">
                                                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                                        Número
                                                    </label>
                                                    <div className="flex max-w-lg rounded-md shadow-sm">
                                                        <input
                                                            type="text"
                                                            name="numero"
                                                            id="numero"
                                                            placeholder='Digite o número ...'
                                                            value={number}
                                                            onChange={e => setNumber(e.target.value)}
                                                            maxLength={19}
                                                            // onBlur={e => leaveInput(props.states.emailAssociado,'email')}
                                                            className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                    {
                                                        errorNumber === true &&
                                                        <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                            {/* Forneça um endereço de e-mail válido. */}
                                                            É obrigatório o preenchimento de um número válido com 16 caracteres.
                                                        </p>
                                                    }
                                                </div>

                                                <div className="mt-2 sm:col-span-1 sm:mt-0">
                                                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                                        Vencimento
                                                    </label>
                                                    <div className="flex max-w-lg rounded-md shadow-sm">
                                                        <input
                                                            type="text"
                                                            name="vencimento"
                                                            id="vencimento"
                                                            placeholder='Digite a data de vencimento ...'
                                                            value={date}
                                                            onChange={e => setDate(e.target.value)}
                                                            maxLength={5}
                                                            // onBlur={e => leaveInput(props.states.celular,'celular')}
                                                            className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                    {
                                                        errorDate === true &&
                                                        <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                            {/* Forneça um endereço de e-mail válido. */}
                                                            É obrigatório o preenchimento de uma data válida.
                                                        </p>
                                                    }
                                                </div>

                                                <div className="mt-2 sm:col-span-1 sm:mt-0">
                                                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                                        Nome
                                                    </label>
                                                    <div className="flex max-w-lg rounded-md shadow-sm">
                                                        <input
                                                            type="text"
                                                            name="nome"
                                                            id="nome"
                                                            autoComplete="nome"
                                                            placeholder='Digite o nome ...'
                                                            value={name}
                                                            onChange={e => setName(e.target.value)}
                                                            maxLength={20}
                                                            // onBlur={e => leaveInput(props.states.cep,'cep')}
                                                            className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                    {
                                                        errorName === true &&
                                                        <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                            {/* Forneça um endereço de e-mail válido. */}
                                                            É obrigatório o preenchimento de um nome e sobrenome.
                                                        </p>
                                                    }
                                                </div>

                                                <div className="mt-2 sm:col-span-1 sm:mt-0">
                                                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                                        CVV
                                                    </label>
                                                    <div className="flex max-w-lg rounded-md shadow-sm">
                                                        <input
                                                            type="text"
                                                            name="bairro"
                                                            id="bairro"
                                                            autoComplete="bairro"
                                                            placeholder='Digite o CVV ...'
                                                            value={cvv == 0 ? '' : cvv}
                                                            onChange={e => setCvv(e.target.value)}
                                                            maxLength={3}
                                                            // onBlur={e => leaveInput(props.states.bairro,'bairro')}
                                                            className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />

                                                    </div>
                                                    {
                                                        errorCvv === true &&
                                                        <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                            {/* Forneça um endereço de e-mail válido. */}
                                                            É obrigatório o preenchimento do CVV.
                                                        </p>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='flex-col'>
                                            <h3 className="text-base font-semibold leading-6 text-gray-900 text-center mt-4">Titular</h3>
                                            <p className="flex mt-1 text-sm text-gray-500 justify-center">
                                                Informe os dados do titular do cartão de crédito
                                            </p>
                                            <div className="flex flex-1 justify-center border-gray-200 pt-4">
                                                <button
                                                    type="button"
                                                    className="w-[50%] rounded-md border border-transparent bg-blue-500 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                                                    onClick={() => buscarDadosCliente()}
                                                >
                                                    Usar dados do cadastro de cliente
                                                </button>
                                            </div>
                                        </div>

                                        <div className="space-y-6 sm:space-y-5 p-5">
                                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                                <div className="mt-2 sm:col-span-1  sm:mt-0">
                                                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                                        Nome
                                                    </label>
                                                    <div className="flex max-w-lg rounded-md shadow-sm">
                                                        <input
                                                            type="text"
                                                            name="nome"
                                                            id="nome"
                                                            placeholder='Digite o nome ...'
                                                            value={nomeTitular}
                                                            onChange={e => setNomeTitular(e.target.value)}
                                                            className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                    {
                                                        errorNomeTitular === true &&
                                                        <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                            {/* Forneça um endereço de e-mail válido. */}
                                                            É obrigatório o preenchimento do nome do titular.
                                                        </p>
                                                    }
                                                </div>

                                                <div className="mt-2 sm:col-span-1 sm:mt-0">
                                                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                                        Email
                                                    </label>
                                                    <div className="flex max-w-lg rounded-md shadow-sm">
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            id="email"
                                                            placeholder='Digite o email ...'
                                                            value={emailTitular}
                                                            onChange={e => setEmailTitular(e.target.value)}
                                                            className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                    {
                                                        errorEmailTitular === true &&
                                                        <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                            {/* Forneça um endereço de e-mail válido. */}
                                                            É obrigatório o preenchimento de um email válido.
                                                        </p>
                                                    }
                                                </div>

                                                <div className="mt-2 sm:col-span-1 sm:mt-0">
                                                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                                        CPF
                                                    </label>
                                                    <div className="flex max-w-lg rounded-md shadow-sm">
                                                        <InputMask
                                                            type="text"
                                                            name="cpf"
                                                            id="cpf"
                                                            autoComplete="cpf"
                                                            placeholder='Digite o CPF ...'
                                                            mask="999.999.999-99"
                                                            value={cpfTitular}
                                                            onChange={e => setCpfTitular(e.target.value)}
                                                            onBlur={() => verificarCpf()}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                    {
                                                        errorCpfTitular === true &&
                                                        <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                            {/* Forneça um endereço de e-mail válido. */}
                                                            É obrigatório o preenchimento de um CPF válido.
                                                        </p>
                                                    }
                                                </div>

                                                <div className="mt-2 sm:col-span-1 sm:mt-0">
                                                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                                        CEP
                                                    </label>
                                                    <div className="flex max-w-lg rounded-md shadow-sm">
                                                        <InputMask
                                                            type="text"
                                                            name="cep"
                                                            id="cep"
                                                            autoComplete="cep"
                                                            placeholder='Digite o CEP ...'
                                                            mask="99999-999"
                                                            value={cepTitular}
                                                            onChange={e => setCepTitular(e.target.value)}
                                                            // onBlur={() => handleCep(cep)}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                    {
                                                        errorCepTitular === true &&
                                                        <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                            {/* Forneça um endereço de e-mail válido. */}
                                                            É obrigatório o preenchimento do CEP.
                                                        </p>
                                                    }
                                                </div>

                                                <div className="mt-2 sm:col-span-1 sm:mt-0">
                                                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                                        Nº da residência
                                                    </label>
                                                    <div className="flex max-w-lg rounded-md shadow-sm">
                                                        <input
                                                            type="text"
                                                            name="numeroResidencia"
                                                            id="numeroResidencia"
                                                            autoComplete="numeroResidencia"
                                                            placeholder='Digite o Nº ...'
                                                            value={numeroEnderecoTitular}
                                                            onChange={e => setNumeroEnderecoTitular(e.target.value)}
                                                            className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />

                                                    </div>
                                                    {
                                                        errorNumeroEnderecoTitular === true &&
                                                        <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                            {/* Forneça um endereço de e-mail válido. */}
                                                            É obrigatório o preenchimento do N°.
                                                        </p>
                                                    }
                                                </div>

                                                <div className="mt-2 sm:col-span-1 sm:mt-0">
                                                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                                        Complemento
                                                    </label>
                                                    <div className="flex max-w-lg rounded-md shadow-sm">
                                                        <input
                                                            type="text"
                                                            name="complemento"
                                                            id="complemento"
                                                            autoComplete="complemento"
                                                            placeholder='Digite o complemento ...'
                                                            value={complementoEnderecoTitular}
                                                            onChange={e => setComplementoEnderecoTitular(e.target.value)}
                                                            className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />

                                                    </div>
                                                </div>

                                                <div className="mt-2 sm:col-span-1 sm:mt-0">
                                                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                                        Telefone
                                                    </label>
                                                    <div className="flex max-w-lg rounded-md shadow-sm">
                                                        <InputMask
                                                            type="text"
                                                            name="telefone"
                                                            id="telefone"
                                                            autoComplete="telefone"
                                                            placeholder='Digite o telefone ...'
                                                            mask="(99) 99999-9999"
                                                            value={telefoneTitular}
                                                            onChange={e => setTelefoneTitular(e.target.value)}
                                                            onBlur={() => verificarTelefone()}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />


                                                    </div>
                                                    {
                                                        errorTelefoneTitular === true &&
                                                        <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                            {/* Forneça um endereço de e-mail válido. */}
                                                            É obrigatório o preenchimento do telefone.
                                                        </p>
                                                    }
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </body>
                            </div>
                        </div>
                        <div className="lg:mt-0">
                            {
                                dependentes.length > 0 && (
                                    <>
                                        <h2 className="text-sm font-medium text-gray-700 mt-1">Dependentes adicionados</h2>
                                        <div className="mt-4 rounded-md border-0 shadow-sm ring-1 ring-inset ring-gray-300">
                                            <h3 className="sr-only">Items in your cart</h3>
                                            <ul role="list" className="divide-y divide-gray-200">
                                                {dependentes.length > 0 && dependentes.map((dependente) => (
                                                    <li key={dependente.cpf} className="flex px-4 py-6 sm:px-6">
                                                        <div className="flex-shrink-0">
                                                            <img src={imagem} crossOrigin="anonymous" className="h-20 w-auto object-cover object-center" />
                                                        </div>

                                                        <div className="ml-6 flex flex-1 flex-col">
                                                            <div className="flex">
                                                                <div className="min-w-0 flex-1">
                                                                    <h4 className="text-sm">
                                                                        <a className="font-medium text-gray-700 hover:text-gray-800">
                                                                            {dependente.name}
                                                                        </a>
                                                                    </h4>
                                                                    <p className="mt-1 text-sm text-gray-500">{dependente.cpf}</p>
                                                                    <p className="mt-1 text-sm text-gray-500">{dependente.email}</p>
                                                                    <p className="mt-1 text-sm text-gray-500">{dependente.parentesco}</p>
                                                                </div>
                                                                <div className="ml-4 flow-root flex-shrink-0">
                                                                    <button
                                                                        type="button"
                                                                        className="-m-2.5 flex items-center justify-center bg-white p-2.5 text-gray-400 hover:text-gray-500"
                                                                    >
                                                                        <span className="sr-only">Remove</span>
                                                                        <TrashIcon className="h-5 w-5" aria-hidden="true" onClick={e => removeDependente(dependente)} />
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            <div className="flex flex-1 items-end justify-between pt-2">
                                                                <p className="mt-1 text-sm font-medium text-gray-900">R$ 10,00  </p>

                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </>
                                )

                            }


                            <h2 className="text-sm font-medium text-gray-700 mt-1">Produtos adicionados</h2>
                            <div className="mt-4 rounded-md border-0 shadow-sm ring-1 ring-inset ring-gray-300">
                                <h3 className="sr-only">Items in your cart</h3>
                                <ul role="list" className="divide-y divide-gray-200">
                                    {productsList.length > 0 && productsList.map((product) => (
                                        <li key={product.ID} className="flex px-4 py-6 sm:px-6">
                                            <div className="flex-shrink-0">
                                                {product.Url == '' ?
                                                    <img src={imagem} crossOrigin="anonymous" className="w-20 rounded-md" />
                                                    :
                                                    <img src={product.Url} crossOrigin="anonymous" className="h-20 w-auto object-cover object-center" />
                                                }

                                            </div>

                                            <div className="ml-6 flex flex-1 flex-col">
                                                <div className="flex">
                                                    <div className="min-w-0 flex-1">
                                                        <h4 className="text-sm">
                                                            <a className="font-medium text-gray-700 hover:text-gray-800">
                                                                {product.Title}
                                                            </a>
                                                        </h4>
                                                        <p className="mt-1 text-sm text-gray-500">{product.Duration} meses</p>
                                                    </div>

                                                    <div className="ml-4 flow-root flex-shrink-0">
                                                        <button
                                                            type="button"
                                                            className="-m-2.5 flex items-center justify-center bg-white p-2.5 text-gray-400 hover:text-gray-500"
                                                        >
                                                            <span className="sr-only">Remove</span>
                                                            <TrashIcon className="h-5 w-5" aria-hidden="true" onClick={e => deleteProdutoOnClick(e, product.ID)} />
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="flex flex-1 items-end justify-between pt-2">
                                                    <p className="mt-1 text-sm font-medium text-gray-900">R$ {(product.Price / 100).toLocaleString('pt-br', { minimumFractionDigits: 2 })}  </p>

                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                <dl className="space-y-6 border-t border-gray-200 px-4 py-6 sm:px-6">
                                    <div className="flex items-center justify-between">
                                        <dt className="text-sm">Subtotal</dt>
                                        <dd className="text-sm font-medium text-gray-900">R$ {(valorFinal / 100).toLocaleString('pt-br', { minimumFractionDigits: 2 })}</dd>
                                    </div>
                                </dl>

                                <div className="flex items-center justify-between px-4 py-6 sm:px-6">
                                    <div className="flex items-center relative">
                                        <input
                                            id="agreeTerms"
                                            checked={isChecked}
                                            onChange={handleCheckboxChange}
                                            name="agreeTerms"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-blue focus:ring-blue-600"
                                        />
                                        <label htmlFor="agreeTerms" className="ml-2 block text-sm text-gray-900">
                                            Aceito os termos e condições do SOMA
                                        </label>
                                    </div>
                                    <div className="flex items-center relative">
                                        <p className="text-center text-sm text-gray-500">
                                            Para visualizar os termos
                                            {' '}
                                            <Link to="/termosgerais" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                                                clique aqui
                                            </Link>
                                        </p>
                                    </div>
                                </div>

                                <div className="border-t border-gray-200 px-4 py-6 sm:px-6 flex">
                                    <p className="text-sm font-medium text-gray-900 mr-2">Adicione dependente por R$ 10,00 a mais!</p>
                                    <button
                                        onClick={() => setModalDep(!modalDep)}
                                        type="button"
                                        className="w-[50%] rounded-md border border-transparent bg-blue-500 px-4 py-3 text-base font-medium text-white shadow-sm 
                    hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                                    >
                                        Adicionar
                                    </button>

                                </div>

                                <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                                    <button
                                        type="submit"
                                        disabled={!isChecked}
                                        className="w-full rounded-md border border-transparent bg-blue-500 px-4 py-3 text-base font-medium text-white shadow-sm 
                    hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                                    >
                                        Confirmar compra
                                    </button>
                                    {!isChecked && <p className="mt-4 text-center text-sm text-gray-500"> É necessario aceitar os termos e condições para finalizar a compra. </p>}
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>

            <Footer />

            <SpinnerOverlay />

        </>
    )
}
