import {useState, useContext, useEffect} from "react"
import {useNavigate, Link} from 'react-router-dom'
import {AuthContext} from "../../Context"
import { toast } from 'react-toastify';
import SpinnerOverlay from '../../Components/SpinnerOverlay/index'

const currentYear = new Date().getFullYear();

const Login = () => {

    const {states, setters, login, editarSenhaUsuario, getPath} = useContext(AuthContext)

    const hist = useNavigate()

    const [inputEmail,setInputEmail] = useState(true)
    const [remainSigned, setRemainSigned] = useState(false)

    const [changePassword, setChangePassword] = useState(false)
    const [newPassword, setNewPassword]= useState('')

    const [erroSenha, setErroSenha]= useState(false)
    const [erroTamanhoSenha, setErroTamanhoSenha]= useState(false)

    const action = async(e) => {
        e.preventDefault()
        try{
            if(changePassword)
            {
                const resEdit = await editarSenhaUsuario(states.email, newPassword)
                if(resEdit.status == true)
                {
                    toast.success('Senha trocada com sucesso!', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });
                    setChangePassword(false)
                }
                else
                {
                    toast.error(resEdit.msg, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });
                }
            }
            else
            {
                const resLogin = await login(remainSigned)
                if (resLogin.status == true) {
                    if(resLogin.changePassword != true)
                    {
                        if(states.compraDeslogado)
                        {
                            hist('/vendas/cadastro')
                            setters.setCompraDeslogado(false)
                        }
                        else
                        {
                            hist('/home')
                            setChangePassword(false)
                            setters.setCompraDeslogado(false)
                        }
                    }
                    else
                    {
                        setChangePassword(true)
                        toast.info('É necessário atualizar sua senha. Favor preencher os campos "Senha" e "Nova Senha"', {
                            position: "top-right",
                            autoClose: 6000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            });
                        setters.setPassword('')
                    }
                }
                else
                {
                    setChangePassword(false)
                    toast.error(resLogin.msg, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });
                }
            }
        }
        catch(err)
        {
            toast.error(err.response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
        }
    }

    const leaveInput = async() => {
        if (states.email === '') {
            setInputEmail(false)
        } else {
            setInputEmail(true)
        }
    }

    const remainSignedOnClick = () => {
        if(!remainSigned)
        {
            setRemainSigned(true)
        }
        else
        {
            setRemainSigned(false)
        }
    }

    const verificarSenha = () => 
    {
      if(states.password === newPassword)
      {
        setErroSenha(false)
        if(states.password.length < 8)
        {
            setErroTamanhoSenha(true)
        }
        else
        {
            setErroTamanhoSenha(false)
        }
      }
      else
      {
        setErroSenha(true)
        if(states.password.length < 8)
        {
            setErroTamanhoSenha(true)
        }
        else
        {
            setErroTamanhoSenha(false)
        }
      }
    }

    useEffect(()=>{
       const email = localStorage.getItem('email')
       if(email != null)
       {
        setters.setEmail(email)
        setRemainSigned(true)
       }

       const password = localStorage.getItem('password')
       if(password != null)
       {
        setters.setPassword(password)
        setRemainSigned(true)
       }
    },[])

    const [imagem, setImagem] = useState('')

    useEffect(()=>{
        constroiLogo()
    },[])

    const constroiLogo = async () =>
    {
        const origin = await getPath(window)
        setImagem(origin + '/logo.png')
    }


    return ( 
        <>
        <SpinnerOverlay/>
            <div className="h-screen">
                <div className="flex min-h-full">
                    <div
                        className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 ">
                            {/* border-2 border-inherit */}
                        <div className="mx-auto w-full max-w-sm lg:w-96">
                            <div className="justify-center">
                                <img
                                    className="h-12 w-auto"
                                    src={imagem}
                                    crossOrigin="anonymous"
                                    alt="Magnavita"/>
                                <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">Seja bem-vindo</h2>

                            </div>

                            <div className="mt-8">
                                <div>
                                    <div className="relative mt-6">
                                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                            <div className="w-full border-t border-gray-300"/>
                                        </div>
                                        <div className="relative flex justify-center text-sm">
                                            <span className="bg-white px-2 text-gray-500">Faça seu login</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-6">
                                    <form action="#" method="POST" className="space-y-6" onSubmit={e => action(e)}>
                                        <div>
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium leading-6 text-gray-900">
                                                E-mail
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    readOnly={changePassword}
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    autoComplete="email"
                                                    onChange={e => setters.setEmail(e.target.value)}
                                                    onBlur={leaveInput}
                                                    required
                                                    value={states.email}
                                                    className={inputEmail === true
                                                    ? "block w-full rounded-md border py-1.5 border border-grey-500 shadow-sm ring-1 ring-inset ring-gr" +
                                                        "ay-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue" +
                                                            "-500 sm:text-sm sm:leading-6"
                                                    : "block w-full rounded-md border-1 py-1.5 border border-red-500 shadow-sm ring-1 r" +
                                                        "ing-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset " +
                                                            "focus:ring-blue-500 sm:text-sm sm:leading-6"}/>
                                            </div>
                                            {
                                                inputEmail === false && 
                                                <p class="mt-2 text-pink-600 text-sm">
                                                    {/* Forneça um endereço de e-mail válido. */}
                                                    É obrigatório o preenchimento de e-mail.
                                                </p>
                                            }
                                            
                                        </div>

                                        <div className="space-y-1">
                                            <label
                                                htmlFor="password"
                                                className="block text-sm font-medium leading-6 text-gray-900">
                                                {!changePassword?'Senha':'Nova Senha'}
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    id="password"
                                                    name="password"
                                                    type="password"
                                                    autoComplete="password"
                                                    onChange={e => setters.setPassword(e.target.value)}
                                                    required
                                                    value={states.password}
                                                    className="block w-full rounded-md border py-1.5 border border-grey-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"/>
                                            </div>
                                        </div>

                                        <div className="space-y-1" hidden={!changePassword}>
                                            <label
                                                htmlFor="password"
                                                className="block text-sm font-medium leading-6 text-gray-900">
                                                Confirme a Senha
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    id="newPassword"
                                                    name="newPassword"
                                                    type="password"
                                                    autoComplete="newPassword"
                                                    onChange={e => setNewPassword(e.target.value)}
                                                    value={newPassword}
                                                    onBlur={()=> verificarSenha()}
                                                    className="block w-full rounded-md border py-1.5 border border-grey-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"/>
                                                {
                                                    erroSenha === true && 
                                                    <p class="mt-2 ml-1 text-pink-600 text-sm">
                                                        As senhas não conferem.
                                                    </p>
                                                }
                                                {
                                                    erroTamanhoSenha === true && 
                                                    <p class="mt-2 ml-1 text-pink-600 text-sm">
                                                        A senha deve ter ao menos 8 caracteres.
                                                    </p>
                                                }
                                            </div>
                                        </div>

                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center">
                                                <input
                                                    id="checkboxRemember"
                                                    name="remember-me"
                                                    type="checkbox"
                                                    checked={remainSigned}
                                                    className="h-4 w-4 rounded border-gray-300 text-blue focus:ring-blue-600" onChange={() => remainSignedOnClick()}/>
                                                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                                                    Lembrar-me
                                                </label>
                                            </div>


                                            <div className="text-sm">
                                                <a href="/forgot" className="font-medium text-blue-500 hover:text-blue-600">
                                                    Esqueceu sua senha?
                                                </a>
                                            </div>
                                        </div>

                                        <div>
                                            <button
                                                type="submit"
                                                className="flex w-full justify-center rounded-md bg-blue-500 py-2 px-3 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 hover:bg-blue-600">
                                                {!changePassword?'Entrar':'Enviar'}
                                            </button>
                                        </div>

                                        <p className="mt-10 text-center text-sm text-gray-500">
                                            Não possui cadastro?{' '}
                                            <Link to="/clientes/cadastro" className="font-semibold leading-6 text-blue-500 hover:text-blue-600">
                                                Cadastre-se agora
                                            </Link>
                                        </p>

                                        <footer
                                            class="flex justify-center w-full bottom-[0px] h-20 bg-white border-t border-gray-900/10">
                                            <div class="mx-auto max-w-7xl overflow-hidden">
                                                <p class="text-center text-xs leading-5 text-gray-400 py-8">&copy; {currentYear} Soter Tec. Todos os direitos reservados.</p>
                                            </div> 
                                        </footer>
                                          
                                    </form>
                                    
                                </div>
                            </div>
                        </div>

                        
                        
                    </div>
                    <div className="relative hidden w-0 flex-1 lg:block">
                        <img
                            className="absolute inset-0 h-full w-full object-cover"
                            src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                            alt=""/>
                    </div>

                    
                </div>
                
            </div>

            
        </>
      )
}

export default Login
