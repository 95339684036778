import { useContext, useEffect, useState } from 'react'
import { AuthContext } from "../../Context/index"
import { toast } from 'react-toastify';
// import { AuthContextApi } from '../../Context/api'
import { useParams,useNavigate } from 'react-router-dom';


export default function ChangePassword() {
    const { changePassword, getPath } = useContext(AuthContext)
    const { token }=useParams();
    const hist = useNavigate()

    const [senha,setSenha] = useState('')
    const [confirmaSenha,setConfirmaSenha] = useState('')

    const [errorSenha,setErrorSenha] = useState(false)

    useEffect(() => {
        if(token == null || token == '' || token == undefined)
        {
          hist('/')
        }
    }, [])

    const enviarMudanca = async (e) =>
    {
        try{
            e.preventDefault()
            if(errorSenha == false)
            {
                const result = await changePassword(token, senha)
                if(result.status == true)
                {
                    toast.success('Senha modificada com sucesso!', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });
                    hist('/')
                }
                else{
                    toast.error(result.msg, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });
                }
            }
            else
            {
                toast.error('As senhas não conferem!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        }
        catch(err)
        {
            toast.error(err.response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
        }
    }

    const [imagem, setImagem] = useState('')

    useEffect(()=>{
        constroiLogo()
    },[])

    const constroiLogo = async () =>
    {
        const origin = await getPath(window)
        setImagem(origin + '/logo.png')
    }

    return(
        <div className="flex min-h-screen bg-gray-100 flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
                className="mx-auto h-16 w-32"
                src={imagem}
                crossOrigin="anonymous"
                alt="Your Company"
            />
            <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Digite a nova senha para efetuar a troca
            </h2>
            </div>
            <form className="space-y-6" action="#" method="POST">
            <div>
                <label htmlFor="newPassword" className="block text-sm font-medium leading-6 text-gray-900">
                Nova Senha
                </label>
                <div className="mt-2">
                <input
                        type="password"
                        name="senha"
                        id="senha"
                        autoComplete="senha"
                        placeholder='Digite a nova senha ..'
                        //   disabled={disabled}
                        value={senha}
                        onChange={e => setSenha(e.target.value)}
                        onBlur={() => senha == confirmaSenha ? setErrorSenha(false) : setErrorSenha(true)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                </div>
                {
                    errorSenha === true && 
                    <p class="mt-2 ml-2 text-pink-600 text-sm">
                        As senhas não conferem.
                    </p>
                }
            </div>

            <div>
                <label htmlFor="newPassword" className="block text-sm font-medium leading-6 text-gray-900">
                Nova Senha
                </label>
                <div className="mt-2">
                <input
                        type="password"
                        name="novaSenha"
                        id="novaSenha"
                        autoComplete="novaSenha"
                        placeholder='Confirme a nova senha ..'
                        //   disabled={disabled}
                        value={confirmaSenha}
                        onChange={e => setConfirmaSenha(e.target.value)}
                        onBlur={() => senha == confirmaSenha ? setErrorSenha(false) : setErrorSenha(true)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                </div>
                {
                    errorSenha === true && 
                    <p class="mt-2 ml-2 text-pink-600 text-sm">
                        As senhas não conferem.
                    </p>
                }
            </div>


            <div>
                <button
                disabled={senha=='' && confirmaSenha==''}
                type="button"
                className="flex w-full justify-center rounded-md bg-blue-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                onClick={e=> enviarMudanca(e)}
                >
                Enviar
                </button>
            </div>
            </form>
        </div>
        </div>
  </div>
    )

}