import {BrowserRouter} from 'react-router-dom';
import Router from './Routes';
import AuthProvider from './Context';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
    return (
        <AuthProvider>
            <BrowserRouter>
                <Router/>
                <ToastContainer/>
            </BrowserRouter>
        </AuthProvider>
    );
}

export default App;

