import { Fragment, useRef, useState, useEffect, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { AuthContext } from "../../../../Context"
import InputMask from "react-input-mask";
import { toast } from 'react-toastify';

export default function ModalCartao(props) {
    const { carregarUsuario, editarPagamentoPlano, validarCpf } = useContext(AuthContext)
    const cancelButtonRef = useRef(null)

    const [number, setNumber] = useState('')
    const [name, setName] = useState('')
    const [date, setDate] = useState('')
    const [cvv, setCvv] = useState(0)

    const [nomeTitular, setNomeTitular] = useState('')
    const [emailTitular, setEmailTitular] = useState('')
    const [cpfTitular, setCpfTitular] = useState('')
    const [cepTitular, setCepTitular] = useState('')
    const [numeroEnderecoTitular, setNumeroEnderecoTitular] = useState('')
    const [complementoEnderecoTitular, setComplementoEnderecoTitular] = useState('')
    const [telefoneTitular, setTelefoneTitular] = useState('')

    const [errorNumber, setErrorNumber] = useState(false)
    const [errorName, setErrorName] = useState(false)
    const [errorDate, setErrorDate] = useState(false)
    const [errorCvv, setErrorCvv] = useState(false)
    const [errorNomeTitular, setErrorNomeTitular] = useState('')
    const [errorEmailTitular, setErrorEmailTitular] = useState('')
    const [errorCpfTitular, setErrorCpfTitular] = useState('')
    const [errorCepTitular, setErrorCepTitular] = useState('')
    const [errorNumeroEnderecoTitular, setErrorNumeroEnderecoTitular] = useState('')
    const [errorTelefoneTitular, setErrorTelefoneTitular] = useState('')


    useEffect(() => {
        let numeroCartao = number

        numeroCartao = numeroCartao.replace(/\D/g, '') // Permite apenas dígitos
        numeroCartao = numeroCartao.replace(/(\d{4})/g, '$1 ') // Coloca um ponto a cada 4 caracteres
        numeroCartao = numeroCartao.replace(/\.$/, '') // Remove o ponto se estiver sobrando
        numeroCartao = numeroCartao.substring(0, 19) // Limita o tamanho

        setNumber(numeroCartao)

    }, [number])

    useEffect(() => {
        let dataExpiracao = date

        dataExpiracao = dataExpiracao.replace(/\D/g, '') // Permite apenas dígitos
        dataExpiracao = dataExpiracao.replace(/(\d{2})/g, '$1/') // Coloca um ponto a cada 4 caracteres
        dataExpiracao = dataExpiracao.substring(0, 5) // Limita o tamanho

        setDate(dataExpiracao)

    }, [date])

    useEffect(() => {
        if(props.states.modalIsOpenAux == false)
        {
            setNumber('')
            setName('')
            setDate('')
            setCvv(0)
        
            setNomeTitular('')
            setEmailTitular('')
            setCpfTitular('')
            setCepTitular('')
            setNumeroEnderecoTitular('')
            setComplementoEnderecoTitular('')
            setTelefoneTitular('')

            setErrorNumber(false)
            setErrorName(false)
            setErrorDate(false)
            setErrorCvv(false)
            setErrorNomeTitular(false)
            setErrorEmailTitular(false)
            setErrorCpfTitular(false)
            setErrorCepTitular(false)
            setErrorNumeroEnderecoTitular(false)
            setErrorTelefoneTitular(false)
        }
    }, [props.states.modalIsOpenAux])

    const buscarDadosCliente = async () => {
        try{
            const result = await carregarUsuario(props.client.UserID)
            if (result.status == true) {
                const endereco = JSON.parse(props.client.Address)
                setEmailTitular(result.user.Email)
                setNomeTitular(props.client.Name)
                setCpfTitular(props.client.CPF)
                setTelefoneTitular(props.client.Phone)

                setCepTitular(endereco.cep)
                setNumeroEnderecoTitular(endereco.number)
                setComplementoEnderecoTitular(endereco.complement)

                setErrorNomeTitular(false)
                setErrorEmailTitular(false)
                setErrorCpfTitular(false)
                setErrorCepTitular(false)
                setErrorNumeroEnderecoTitular(false)
                setErrorTelefoneTitular(false)

                toast.success('Dados carregados com sucesso!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                });
            } else {
                toast.error(result.msg, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                });
            }
        }
        catch (err) 
        {
            toast.error(err, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
        }
    }

    const onSubmitForm = async (e) => {
        e.preventDefault()
        try 
        {
            const cartao = {
                number: number.replaceAll(' ',''),
                ccv: cvv,
                holderName: name,
                expiryMonth: date.split('/')[0],
                expiryYear: date.split('/')[1],
                name: nomeTitular,
                email: emailTitular,
                cpfCnpj: cpfTitular,
                postalCode: cepTitular,
                addressNumber: numeroEnderecoTitular,
                addressComplement: complementoEnderecoTitular,
                phone: telefoneTitular
            }
            const verificarCartao = await verificarStatesCartao(cartao)
            if(verificarCartao == true)
            {
                const creditCard = {
                    number: number.replaceAll(' ',''),
                    ccv: cvv,
                    holderName: name,
                    expiryMonth: date != '' ? date.split('/')[0] : '',
                    expiryYear: date != '' ? date.split('/')[1]: ''
                }
    
                const creditCardHolderInfo = {
                    name: nomeTitular,
                    email: emailTitular,
                    cpfCnpj: cpfTitular.replaceAll('.','').replaceAll('-',''),
                    postalCode: cepTitular.replaceAll('-',''),
                    addressNumber: numeroEnderecoTitular,
                    addressComplement: complementoEnderecoTitular == '' ? null:complementoEnderecoTitular,
                    phone: telefoneTitular.replaceAll(' ','').replaceAll('-','').replaceAll('(','').replaceAll(')','')
                }

                const result = await editarPagamentoPlano(props.idProduct, props.idSale, creditCard, creditCardHolderInfo)
                if(result.status == true)
                {
                    toast.success('Cartão cadastrado com sucesso', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
                    props.setters.setModalIsOpenAux(false)
                }
                else
                {
                    toast.error('Erro ao cadastrar cartão no gateway', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
                }
            }
            else
            {
                toast.error('Dados do cartão inválidos', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        } 
        catch (err) 
        {
            toast.error(err.response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
        }
    }

    const verificarStatesCartao = async (cartao) =>{
        try{
            let resultado = true
            for await(const key of Object.keys(cartao))    
            {                    
            if(cartao[key] == '' || cartao[key] == ' ')
            {
                if(key == 'number')
                { 
                    resultado = false 
                    setErrorNumber(true)
                }
                else if(key == 'ccv')
                { 
                    resultado = false 
                    setErrorCvv(true)
                }
                else if(key == 'holderName')
                { 
                    resultado = false 
                    setErrorName(true)
                }
                else if(key == 'expiryMonth' || key == 'expiryYear')
                { 
                    resultado = false 
                    setErrorDate(true)
                }
                else if(key == 'name')
                { 
                    resultado = false 
                    setErrorNomeTitular(true)
                }
                else if(key == 'email')
                { 
                    resultado = false 
                    setErrorEmailTitular(true)
                }
                else if(key == 'cpfCnpj')
                { 
                    resultado = false 
                    setErrorCpfTitular(true)
                }
                else if(key == 'postalCode')
                { 
                    resultado = false 
                    setErrorCepTitular(true)
                }
                else if(key == 'addressNumber')
                { 
                    resultado = false 
                    setErrorNumeroEnderecoTitular(true)
                }
                else if(key == 'phone')
                { 
                    resultado = false 
                    setErrorTelefoneTitular(true)
                }
            }
            else
            {
                if(key == 'number')
                {
                    if(number.replaceAll(' ','').length != 16)
                    {
                        resultado = false
                        setErrorNumber(true)
                    }
                    else
                    {
                        setErrorNumber(false)
                    }
                }
                else if(key == 'ccv')
                {
                    setErrorCvv(false)
                }
                else if(key == 'holderName')
                { 
                    setErrorName(false)
                }
                else if(key == 'expiryMonth' || key == 'expiryYear')
                {
                    if(date == '00/00' || date == '')
                    {
                        resultado = false 
                        setErrorDate(true)
                    }
                    else
                    {
                        setErrorDate(false)
                    }
                }
                else if(key == 'name')
                { 
                    setErrorNomeTitular(false)
                }
                else if(key == 'email')
                { 
                    setErrorEmailTitular(false)
                }
                else if(key == 'cpfCnpj')
                { 
                    setErrorCpfTitular(false)
                }
                else if(key == 'postalCode')
                { 
                    setErrorCepTitular(false)
                }
                else if(key == 'addressNumber')
                { 
                    setErrorNumeroEnderecoTitular(false)
                }
                else if(key == 'phone')
                { 
                    setErrorTelefoneTitular(false)
                }
            }
            }
            return resultado
        }
        catch(err)
        {
            return false
        }
    }

    const verificarCpf = () => {
        const resultado = validarCpf(cpfTitular)
        setErrorCpfTitular(!resultado.status)
    }

    const verificarTelefone = () => {
        const resultado = (telefoneTitular.replaceAll(`_`,``).replaceAll(`-`,``).replaceAll(`(`,``).replaceAll(`)`,``).replaceAll(` `,``).length == 11) ? true : false
        setErrorTelefoneTitular(!resultado)
    }

  return (
    <Transition.Root show={props.states.modalIsOpenAux} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => props.setters.setModalIsOpenAux(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <form onSubmit={e => onSubmitForm(e)}>
                <body class='flex flex-col justify-center mt-4 mb-4'>
                    <div class="rounded-lg overflow-hidden shadow-lg">
                        <div class="flex justify-center p-10 bg-gradient-to-r from-green-300 via-blue-500 to-purple-600">
                            <div class="w-64 h-40 bg-gradient-to-r from-blue-700 via-blue-800 to-gray-900 rounded-lg shadow-lg">
                                <div class="flex justify-between m-2">
                                    
                                    <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="34"
                                    height="34"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="#ffffff"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <rect x="3" y="5" width="18" height="14" rx="3" />
                                    <line x1="3" y1="10" x2="21" y2="10" />
                                    <line x1="7" y1="15" x2="7.01" y2="15" />
                                    <line x1="11" y1="15" x2="13" y2="15" />
                                    </svg>
                                    <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="34"
                                    height="34"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="#ffffff"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <circle cx="9.5" cy="9.5" r="5.5" fill="#fff" />
                                    <circle cx="14.5" cy="14.5" r="5.5" />
                                    </svg>
                                </div>
                                <div class="flex justify-center mt-4">
                                    <h1 class="text-gray-400 font-thin font-os">
                                    {number == ''? '**** **** **** ****': number}
                                    </h1>
                                </div>
                                <div class="flex flex-col justfiy-end mt-1 p-4 text-gray-400 font-quick">
                                    <p class="font-bold text-xs">{cvv == 0? '000':cvv}</p>
                                    <p class="font-bold text-xs">{date}</p>
                                    <h4 class="uppercase tracking-wider font-semibold text-xs">
                                    {name ==''?'Nome impresso':name}
                                    </h4>
                                </div>
                            </div>
                        </div>

                        <div className='flex-col'>
                            <h3 className="text-base font-semibold leading-6 text-gray-900 text-center mt-4">Cartão</h3>
                            <p className="flex mt-1 text-sm text-gray-500 justify-center">
                            Informe os dados do seu cartão de crédito
                            </p>
                        </div>

                        <div className="space-y-6 sm:space-y-5 p-5">
                            <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <div className="mt-2 sm:col-span-2  sm:mt-0">
                                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                        Número
                                    </label>
                                        <div className="flex max-w-lg rounded-md shadow-sm">
                                        <input
                                            type="text"
                                            name="numero"
                                            id="numero"
                                            placeholder= 'Digite o número ...'
                                            value={number}
                                            onChange={e => setNumber(e.target.value)}
                                            maxLength={19}
                                            // onBlur={e => leaveInput(props.states.emailAssociado,'email')}
                                            className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                        </div>
                                        {
                                        errorNumber === true && 
                                        <p class="mt-2 ml-2 text-pink-600 text-sm">
                                            {/* Forneça um endereço de e-mail válido. */}
                                            É obrigatório o preenchimento de um número válido com 16 caracteres.
                                        </p>
                                    }
                                    </div>
                                
                                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                        Vencimento
                                    </label>
                                        <div className="flex max-w-lg rounded-md shadow-sm">
                                        <input
                                            type="text"
                                            name="vencimento"
                                            id="vencimento"
                                            placeholder='Digite a data de vencimento ...'
                                            value={date}
                                            onChange={e => setDate(e.target.value)}
                                            maxLength={5}
                                            // onBlur={e => leaveInput(props.states.celular,'celular')}
                                            className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                        </div>
                                        {
                                        errorDate === true && 
                                        <p class="mt-2 ml-2 text-pink-600 text-sm">
                                            {/* Forneça um endereço de e-mail válido. */}
                                            É obrigatório o preenchimento de uma data válida.
                                        </p>
                                    }
                                    </div>

                                <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                    Nome
                                </label>
                                    <div className="flex max-w-lg rounded-md shadow-sm">
                                    <input
                                        type="text"
                                        name="nome"
                                        id="nome"
                                        autoComplete="nome"
                                        placeholder='Digite o nome ...'
                                        value={name}
                                        onChange={e =>setName(e.target.value)}
                                        maxLength={20}
                                        // onBlur={e => leaveInput(props.states.cep,'cep')}
                                        className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    </div>
                                    {
                                    errorName === true && 
                                    <p class="mt-2 ml-2 text-pink-600 text-sm">
                                        {/* Forneça um endereço de e-mail válido. */}
                                        É obrigatório o preenchimento de um nome e sobrenome.
                                    </p>
                                }
                                </div>

                                <div className="mt-2 sm:col-span-2 sm:mt-0">
                                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                        CVV
                                    </label>
                                        <div className="flex max-w-lg rounded-md shadow-sm">
                                        <input
                                            type="text"
                                            name="bairro"
                                            id="bairro"
                                            autoComplete="bairro"
                                            placeholder='Digite o CVV ...'
                                            value={cvv == 0? '': cvv}
                                            onChange={e => setCvv(e.target.value)}
                                            maxLength={3}
                                            // onBlur={e => leaveInput(props.states.bairro,'bairro')}
                                            className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />

                                        </div>
                                        {
                                        errorCvv === true && 
                                        <p class="mt-2 ml-2 text-pink-600 text-sm">
                                            {/* Forneça um endereço de e-mail válido. */}
                                            É obrigatório o preenchimento do CVV.
                                        </p>
                                    }
                                </div> 
                            </div>
                        </div>

                        <div className='flex-col'>
                            <h3 className="text-base font-semibold leading-6 text-gray-900 text-center mt-4">Titular</h3>
                            <p className="flex mt-1 text-sm text-gray-500 justify-center">
                                Informe os dados do titular do cartão de crédito
                            </p>
                            <div className="flex flex-1 justify-center border-gray-200 pt-4">
                                <button
                                type="button"
                                className="w-[50%] rounded-md border border-transparent bg-blue-500 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                                onClick={()=> buscarDadosCliente()}
                                >
                                Usar dados do cadastro de cliente
                                </button>
                            </div>
                        </div>

                        <div className="space-y-6 sm:space-y-5 p-5">
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                <div className="mt-2 sm:col-span-1  sm:mt-0">
                                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                    Nome
                                </label>
                                    <div className="flex max-w-lg rounded-md shadow-sm">
                                    <input
                                        type="text"
                                        name="nome"
                                        id="nome"
                                        placeholder= 'Digite o nome ...'
                                        value={nomeTitular}
                                        onChange={e => setNomeTitular(e.target.value)}
                                        className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    </div>
                                    {
                                    errorNomeTitular === true && 
                                    <p class="mt-2 ml-2 text-pink-600 text-sm">
                                        {/* Forneça um endereço de e-mail válido. */}
                                        É obrigatório o preenchimento do nome do titular.
                                    </p>
                                }
                                </div>
                            
                                <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                    Email
                                </label>
                                    <div className="flex max-w-lg rounded-md shadow-sm">
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        placeholder='Digite o email ...'
                                        value={emailTitular}
                                        onChange={e => setEmailTitular(e.target.value)}
                                        className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    </div>
                                    {
                                    errorEmailTitular === true && 
                                    <p class="mt-2 ml-2 text-pink-600 text-sm">
                                        {/* Forneça um endereço de e-mail válido. */}
                                        É obrigatório o preenchimento de um email válido.
                                    </p>
                                }
                                </div>

                                <div className="mt-2 sm:col-span-1 sm:mt-0">
                                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                    CPF
                                </label>
                                    <div className="flex max-w-lg rounded-md shadow-sm">
                                        <InputMask
                                            type="text"
                                            name="cpf"
                                            id="cpf"
                                            autoComplete="cpf"
                                            placeholder='Digite o CPF ...'
                                            mask="999.999.999-99"
                                            value={cpfTitular}
                                            onChange={e =>setCpfTitular(e.target.value)}
                                            onBlur={()=> verificarCpf()}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                    {
                                        errorCpfTitular === true && 
                                        <p class="mt-2 ml-2 text-pink-600 text-sm">
                                            {/* Forneça um endereço de e-mail válido. */}
                                            É obrigatório o preenchimento de um CPF válido.
                                        </p>
                                    }
                                </div>

                                <div className="mt-2 sm:col-span-2 sm:mt-0">
                                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                        Telefone
                                    </label>
                                        <div className="flex max-w-lg rounded-md shadow-sm">
                                        {/* <input
                                            type="text"
                                            name="telefone"
                                            id="telefone"
                                            autoComplete="telefone"
                                            placeholder='Digite o telefone ...'
                                            value={telefoneTitular}
                                            onChange={e => setTelefoneTitular(e.target.value)}
                                            className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        /> */
                                        }
                                        <InputMask
                                            type="text"
                                            name="telefone"
                                            id="telefone"
                                            autoComplete="telefone"
                                            placeholder='Digite o telefone ...'
                                            mask="(99) 99999-9999"
                                            value={telefoneTitular}
                                            onChange={e => setTelefoneTitular(e.target.value)}
                                            onBlur={() => verificarTelefone()}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        

                                        </div>
                                        {
                                        errorTelefoneTitular === true && 
                                        <p class="mt-2 ml-2 text-pink-600 text-sm">
                                            {/* Forneça um endereço de e-mail válido. */}
                                            É obrigatório o preenchimento do telefone.
                                        </p>
                                    }
                                </div> 

                                <div className="mt-2 sm:col-span-1 sm:mt-0">
                                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                        CEP
                                    </label>
                                        <div className="flex max-w-lg rounded-md shadow-sm">
                                            <InputMask
                                            type="text"
                                            name="cep"
                                            id="cep"
                                            autoComplete="cep"
                                            placeholder='Digite o CEP ...'
                                            mask="99999-999"
                                            value={cepTitular}
                                            onChange={e => setCepTitular(e.target.value)}
                                            // onBlur={() => handleCep(cep)}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                        </div>
                                        {
                                        errorCepTitular === true && 
                                        <p class="mt-2 ml-2 text-pink-600 text-sm">
                                            {/* Forneça um endereço de e-mail válido. */}
                                            É obrigatório o preenchimento do CEP.
                                        </p>
                                    }
                                </div> 

                                <div className="mt-2 sm:col-span-1 sm:mt-0">
                                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                        Nº da residência
                                    </label>
                                        <div className="flex max-w-lg rounded-md shadow-sm">
                                        <input
                                            type="text"
                                            name="numeroResidencia"
                                            id="numeroResidencia"
                                            autoComplete="numeroResidencia"
                                            placeholder='Digite o Nº ...'
                                            value={numeroEnderecoTitular}
                                            onChange={e => setNumeroEnderecoTitular(e.target.value)}
                                            className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />

                                        </div>
                                        {
                                        errorNumeroEnderecoTitular === true && 
                                        <p class="mt-2 ml-2 text-pink-600 text-sm">
                                            {/* Forneça um endereço de e-mail válido. */}
                                            É obrigatório o preenchimento do N°.
                                        </p>
                                    }
                                </div> 

                                <div className="mt-2 sm:col-span-1 sm:mt-0">
                                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                        Complemento
                                    </label>
                                        <div className="flex max-w-lg rounded-md shadow-sm">
                                        <input
                                            type="text"
                                            name="complemento"
                                            id="complemento"
                                            autoComplete="complemento"
                                            placeholder='Digite o complemento ...'
                                            value={complementoEnderecoTitular}
                                            onChange={e => setComplementoEnderecoTitular(e.target.value)}
                                            className="block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />

                                        </div>
                                </div> 

                            </div>
                            
                        </div>

                    </div>
                </body>


                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="submit"
                    className="inline-flex w-full justify-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                  >
                    Enviar
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => props.setters.setModalIsOpenAux(false)}
                  >
                    Cancelar
                  </button>
                </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
