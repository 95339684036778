import { Fragment, useState,useEffect,useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useContext } from "react"
import { AuthContext } from "../../../Context"
import { CheckIcon,XMarkIcon,WalletIcon } from '@heroicons/react/24/outline'

import { Listbox } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom'

import ModalCartao from '../Util/Cartao'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ModalSale(props) {

  const {cadastrarVenda, carregarVenda, listarClientePorId} = useContext(AuthContext)

  const [idProductChange, setIdProductChange] = useState(false)

  const [productSelected, setProductSelected] = useState(props.states.products)
  const [clientSelected, setClientSelected] = useState(props.states.clients)
  const [observacao, setObservacao] = useState('')

  const [clientError,setClientError]= useState(false)
  const [idsProductError,setIdsProductError]= useState(false)
  const [observationError,setObservationError]= useState(false)
  
  let [productsList , setProductsList] = useState('')

  const cancelButtonRef = useRef(null)
  const [disabled , setDisabled] = useState(false)
  
  const addVendaOnClick = async(e) =>
  {
    try{
      e.preventDefault()
      let arrayIds = []
      let totalPrice = 0


      for await(const produto of productsList)
      {
        let id = produto.ID
        arrayIds.push({id})
        totalPrice+=produto.Price
      }

      const sale = {idClient: clientSelected.ID, origin: 'Vendedor', value: totalPrice, observation:observacao, idsProduct: arrayIds, companyID: localStorage.getItem('companyID')}
      const verificar = await verificarStatesModalSale(sale)
      if(verificar == true)
      {
        const result = await cadastrarVenda(sale)
        if(result.status == true)
        {
          toast.success('Venda cadastrada com sucesso!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            props.setters.setModalIsOpen(false)
        }
        else
        {
          toast.error('Venda não pôde ser cadastrada!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        }
      }
      else
      {
          toast.error('Venda não pôde ser cadastrada!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
      }
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }

  const addProdutoOnClick = (e,selected) =>
  {
    try{
      if(!(selected==''))
      {
        e.preventDefault()
        
        if(productsList.length == 0)
        {
          setProductsList(products => [...products, selected])
          setIdsProductError(false)
          
        }
        else
        {
          setProductSelected('')
          toast.error('Só pode ser comprado um produto por vez',{
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
        }
        // let canAdd = true
        // for (let element of productsList)
        // {
        //   if(element.ID == selected.ID)
        //   {
        //     canAdd = false
        //   }
        // }
        // if(canAdd)
        // {
        //   setProductsList(products => [...products, selected])
        //   setIdsProductError(false)
        // }
        // else
        //     {
        //         toast.error('Produto já adicionado',{
        //             position: "top-right",
        //             autoClose: 3000,
        //             hideProgressBar: false,
        //             closeOnClick: true,
        //             pauseOnHover: true,
        //             draggable: true,
        //             progress: undefined,
        //             theme: "light",
        //             });
        //     }
        
        // setProductSelected('')
      }
    }
    catch(err)
    {
      toast.error(err, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }

  const deleteProdutoOnClick = async (e,idProduto) =>
  {
    try
    {
      let arrayAux = []
      for await (const produto of productsList)
      {
        if(!(produto.ID == idProduto))
        {
          arrayAux.push(produto)
        }
      }
      setProductsList(arrayAux)
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }

  const listarSelectsAsync = async() => {
    try{
      await props.listarClientes('All', 'All')
      await props.listarProdutos(props.states.isVendedor, props.states.isAdmin, 'All', 'All')
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        });
    }
  }

  const limparStatesModalSale = () =>
  {
    setClientSelected('')
    setProductSelected('')
    setObservacao('')
    setProductsList('')
    setIdsProductError('')
    setObservationError('')
    setClientError('')
  }

  const buscarCliente = async (id) =>
  {
    try{
      await listarClientePorId(id)
      setClientError(false)
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }

  const verificarStatesModalSale= async (sale) =>
  {
    try{
      let resultado = true
      for await(const key of Object.keys(sale)) {
        if(sale[key] == '')
        {
          if(key == 'idClient')   
          {
            resultado = false
            setClientError(true)
          }
          else if(key== 'idsProduct')
          {
            resultado = false
            setIdsProductError(true)
          }
          else if(key == 'observation')
          {
            resultado=false
            setObservationError(true)
          }
        }
        else
        {
          if(key == 'idClient')
          {
            setClientError(false)
          }
          else if(key == 'idsProduct')
          {
            setIdsProductError(false)
          }
          else if(key == 'observation')
          {
            setObservationError(false)
          }
        }
      }
      return resultado
    }
    catch(err)
    {
      return false
    }
  }

  useEffect(() => {
    try{
      setClientSelected(props.states.clients)
    }
    catch(err)
    {
      toast.error(err, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }, []);

  useEffect(() => {
    try{
      if(props.states.modalIsOpen === false)
      {
        limparStatesModalSale()
      }
      else
      {
        if(!(props.id == null || props.id == undefined || props.id == ''))
        {
          setDisabled(true)
          carregarVenda(props.id)
        }
        else
        {
          props.setters.setProducts('')
          setDisabled(false)
          listarSelectsAsync()
          props.setters.setSaleSelected('')
          props.setters.setSaleSelectedProducts('')
          props.setters.setSaleSelectedClient('')
        }
      }
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }, [props.states.modalIsOpen]);

  useEffect(() => {
    try
    {
      if(props.states.saleSelected!= '')
      {
        buscarCliente(props.states.saleSelected.ClientID)
        setObservacao(props.states.saleSelected.Observation)
        setProductsList(props.states.saleSelectedProducts)
      }
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }, [props.states.saleSelected]);

  useEffect(() => {
    try
    {
      if(props.states.saleSelectedClient!= '')
      {
        setClientSelected(props.states.saleSelectedClient)
      }
    }
    catch(err)
    {
      toast.error(err, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }, [props.states.saleSelectedClient]);

  const changeMethodOnClick= async (e,idProduto) =>
  {
    e.preventDefault()
    props.setters.setModalIsOpenAux(true)
    setIdProductChange(idProduto)
  }

  
  return (
    <Transition.Root show={props.states.modalIsOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={()=>props.setters.setModalIsOpen(false)} >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"> */}
              <Dialog.Panel className="lg:w-[50%] md:w-[50%] xsm:w-[70%] transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
              <form className="space-y-8 divide-y divide-gray-200" onSubmit={e=> addVendaOnClick(e)} >
                      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                        <div className="space-y-6 sm:space-y-5">
                            <div className='flex-col'>
                              <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Venda</h3>
                              <p className="flex mt-1 text-sm text-gray-500 justify-center">
                                Informações da venda
                              </p>
                            </div>


                            {(props.id == null || props.id == undefined || props.id == '')?

                              <div className="space-y-6 sm:space-y-5">
                                {/* 1 LINHA */}
                                <div className="sm:grid sm:grid-cols-1 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-3">
                                  
                                  <div className="mt-2 sm:col-span-1 sm:mt-0">


                                    <Listbox value={clientSelected} onChange={setClientSelected}> 
                                    {/* setClientSelected */}
                                      {({ open }) => (
                                        <>
                                          {/* <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900" >Selecionar cliente</Listbox.Label> */}
                                          <div className="flex items-center justify-between">
                                            <h2 className="block text-sm font-medium leading-6 text-gray-900">Selecionar cliente</h2>
                                            <Link to="../clientes/cadastro" rel="noopener noreferrer" disabled={disabled} className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                                                Novo cliente
                                            </Link>
                                          </div>
                                          <div className="relative mt-2">
                                            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                                              {clientSelected != ''?
                                              <span className="inline-flex w-full truncate">
                                                <span className="truncate">{clientSelected.Name}</span>
                                                <span className="ml-2 truncate text-gray-500">{clientSelected.Phone}</span>
                                              </span>
                                              :
                                              <span className="inline-flex w-full truncate">
                                                <span className="truncate">Selecione ...</span>
                                              </span>
                                              }
                                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                              </span>
                                            </Listbox.Button>

                                            <Transition
                                              show={open}
                                              as={Fragment}
                                              leave="transition ease-in duration-100"
                                              leaveFrom="opacity-100"
                                              leaveTo="opacity-0"
                                            >
                                              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                {props.states.clients.length > 0 && props.states.clients.map((client) => (
                                                  <Listbox.Option
                                                    key={client.ID}
                                                    className={({ active }) =>
                                                      classNames(
                                                        active ? 'bg-blue-500 text-white' : 'text-gray-900',
                                                        'relative cursor-default select-none py-2 pl-3 pr-9'
                                                      )
                                                    }
                                                    value={client}
                                                  >
                                                    {({ selected, active }) => (
                                                      <>
                                                        <div className="flex">
                                                          <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'truncate')}>
                                                            {client.Name}
                                                          </span>
                                                          <span className={classNames(active ? 'text-indigo-200' : 'text-gray-500', 'ml-2 truncate')}>
                                                            {client.Phone}
                                                          </span>
                                                        </div>

                                                        {selected ? (
                                                          <span
                                                            className={classNames(
                                                              active ? 'text-white' : 'text-indigo-600',
                                                              'absolute inset-y-0 right-0 flex items-center pr-4'
                                                            )}
                                                          >
                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                          </span>
                                                        ) : null}
                                                      </>
                                                    )}
                                                  </Listbox.Option>
                                                ))}
                                              </Listbox.Options>
                                            </Transition>
                                          </div>
                                        </>
                                      )}
                                    </Listbox>
                                    {
                                      clientError === true && 
                                      <p class="mt-2 ml-1 text-pink-600 text-sm">
                                          É obrigatório o preenchimento do cliente.
                                      </p>
                                    }
                                  </div>
                                  
                                </div>
                              </div>
                              :
                              <div className="space-y-6 sm:space-y-5">
                                <div className="sm:grid sm:grid-cols-1 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-3">
                                  
                                  <div className="sm:col-span-1 sm:mt-0">
                                  <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                    Cliente
                                  </label>
                                    <div className="">
                                      <input
                                        readOnly
                                        type="text"
                                        name="client"
                                        id="client"
                                        autoComplete="client"
                                        placeholder='Digite o cliente'
                                        value={clientSelected.Name+' - '+clientSelected.Phone}
                                        className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>
                                  
                                </div>
                              </div>
                            }
                          
                            <div className="space-y-6 sm:space-y-5">
                              {/* 2 LINHA */}
                              <div className="sm:grid sm:grid-cols-1 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-3">
                                
                                <div className="sm:col-span-1 sm:mt-0">
                                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                  Observação
                                </label>
                                  <div className="">
                                    <input
                                      disabled={disabled}
                                      type="text"
                                      name="observation"
                                      id="observation"
                                      autoComplete="observation"
                                      placeholder='Digite a observação'
                                      value={observacao}
                                      onChange={e => setObservacao(e.target.value)}
                                      className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                  </div>
                                  {
                                      observationError === true && 
                                      <p class="mt-2 ml-1 text-pink-600 text-sm">
                                          É obrigatório o preenchimento da observação.
                                      </p>
                                    }
                                </div>
                                
                              </div>
                            </div>

                            <div className="space-y-6 sm:space-y-5">
                              {/* 3 LINHA */}
                              <div className="sm:grid sm:grid-cols-1 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                
                                {(props.id == null || props.id == undefined || props.id == '')&&
                                  <div className="mt-2 sm:col-span-1 sm:mt-0">
                                    <Listbox value={productSelected} onChange={setProductSelected}>
                                      {({ open }) => (
                                        <>
                                          {/* <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">Inserir produtos</Listbox.Label> */}
                                          <div className="flex items-center justify-between">
                                            <h2 className="block text-sm font-medium leading-6 text-gray-900">Selecionar produto</h2>
                                            <button type="button" disabled={disabled} className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500" onClick={e => addProdutoOnClick(e,productSelected)}>
                                                Adicionar produto
                                            </button>
                                          </div>
                                          <div className="relative mt-2">
                                            <Listbox.Button className="flex flex-1 relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                                            {!(productSelected == '' || productSelected == undefined || productSelected == null)?
                                              <span className="inline-flex w-full truncate">
                                                <span className="truncate">{productSelected.Title}</span>
                                                {!(productSelected.Price == '' || productSelected.Price == undefined || productSelected.Price == null)
                                                ?
                                                <span className="ml-2 truncate text-gray-500"> R$ {(productSelected.Price/100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</span>:
                                                <span className="truncate text-gray-500"> Selecione ... </span>
                                              }
                                              </span>
                                              :
                                              <span className="inline-flex w-full truncate">
                                                <span className="truncate">Selecione ...</span>
                                              </span>
                                              }
                                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                              </span>
                                            </Listbox.Button>
                                            

                                            <Transition
                                              show={open}
                                              as={Fragment}
                                              leave="transition ease-in duration-100"
                                              leaveFrom="opacity-100"
                                              leaveTo="opacity-0"
                                            >
                                              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                {props.states.products.length>0 && props.states.products.map((product) => (
                                                  product.IsDeleted == 0 &&
                                                  <Listbox.Option
                                                    key={product.ID}
                                                    className={({ active }) =>
                                                      classNames(
                                                        active ? 'bg-blue-500 text-white' : 'text-gray-900',
                                                        'relative cursor-default select-none py-2 pl-3 pr-9'
                                                      )
                                                    }
                                                    value={product}>
                                                      {({ selected, active }) => (
                                                        <>
                                                          <div className="flex">
                                                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'truncate')}>
                                                              {product.Title}
                                                            </span>
                                                            <span className={classNames(active ? 'text-indigo-200' : 'text-gray-500', 'ml-2 truncate')}>
                                                            R$ {(product.Price/100).toLocaleString('pt-br', {minimumFractionDigits: 2})}
                                                            </span>
                                                          </div>

                                                          {selected ? (
                                                            <span
                                                              className={classNames(
                                                                active ? 'text-white' : 'text-indigo-600',
                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                              )}
                                                            >
                                                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                            </span>
                                                          ) : null}
                                                        </>
                                                      )}
                                                  </Listbox.Option>
                                                )
                                                
                                                )}
                                              </Listbox.Options>
                                            </Transition>

                                          </div>
                                          
                                        </>
                                      )}
                                    </Listbox>
                                    {
                                        idsProductError === true && 
                                        <p class="mt-2 ml-1 text-pink-600 text-sm">
                                            É obrigatório o preenchimento de pelo menos um produto.
                                        </p>
                                      }
                                  </div>
                                }

                                {productsList.length > 0 &&
                                <div>
                                  <h2 className="block text-sm font-small leading-6 text-gray-700">Produto(s) adicionado(s)</h2>
                                  <div className="mt-6 overflow-hidden border-t border-gray-100">
                                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                      <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                                        <table className="w-full text-left">
                                          <tbody>
                                          {productsList.map((product) => (
                                              <Fragment key={product.ID}>
                                                <tr className="text-sm leading-6 text-gray-900">
                                                  <th scope="colgroup" colSpan={3} className="relative isolate py-2 font-semibold">
                                                    {product.Title}
                                                    <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                                                    <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                                                  </th>
                                                </tr>
                                                  <tr >
                                                    <td className="relative py-5 pr-6">
                                                      <div className="flex gap-x-6">
                                                        <div className="flex-auto">
                                                          <div className="flex items-start gap-x-3">
                                                          R$ {(product.Price/100).toLocaleString('pt-br', {minimumFractionDigits: 2})}  
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                                                      <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                                                    </td>
                                                    <td className="hidden py-5 pr-6 sm:table-cell">
                                                      <div className="text-sm leading-6 text-gray-900">Duração: {product.Duration} meses</div>
                                                    </td>
                                                    <td className="hidden py-5 pr-6 sm:table-cell">
                                                      <div className="text-sm leading-6 text-gray-900">Valor: R$ { parseFloat(product.Price/100).toFixed(2).toString().replace('.',',')}</div>
                                                    </td>

                                                    {disabled == true && product.BillingType == 'BOLETO' &&
                                                      <td className="hidden py-5 sm:table-cell">
                                                        <button type='Button' className="hidden sm:inline p-2" title='Mudar plano para cartão'>
                                                            <WalletIcon className="w-5" aria-hidden="true" onClick={e => changeMethodOnClick(e,product.ID)}/>
                                                            {/* onClick={()=>changePaymentMethod(product.ID)} */}
                                                        </button>
                                                      </td>
                                                    }
                                                    
                                                    {disabled == false &&
                                                      <td className="py-5 text-right">
                                                        <div className="flex justify-end">
                                                          <a // href={transaction.href} LINK AQUI 
                                                            // onClick={()=>setters.setModalIsOpen(true)}
                                                            className="text-sm font-medium leading-6 text-indigo-600 hover:text-indigo-500">
                                                            <button type="button" className="hidden sm:inline p-2" onClick={e => deleteProdutoOnClick(e, product.ID)}>
                                                                <XMarkIcon className="w-3.5" aria-hidden="true" />  
                                                            </button>
                                                          </a>
                                                        </div>
                                                      </td>
                                                    }
                                                  </tr>
                                              </Fragment>
                                           ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                }
                                
                              </div>
                            </div>

                        </div>
                      </div>

                        {!disabled? 
                          <div className="pt-5" >
                            <div className="flex justify-end gap-x-3">
                              <button
                                type="button"
                                className="rounded-md bg-white py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                onClick={()=>props.setters.setModalIsOpen(false)}
                              >
                                Cancelar
                              </button>
                              <button
                              
                                type="submit"
                                className="inline-flex justify-center rounded-md bg-blue-500 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              >
                                Adicionar
                              </button>
                            </div>
                          </div>: 
                          <div className="pt-5">
                            <div className="flex justify-end gap-x-3">
                              <button
                                type="button"
                                className="rounded-md bg-white py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                onClick={()=>props.setters.setModalIsOpen(false)}
                              >
                                Cancelar
                              </button>
                            </div>
                          </div>
                        }
              </form>
              <ModalCartao states={props.states} setters={props.setters} idProduct={idProductChange} idSale={props.id} client={clientSelected}/>   {/*  setConfirm={setConfirm} */}
                
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
