import { useState ,useContext,useEffect, useRef } from 'react'
import { AuthContext} from "../../Context"
import ModalAddUser from '../../Components/Modal/User'
import Navbar from '../../Components/Navbar/navbar'
import { toast } from 'react-toastify';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { LockOpenIcon, LockClosedIcon, MagnifyingGlassIcon} from '@heroicons/react/24/outline'

import Footer from '../../Components/Footer/footer'
import Error from '../../Components/EmptyGrid'
import Spinner from '../../Components/Spinner'

import SpinnerOverlay from '../../Components/SpinnerOverlay';

const statuses = {
  0: 'text-green-700 bg-green-50 ring-green-600/20 ',
  1: 'text-red-700 bg-red-50 ring-red-600/10 ',
}


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Usuarios() {
  const {states, setters, listarUsuarios, ativarUsuario, excluirUsuario, verificaPermissao, listarEmpresas} = useContext(AuthContext)
  const [userSelected, setUserSelected] = useState('')
  const [searchType, setSearchType] = useState('Todos')
  const [offset, setOffset] = useState(0)
  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) {
      listarUsuariosAsync()
    } else {
      mounted.current = true;
    }
  }, [states.isAdmin, states.isVendedor, states.isComum, searchType, offset]);

  useEffect(()=>{
    verificarPermissaoAsync()
    listarUsuariosAsync()
  },[])

  const verificarPermissaoAsync = async() =>
  {
    try{
      await verificaPermissao()
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined, 
        theme: "light",
        });
    }
  }

  const listarUsuariosAsync = async() => {
    try{
      if(searchType == null || searchType == '' || searchType == undefined)
      {
        await listarUsuarios(10, offset, 'All')
      }
      else
      {
        if(searchType == 'Todos')
        {
          await listarUsuarios(10, offset, 'All')
        }
        else
        {
          let busca
          if(searchType == 'Cliente')
          {
            busca = 'Comum'
          }
          else
          {
            busca = searchType
          }
          await listarUsuarios(10, offset, busca)
        }
      }
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }

  const listarUsuariosPermissaoAsync = async(permissao) => {
    setSearchType(permissao)
  }

  const viewUserOnClick = async (user) => {
    setUserSelected(user)
    setters.setModalIsOpen(true)
  }

  const activateUserOnClick = async (id) => {
    try{
      const result = await ativarUsuario(id)
      if(result.status == true)
      {
          toast.success('Usuário ativado com sucesso!', {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
              listarUsuariosAsync()
      }
      else
      {
          toast.error(result.msg, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
      }
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
    
  }

  const deleteUserOnClick = async (id) => {
  try{
      const result = await excluirUsuario(id)
      if(result.status == true)
      {
          toast.success('Usuário inativado com sucesso!', {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
              listarUsuariosAsync()
      }
      else
      {
          toast.error(result.msg, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
      }
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }

  return (
    <>

     <Navbar/>

      <main>

        <div className="pt-6 xl:space-y-20">
          {/* Recent activity table */}
          <div className="">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              {/* <h1 className="text-base font-semibold leading-6 text-gray-900">Usuários Magnavita</h1>
              <div className="sm:grid sm:grid-cols-2 sm:items-start sm:gap-4 sm:pt-4">
                  <div className="mt-2 sm:col-span-1 sm:mt-0">
                    <div className="flex max-w rounded-md">
                      <label className='inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-2 text-gray-500 sm:text-sm py-1'>
                        Permissão
                      </label>
                      <select
                      id="permissionFilter"
                      name="permissionFilter"
                      className="inline-flex items-center rounded-r-md border border-gray-300 px-2 text-gray-500 sm:text-sm "
                      onChange={e => listarUsuariosPermissaoAsync(e.target.value)}
                      value={searchType}
                      >
                        <option>Todos</option>
                        <option>Admin</option>
                        <option>Vendedor</option>
                        <option>Cliente</option>
                      </select>
                    </div>
                  </div>

                  <div className="mt-2 sm:col-span-1 sm:mt-0">
                    <div className="flex max-w rounded-md">
                      
                      <div className="flex-1 flex bg-white pl-2 justify-end items-center">
                        <button className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                          onClick={()=>setters.setModalIsOpen(true)}  >
                          Adicionar usuário
                        </button>
                      </div> 

                    </div>
                  </div>
                  
              </div> */}
              <h1 className="text-base font-semibold leading-6 text-gray-900">Usuários do sistema</h1>
              <h1 className="mt-4 text-sm font-semibold leading-3 text-gray-500">Pesquisar por permissão</h1>
              <div className="sm:grid sm:grid-cols-2 sm:items-start sm:gap-4 mt-3">
                  <div className="sm:col-span-1 sm:mt-0">
                    <div className="flex max-w rounded-md">

                    <form className="flex md:ml-0">
                      <div className="relative w-full text-gray-400 focus-within:text-gray-600 border border-gray-300 rounded-md">
                        {/* <div className="pointer-events-none absolute inset-y-0 left-0 pl-1 flex items-center rounded-md">
                          <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                        </div> */}
                        {/* <input
                          id="search-field"
                          name="search-field"
                          type="search"
                          className="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 focus:border-transparent focus:outline-none focus:ring-0 focus:placeholder:text-gray-400 sm:text-sm rounded-md"
                          placeholder='Digite ..'
                          maxLength={14}
                          onChange={e => setSearchType(e.target.value)}
                          onBlur={()=>pesquisaEmpresa()}
                          value={searchType}
                        /> */}
                         <select
                          id="permissionFilter"
                          name="permissionFilter"
                          className="block h-full w-full border-transparent py-2 text-gray-900 focus:border-transparent focus:outline-none focus:ring-0 focus:placeholder:text-gray-400 sm:text-sm rounded-md"
                          onChange={e => listarUsuariosPermissaoAsync(e.target.value)}
                          value={searchType}
                          >
                            <option>Todos</option>
                            <option>Admin</option>
                            <option>Vendedor</option>
                            <option>Cliente</option>
                          </select>
                      </div>
                    </form>
                    </div>
                  </div>

                  <div className="mt-2 sm:col-span-1 sm:mt-0">
                    <div className="flex max-w rounded-md">
                      
                      <div className="flex-1 flex bg-white pl-2 justify-end items-center">
                        <button className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                          onClick={()=>setters.setModalIsOpen(true)}  >
                          Adicionar usuário
                        </button>
                      </div> 

                    </div>
                  </div>
                  
              </div>
              {states.loading == true?
                  <Spinner/>
              :
              states.users.length !== 0  ?
                <div className='flex flex-1 flex-col'>
                  <div className=''>
                    <div className="py-2 mt-6 flow-root">
                      <div className="-my-4 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg"> 
                              <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Status
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        E-mail
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Permissão
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Data Criação
                                    </th>

                                    <th scope="col" className="text-left text-sm font-semibold text-gray-900">
                                        <span className="sr-only">Verificar</span>
                                    </th>
                                  
                                    <th scope="col" className="">
                                        <span className="sr-only">Visualizar</span>
                                    </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                  {states.users.map((user) => (
                                    <tr key={user.email} className="hover:bg-gray-50">
                                        <td className={classNames(
                                            statuses[user.IsDeleted],
                                            'ml-1 inline-flex items-center rounded-full px-3 py-1.5 mt-3 text-xs font-medium capitalize'
                                            )} >
                                              {user.IsDeleted == 0 ? 'Ativo':'Inativo'}
                                          </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.Email}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.Permission}</td>
                                        
                                        <td className='whitespace-nowrap px-3  text-sm text-gray-500'>
                                            {user.CreatedAt.replace('T', ' ').replace('.000Z', '')}
                                        </td>
                                        
                                        <td className="relative whitespace-nowrap text-right text-sm font-medium sm:pr-6">

                                        {user.IsDeleted ? 
                                        <button  className="hidden sm:inline p-2">
                                            <LockOpenIcon className="w-3.5" aria-hidden="true" onClick={()=>activateUserOnClick(user.ID)}/>  
                                        </button>
                                        :
                                        <>
                                          {user.Permission == 'Vendedor' &&
                                            <button  className="hidden sm:inline p-2">
                                                <MagnifyingGlassIcon className="w-3.5" aria-hidden="true" onClick={()=>viewUserOnClick(user)}/>  
                                            </button>
                                          }
                                          <button  className="hidden sm:inline p-2">
                                              <LockClosedIcon className="w-3.5" aria-hidden="true" onClick={()=>deleteUserOnClick(user.ID)}/>  
                                          </button>
                                        </>
                                        }
                                        </td>
                                    </tr>
                                    ))
                                  }
                                </tbody>
                              </table>
                          </div>
                          </div>
                      </div>

                      <nav
                        className="flex items-center justify-between border-t border-gray-200 bg-white py-6"
                        aria-label="Pagination"
                      >
                        <div className="hidden sm:block">
                          <p className="text-sm text-gray-700 flex-1 ">
                            Mostrando <span className="font-medium">
                              {(offset*10)+1}
                            </span> a <span className="font-medium">
                            {states.loadMoreUsers
                            ?
                            (offset+1)*10
                            :
                            (offset*10)+states.users.length%10
                           }
                            
                              </span> de{' '}
                            <span className="font-medium">
                              {states.totalRegisters !== '' && states.totalRegisters}
                              </span> Usuários
                          </p>
                        </div>
                        <div className="flex flex-1 justify-between sm:justify-end">
                          {/* {offset > 0 && */}
                            <button
                              disabled = {!(offset > 0)}
                              type="button"
                              className="relative inline-flex items-center rounded-l-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                              onClick={()=> setOffset(offset-1)}
                           >
                              <span className="sr-only">Previous</span>
                              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                          {/* } */}

                          <button
                            disabled = {!states.loadMoreUsers}
                            type="button"
                            className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                            onClick={()=> setOffset(offset+1)}
                          >
                            <span className="sr-only">Next</span>
                            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                          </button>

                        </div>
                      </nav>
                    </div>
                  </div>
                </div>
              :
              <Error origin={'usuários a serem exibidos'}/>
              }

            </div>
          </div>
        </div>
      </main>

      <Footer/>

      <ModalAddUser states={states} setters={setters} listarEmpresas={listarEmpresas} user={userSelected} setUserSelected={setUserSelected}/>
      <SpinnerOverlay/>
    </>
  )
}




