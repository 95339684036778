import { Fragment, useState,useEffect,useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useContext } from "react"
import { AuthContext } from "../../../Context"
import { CheckIcon } from '@heroicons/react/24/outline'
import { toast } from 'react-toastify';
import { Listbox } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import SpinnerOverlay from '../../SpinnerOverlay/index'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ModalAddUser(props) {
  
  const cancelButtonRef = useRef(null)

  const {cadastrarUsuario,listarUsuarios,editarUsuario} = useContext(AuthContext)
  const [disabled , setDisabled] = useState(true)
  const [email,setEmail]= useState('')
  const [permission,setPermission]= useState('Vendedor')

  const [comission,setComission]= useState('')
  const [comissionError,setComissionError]= useState(false)

  const [companySelected, setCompanySelected] = useState(props.states.companies)

  const [emailError, setEmailError] = useState(false)
  const [permissionError, setPermissionError] = useState(false)
  const [idsCompanyError, setIdsCompanyError] = useState(false)

  useEffect(() => {
    if(props.states.modalIsOpen === false)
    {
        limparStatesModalUser()
        listarUsuariosAsync()
        setDisabled(false)
        props.setUserSelected('')
    }
    else
    {
      listarSelectsAsync()
      if(props.user!='')
      {
        setComission(props.user.Comission/100)
        setDisabled(true)
      }
    }
  }, [props.states.modalIsOpen]);

  useEffect(() => {
    if(permission != 'Vendedor')
    {
      setComission('')
      setComissionError(false)

      setCompanySelected('')
      setCompanySelected(false)
    }
    
  }, [permission]);

  const enviarFormularioOnClick= async(e)=>
  {
    try
    {
      if(props.user == '')
      {
        cadastrarUsuarioOnClick(e)
      }
      else
      {
        editarUsuarioOnClick(e)
      }
    }
    catch(err)
    {

    }
  }

  const cadastrarUsuarioOnClick = async (e) =>
  {
    try{
      e.preventDefault()
      let user
      if(permission == 'Vendedor')
      {
        const comissao = parseFloat(comission.replace(',', '.'))
        user = {email: email, password: '0', permission: permission, companyID: companySelected.ID, comission: comissao*100}
      }
      else
      {
        user = {email: email, password: '0', permission: permission, companyID: '', comission: ''}
      }
      
      const retorno  = await verificarStatesModalUser(user,false)
      if(retorno)
      {
        const result = await cadastrarUsuario(user)
        if(result.status == true)
        {
          toast.success('Usuário cadastrado com sucesso!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            props.setters.setModalIsOpen(false)
        }
        else
        {
          toast.error(result.msg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        }
      }
      else
      {
        toast.error('Usuário não pôde ser cadastrado!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      }
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }

  const editarUsuarioOnClick = async (e) =>
  {
    try{
      e.preventDefault()
      
      const comissao = parseFloat(comission.replace(',', '.'))
      const user = {id: props.user.ID, comission: comissao*100}
      const retorno  = await verificarStatesModalUser(user, true)
      if(retorno)
      {
        const result = await editarUsuario(user)
        if(result.status == true)
        {
          toast.success('Usuário editado com sucesso!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            props.setters.setModalIsOpen(false)
        }
        else
        {
          toast.error(result.msg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        }
      }
      else
      {
        toast.error('A comissão deve ser preenchida', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      }
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }

  const verificarStatesModalUser = async (user, editar) =>
  {
    try{
      let retorno = true
      if(!editar)
      {
        if(user.email == '' || user.email == null || user.email == undefined)
        {
          retorno = false
          setEmailError(true)
        }
        else
        {
          setEmailError(false)
        }

        if(user.permission == '' || user.permission == null || user.permission == undefined)
        {
          retorno = false
          setPermissionError(true)
        }
        else
        {
          setPermissionError(false)
        }

        if(user.permission == 'Vendedor')
        {
          if(user.companyID == '' || user.companyID == null || user.companyID == undefined)
          {
            retorno = false
            setIdsCompanyError(true)
          }
          else
          {
            setIdsCompanyError(false)
          }

          if(user.comission == '' || user.comission == null || user.comission == undefined || user.comission == '0' || user.comission == '0,00')
          {
            retorno=false
            setComissionError(true)
          }
          else
          {
            setComissionError(false)
          }
        }
      }
      else
      {
        if(user.comission == '' || user.comission == null || user.comission == undefined || user.comission == '0' || user.comission == '0,00')
        {
          retorno=false
          setComissionError(true)
        }
        else
        {
          setComissionError(false)
        }
      }
      
      return retorno
    }
    catch(err)
    {
      return false
    }
  }

  const limparStatesModalUser = async () =>
  {
      setEmail('') 
      setPermission('Vendedor')
      setComission('') 
      setCompanySelected('')
      setIdsCompanyError(false)
      setEmailError(false)
      setPermissionError(false)
      setComissionError(false)
  }

  const listarUsuariosAsync = async() => {
    try{
      await listarUsuarios()
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }

  const listarSelectsAsync = async() => {
    try{
      await props.listarEmpresas('All','All')
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }

  const handleChange = (inputValue) => {
    try{
      // if(inputValue.length > 0){
      //   const value = inputValue.replace(/[^0-9]/g, '')
      //   const valueMoney = value / 100 
      //   setComission(valueMoney)
      // }
      // else
      // {
      //   setComission('')
      // }
      if (inputValue.length > 0) {
        const value = inputValue.replace(/[^0-9]/g, '')
        const arrPrice = value.split('')
        if (arrPrice.length > 2) {
          arrPrice.splice(-2, 0, ',')
          const valueString = arrPrice.join('')
          setComission(valueString)
        } else {
          setComission(value)
        }
      }
      else {
        setComission('')
      }
    }
    catch(err)
    {
      toast.error(err, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }

  

  
  return (
    <Transition.Root show={props.states.modalIsOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={()=>props.setters.setModalIsOpen(false)} >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              
              <form className="space-y-8 divide-y divide-gray-200" onSubmit={e => enviarFormularioOnClick(e)} >
              {/* onSubmit={()=> cadastrarAssociado()} */}
                      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                        <div className="space-y-6 sm:space-y-5">
                            <div className='flex-col'>
                              <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Usuário</h3>
                              <p className="flex mt-1 text-sm text-gray-500 justify-center">
                                Informações do usuário
                              </p>
                            </div>

                            <div className="space-y-6 sm:space-y-5">
                              {/* 1 LINHA */}
                              <div className="sm:grid sm:grid-cols-2 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                
                                {!disabled && 
                                <>
                                  <div className="mt-2 sm:col-span-1 sm:mt-0">
                                  <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                    Email
                                  </label>
                                    <div className="flex max-w-lg rounded-md shadow-sm">
                                      <input
                                        type="text"
                                        name="email"
                                        id="email"
                                        autoComplete="email"
                                        placeholder='Digite o email'
                                      //   disabled={disabled}
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                    {
                                    emailError === true && 
                                    <p class="mt-2 ml-2 text-pink-600 text-sm">
                                        É obrigatório o preenchimento do email.
                                    </p>
                                  }
                                  </div>
                                
                                  <div className="mt-2 sm:col-span-1 sm:mt-0">
                                  <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                    Permissão
                                  </label>
                                    <div className="flex max-w-lg rounded-md shadow-sm">
                                    <select
                                        id="location"
                                        name="location"
                                        className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        // disabled={disabled}
                                        value={
                                            permission
                                          }
                                          onChange={e => setPermission(e.target.value)
                                        }
                                      >
                                        <option>Vendedor</option>
                                        <option>Admin</option>
                                      </select>
                                    </div>
                                    {
                                    permissionError === true && 
                                    <p class="mt-2 ml-2 text-pink-600 text-sm">
                                        É obrigatório o preenchimento da permissão.
                                    </p>
                                  }
                                  </div>
                                </>
                                }

                              {permission == 'Vendedor' &&
                                <>
                                <div className= {!disabled? "mt-2 sm:col-span-1 sm:mt-0":"mt-2 sm:col-span-full sm:mt-0"}>
                                  <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                      Comissão
                                    </label>
                                    <div className="relative rounded-md shadow-sm">
                                      <input
                                      disabled={permission != 'Vendedor'}
                                        type="text"
                                        name="price"
                                        id="price"
                                        className="h-9 block w-full rounded-md border-0 py-2 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        placeholder="0,00"
                                        value={comission}
                                        onChange={e => handleChange(e.target.value)}
                                        aria-describedby="price-currency"
                                      />
                                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                                          <p className="w-7 text-gray-500" aria-hidden="true">%</p>
                                      </div>
                                    </div>
                                    {
                                      comissionError === true && 
                                      <p class="mt-2 ml-2 text-pink-600 text-sm">
                                          É obrigatório o preenchimento da comissão.
                                      </p>
                                    }
                                </div>

                                {!disabled && 
                                  <div className="mt-2 sm:col-span-1 sm:mt-0">
                                    <Listbox value={companySelected} onChange={setCompanySelected} disabled={permission != 'Vendedor'}>
                                      {({ open }) => (
                                        <>
                                          {/* <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">Inserir produtos</Listbox.Label> */}
                                          <div className="flex items-center justify-between">
                                            <h2 className="block text-sm font-medium leading-6 text-gray-900">Selecionar empresa</h2>
                                            {/* {companyList.lenght != 1 &&
                                              <button disabled={disabled} type="button" className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500" onClick={e => addCompanyOnClick(e,companySelected)}>
                                                  Adicionar empresa
                                              </button>
                                            } */}
                                          </div>
                                          <div className="relative mt-2">
                                            <Listbox.Button className="flex flex-1 relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                                            {!(companySelected == '' || companySelected == undefined || companySelected == null)?
                                              <span className="inline-flex w-full truncate">
                                                <span className="truncate"> {companySelected.Nome} </span>
                                                <span className="ml-2 truncate text-gray-500"> 
                                                {companySelected.CNPJ.slice(0, 2) + '.' + companySelected.CNPJ.slice(2, 5) + '.' + companySelected.CNPJ.slice(5, 8) + '/' + 
                                                companySelected.CNPJ.slice(8, 12)+ '-' + companySelected.CNPJ.slice(12, 14)}
                                                </span>
                                              </span>
                                              :
                                              <span className="inline-flex w-full truncate">
                                                <span className="truncate">Selecione ...</span>
                                              </span>
                                              }
                                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                              </span>
                                            </Listbox.Button>
                                            

                                            <Transition
                                              show={open}
                                              as={Fragment}
                                              leave="transition ease-in duration-100"
                                              leaveFrom="opacity-100"
                                              leaveTo="opacity-0"
                                            >
                                              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                {props.states.companies.length>0 && props.states.companies.map((company) => (
                                                  (company.IsDeleted == 0) &&
                                                  <Listbox.Option
                                                    key={company.ID}
                                                    className={({ active }) =>
                                                      classNames(
                                                        active ? 'bg-blue-500 text-white' : 'text-gray-900',
                                                        'relative cursor-default select-none py-2 pl-3 pr-9'
                                                      )
                                                    }
                                                    value={company}>
                                                      {({ selected, active }) => (
                                                        <>
                                                          <div className="flex">
                                                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'truncate')}>
                                                              {company.Nome}
                                                            </span>
                                                            <span className={classNames(active ? 'text-indigo-200' : 'text-gray-500', 'ml-2 truncate')}>
                                                              {company.CNPJ.slice(0, 2) + '.' + company.CNPJ.slice(2, 5) + '.' + company.CNPJ.slice(5, 8) + '/' + 
                                                              company.CNPJ.slice(8, 12)+ '-' + company.CNPJ.slice(12, 14)}
                                                            </span>
                                                          </div>

                                                          {selected ? (
                                                            <span
                                                              className={classNames(
                                                                active ? 'text-white' : 'text-indigo-600',
                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                              )}
                                                            >
                                                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                            </span>
                                                          ) : null}
                                                        </>
                                                      )}
                                                  </Listbox.Option>
                                                )
                                                
                                                )}
                                              </Listbox.Options>
                                            </Transition>

                                          </div>
                                          
                                        </>
                                      )}
                                    </Listbox>
                                    {
                                        idsCompanyError === true && 
                                        <p class="mt-2 ml-1 text-pink-600 text-sm">
                                            É obrigatório o preenchimento de pelo menos uma empresa.
                                        </p>
                                      }
                                  </div>
                                }
                                </>
                              }


                              </div>
                            </div>
                        </div>
                      </div>

                      <div className="pt-5" >
                        <div className="flex justify-end gap-x-3">
                          <button
                            type="button"
                            className="rounded-md bg-white py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            onClick={()=>props.setters.setModalIsOpen(false)}
                          >
                            Cancelar
                          </button>
                          <button
                            type="submit"
                            className="inline-flex justify-center rounded-md bg-blue-500 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            // onClick={()=>props.setters.setModalIsOpen(true)}
                          >
                            {!disabled ?'Adicionar':'Editar'}
                          </button>
                        </div>
                      </div>
              </form>
                
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
