import { Navigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { AuthContext } from "../Context";

const RouterWrapper = ({ component: Component, isPrivate }) => {
    const { states, setters, checkLogin } = useContext(AuthContext)

    async function isSigned() {
        const token = localStorage.getItem('token')
        if (token === null) {
            setters.setSigned(false)
        } else {
            const response = await checkLogin()
            if (response === true) {
                setters.setSigned(true)
            } else {
                localStorage.clear()
                setters.setSigned(false)
            }
        }
    }

    useEffect(() => {
        isSigned()
    }, [localStorage.getItem('token')])

    if (isPrivate) {
        if (states.signed) {
            return (
                <>
                    <Component />
                </>
            )
        } else {
            if (localStorage.getItem('token') === null) {
                return (
                    <Navigate to='/' />
                )
            } else {
                localStorage.setItem('token', localStorage.getItem('token'))
                return (
                    <>
                        <Component />
                    </>
                )
            }
        }
    }

    if (isPrivate && !states.signed) {
        return (
            <Navigate to='/' />
        )
    }

    if (!isPrivate) {
        return (
            <Component />
        )
    }
    return (
        <Component />
    )



}

export default RouterWrapper;



// import { Navigate } from "react-router-dom";
// import { useContext, useEffect } from "react";
// import { AuthContext } from "../Context";

// const RouterWrapper = ({component: Component, isPrivate}) => {
//     const {states, setters} = useContext(AuthContext)

//     const compFunction = () => {
//         if(localStorage.getItem('token') === null || localStorage.getItem('token') === undefined || localStorage.getItem('token') ==='')
//         {
//             return (
//                 <Component/>
//             )
//         }
//     }

//     useEffect(() => {
//         // Update the document title using the browser API
//         compFunction()
//       },[localStorage.getItem('token')]);

//     if(isPrivate && states.signed)
//     {
//         return (
//             <Component/>
//         )
//     }
    
//     if(isPrivate && !states.signed)
//     {
//         return (
//             <Navigate to='/'/>
//         )
//     }

//     if(!isPrivate)
//     {
//         return (
//             <Component/>
//         )
//     }

    
    
// }

// export default RouterWrapper;

