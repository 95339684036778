import {useState, useContext, useEffect} from 'react'
import InputMask from "react-input-mask";
import {AuthContext} from "../../../Context"
import {toast} from 'react-toastify';
import Navbar from '../../../Components/Navbar/navbar'
import {useNavigate, Link} from 'react-router-dom'

import Footer from "../../../Components/Footer/footer"

import SpinnerOverlay from '../../../Components/SpinnerOverlay';


export default function CadastroCliente() {
    const {states, setters, cadastrarCliente, buscarCep, validarCpf, verificaPermissao, login} = useContext(AuthContext)

    const navigate = useNavigate()

    const [hidden, setHidden] = useState(false)
    const [erroSenha, setErroSenha]= useState(false)
    const [erroTamanhoSenha, setErroTamanhoSenha]= useState(false)

    const [email,setEmail] = useState('')
    const [senha,setSenha] = useState('')
    const [confirmSenha,setConfirmSenha] = useState('')
    const [nome,setNome] = useState('')
    const [cpf,setCpf] = useState('')
    const [telefone,setTelefone] = useState('')
    const [rg,setRg] = useState('')
    const [cep,setCep] = useState('')
    const [rua,setRua] = useState('')
    const [numero,setNumero] = useState('')
    const [complemento,setComplemento] = useState('')
    const [bairro,setBairro] = useState('')
    const [cidade,setCidade] = useState('')
    const [estado,setEstado] = useState('')

    const [cpfError,setCpfError] = useState('')
    const [telefoneError,setTelefoneError] = useState('')
    const [cepError,setCepError] = useState('')

     useEffect(()=>{
        listarPermissoesAsync()
     },[])

    const listarPermissoesAsync = async() => {
        try
        {
            await verificaPermissao()
            setHidden(states.isVendedor)
            if(states.isVendedor){
                setErroSenha(false)
                setErroTamanhoSenha(false)
                setSenha(`0`)
                setConfirmSenha(`0`)
            }
        }
        catch(err)
        {
            toast.error(err.response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
        }
    }

    const handleCep = async(cep) => {
        try{
            if (cep != '') {
                const cepCorrigido = cep
                    .replace('-', '')
                    .replace('_', '')
                if (!(cepCorrigido == '' || cepCorrigido.length != 8)) {
                    setCepError(false)
                    const result = await buscarCep(cep)
                    if (result.status == true) {
                        setRua(result.content.logradouro.replace('Rua ', ''))
                        setBairro(result.content.bairro)
                        setCidade(result.content.localidade)
                        setEstado(buscaAbreviacao(result.content.uf))
                        toast.success('CEP encontrado!', {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light"
                        });
                    } else {
                        setCepError(true)
                        toast.error(result.msg, {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light"
                        });
                    }
                } else {
                    setCepError(true)
                    toast.error('CEP inválido!', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"
                    });
                }
            }
        }
        catch (err) 
        {
            toast.error(err, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
        }
    }

    const buscaAbreviacao = (sigla) => {
        if (sigla == 'AC') {
            return 'Acre'
        } else if (sigla == 'AL') {
            return 'Alagoas'
        } else if (sigla == 'AP') {
            return 'Amapá'
        } else if (sigla == 'AM') {
            return 'Amazonas'
        } else if (sigla == 'BA') {
            return 'Bahia'
        } else if (sigla == 'CE') {
            return 'Ceará'
        } else if (sigla == 'DF') {
            return 'Distrito Federal'
        } else if (sigla == 'ES') {
            return 'Espírito Santo'
        } else if (sigla == 'GO') {
            return 'Goiás'
        } else if (sigla == 'MA') {
            return 'Maranhão'
        } else if (sigla == 'MT') {
            return 'Mato Grosso'
        } else if (sigla == 'MS') {
            return 'Mato Grosso do Sul'
        } else if (sigla == 'MG') {
            return 'Minas Gerais'
        } else if (sigla == 'PA') {
            return 'Pará'
        } else if (sigla == 'PB') {
            return 'Paraíba'
        } else if (sigla == 'PR') {
            return 'Paraná'
        } else if (sigla == 'PE') {
            return 'Pernambuco'
        } else if (sigla == 'PI') {
            return 'Piauí'
        } else if (sigla == 'RJ') {
            return 'Rio de Janeiro'
        } else if (sigla == 'RN') {
            return 'Rio Grande do Norte'
        } else if (sigla == 'RS') {
            return 'Rio Grande do Sul'
        } else if (sigla == 'RO') {
            return 'Rondônia'
        } else if (sigla == 'RR') {
            return 'Roraima'
        } else if (sigla == 'SC') {
            return 'Santa Catarina'
        } else if (sigla == 'SP') {
            return 'São Paulo'
        } else if (sigla == 'SE') {
            return 'Sergipe'
        } else if (sigla == 'TO') {
            return 'Tocantins'
        } else {
            return sigla
        }
    }

    const onSubmitForm = async (e) => {
        e.preventDefault()
        try 
        {
            const address = {street: rua, number: numero, complement: complemento, neighbourhood: bairro, cep:cep.replaceAll('-','').replaceAll('_',''), city:cidade, state:estado}
            const client ={email: email, password: senha, name:nome, cpf:cpf.replaceAll('.','').replaceAll('-','').replaceAll('_',''), rg:rg, phone: telefone.replaceAll('(','').replaceAll(')','').replaceAll('-','').replaceAll(' ','').replaceAll('_',''), 
            address: address}
            
            if(!(erroSenha || cpfError || telefoneError || cepError || erroTamanhoSenha))
            {
              const result = await cadastrarCliente(client)
              if(result.status == true)
              {
                toast.success(result.content, {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                });

                if(states.compraDeslogado)
                {
                    setters.setEmail(email)
                    setters.setPassword(senha)
                    await actionCompraDeslogado(email, senha)
                }
                else
                {
                    navigate(-1)
                }
              }
              else
              {
                toast.error(result.msg, {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                });
              }
            }
            else
            {
                toast.error('Favor revisar o formulário', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
              });
            }
        } 
        catch (err) 
        {
            toast.error(err.response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
              });
        }
    }

    const actionCompraDeslogado = async (email, senha) => {
        try{
            const resLogin = await login(true, email, senha)
            if (resLogin.status == true) {
                navigate('/vendas/cadastro')
                setters.setCompraDeslogado(false)
            }
            else
            {
                toast.error(resLogin.msg, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        }
        catch(err)
        {
            toast.error(err.response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
			}
    }

    const verificarCpf = () => {
        const resultado = validarCpf(cpf)
        setCpfError(!resultado.status)
    }

    const verificarTelefone = () => {
        const resultado = (telefone.replaceAll(`_`,``).replaceAll(`-`,``).replaceAll(`(`,``).replaceAll(`)`,``).replaceAll(` `,``).length == 11) ? true : false
        setTelefoneError(!resultado)
    }

    const verificarSenha = () => 
    {
      if(senha === confirmSenha)
      {
        setErroSenha(false)
      }
      else
      {
        setErroSenha(true)
      }
    }

    const verificarTamanhoSenha = () => 
    {
      if(senha.length < 8)
      {
        setErroTamanhoSenha(true)
      }
      else
      {
        setErroTamanhoSenha(false)
      }
    }

    useEffect(()=>{
        setHidden(states.isVendedor)
        if(states.isVendedor){
            setErroSenha(false)
            setErroTamanhoSenha(false)
            setSenha(`0`)
            setConfirmSenha(`0`)
        }
    },[states.isAdmin, states.isVendedor, states.isComum])

    return ( 
    <> 
    <Navbar/>

    < main > 
      <div className="space-y-16 py-16 xl:space-y-20 pt-6">
          {/* Recent client list*/}
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                  <form onSubmit={e => onSubmitForm(e)}>
                      <div className="space-y-10">
                          <div className="">
                                <div className="flex items-center justify-between">
                                    <h2 className="text-base font-semibold leading-7 text-gray-900">Cadastro de Usuário</h2>
                                    <p className="text-center text-sm text-gray-500" hidden={hidden}>
                                        Já possui cadastro?{' '}
                                        <Link to="/" className="font-semibold leading-6 text-blue-500 hover:text-blue-600">
                                            Entre aqui
                                        </Link>
                                    </p>
                                </div>
                              <p className=" text-sm leading-6 text-gray-600">Informe seus dados para efetuar o cadastro.</p>

                              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                  <div className="col-span-full">
                                      <label
                                          htmlFor="street-address"
                                          className="block text-sm font-medium leading-6 text-gray-900">
                                          Email
                                      </label>
                                      <div className="mt-2">
                                          <input
                                              required
                                              type="email"
                                              name="email"
                                              id="email"
                                              autoComplete="email"
                                              placeholder='Digite o email'
                                              value={email}
                                              onChange={e => setEmail(e.target.value)}
                                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                      </div>
                                  </div>

                                  <div className="sm:col-span-3" hidden={hidden}>
                                      <label
                                          htmlFor="first-name"
                                          className="block text-sm font-medium leading-6 text-gray-900">
                                          Senha
                                      </label>
                                      <div className="mt-2">
                                          <input
                                              required
                                              type="password"
                                              name="password"
                                              id="password"
                                              placeholder='Digite a senha'
                                              value={senha}
                                              onChange={e => setSenha(e.target.value)}
                                              autoComplete='password'
                                              onBlur={()=> verificarTamanhoSenha()}
                                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                              />
                                              {
                                                erroTamanhoSenha === true && 
                                                <p class="mt-2 ml-1 text-pink-600 text-sm">
                                                    A senha deve ter ao menos 8 caracteres.
                                                </p>
                                            }
                                      </div>
                                  </div>

                                  <div className="sm:col-span-3" hidden={hidden}>
                                      <label
                                          htmlFor="last-name"
                                          className="block text-sm font-medium leading-6 text-gray-900">
                                          Confirme a senha
                                      </label>
                                      <div className="mt-2">
                                          <input
                                              required
                                              type="password"
                                              name="confirmPassword"
                                              id="confirmPassword"
                                              placeholder='Confirme a senha'
                                              value={confirmSenha}
                                              onChange={e => setConfirmSenha(e.target.value)}
                                              autoComplete='password'
                                              onBlur={()=> verificarSenha()}
                                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                            {
                                              erroSenha === true && 
                                              <p class="mt-2 ml-1 text-pink-600 text-sm">
                                                  As senhas não conferem.
                                              </p>
                                            }
                                      </div>
                                  </div>

                                  <p class="sm:col-span-full text-gray-600 text-sm" hidden={!hidden}>
                                     A senha cadastrada para esse usuário será: "0". No próximo login, basta entrar com essa senha, que será solicitado a troca da mesma.
                                  </p>

                                  <div className="col-span-full border-b border-gray-900/10"/>

                                  <div className="sm:col-span-3">
                                      <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                                        Nome
                                      </label>
                                      <div className="mt-2">
                                          <input
                                              required
                                              type="text"
                                              name="nome"
                                              id="nome"
                                              autoComplete="nome"
                                              placeholder='Digite o nome'
                                              value={nome}
                                              onChange={e => setNome(e.target.value)}
                                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                      </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                      <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                          CPF
                                      </label>
                                      <div className="mt-2">
                                          <InputMask
                                             required
                                              type="text"
                                              name="cpf"
                                              id="cpf"
                                              autoComplete="cpf"
                                              placeholder='Digite o CPF'
                                              mask="999.999.999-99"
                                              value={cpf}
                                              onChange={e => setCpf(e.target.value)}
                                              onBlur={()=> verificarCpf()}
                                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                          {
                                              cpfError === true && 
                                              <p class="mt-2 ml-1 text-pink-600 text-sm">
                                                  CPF inválido.
                                              </p>
                                            }
                                      </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                      <label
                                          htmlFor="first-name"
                                          className="block text-sm font-medium leading-6 text-gray-900">
                                          Telefone
                                      </label>
                                      <div className="mt-2">
                                          <InputMask
                                              required
                                              type="text"
                                              name="telefone"
                                              id="telefone"
                                              autoComplete="telefone"
                                              placeholder='Digite o telefone'
                                              mask="(99) 99999-9999"
                                              value={telefone}
                                              onChange={e => setTelefone(e.target.value)}
                                              onBlur={() => verificarTelefone()}
                                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                           {
                                              telefoneError === true && 
                                              <p class="mt-2 ml-1 text-pink-600 text-sm">
                                                  Telefone inválido.
                                              </p>
                                            }
                                      </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                      <label
                                          htmlFor="last-name"
                                          className="block text-sm font-medium leading-6 text-gray-900">
                                          RG
                                      </label>
                                      <div className="mt-2">
                                          <input
                                              required
                                              type="text"
                                              name="rg"
                                              id="rg"
                                              autoComplete="rg"
                                              placeholder='Digite o RG'
                                              value={rg}
                                              onChange={e => setRg(e.target.value)}
                                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                      </div>
                                  </div>

                                  <div className="col-span-full border-b border-gray-900/10"/>

                                  <div className="sm:col-span-2">
                                      <label
                                          htmlFor="postal-code"
                                          className="block text-sm font-medium leading-6 text-gray-900">
                                          CEP
                                      </label>
                                      <div className="mt-2">
                                          <InputMask
                                              required
                                              type="text"
                                              name="cep"
                                              id="cep"
                                              autoComplete="cep"
                                              placeholder='Digite o CEP'
                                              mask="99999-999"
                                              value={cep}
                                              onChange={e => setCep(e.target.value)}
                                              onBlur={() => handleCep(cep)}
                                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                             {
                                              cepError === true && 
                                              <p class="mt-2 ml-1 text-pink-600 text-sm">
                                                  CEP inválido.
                                              </p>
                                            }
                                      </div>
                                  </div>

                                  <div className="sm:col-span-2">
                                      <label
                                          htmlFor="city"
                                          className="block text-sm font-medium leading-6 text-gray-900">
                                          Rua
                                      </label>
                                      <div className="mt-2">
                                          <input
                                              required
                                              type="text"
                                              name="rua"
                                              id="rua"
                                              autoComplete="rua"
                                              placeholder='Digite a rua'
                                              value={rua}
                                              onChange={e => setRua(e.target.value)}
                                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                      </div>
                                  </div>

                                  <div className="sm:col-span-1.5">
                                      <label
                                          htmlFor="region"
                                          className="block text-sm font-medium leading-6 text-gray-900">
                                          Nº
                                      </label>
                                      <div className="mt-2">
                                          <input
                                              required
                                              type="text"
                                              name="numero"
                                              id="numero"
                                              autoComplete="numero"
                                              placeholder='Digite o Nº'
                                              value={numero}
                                              onChange={e => setNumero(e.target.value)}
                                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                      </div>
                                  </div>

                                  <div className="sm:col-span-1.5">
                                      <label
                                          htmlFor="postal-code"
                                          className="block text-sm font-medium leading-6 text-gray-900">
                                          Complemento
                                      </label>
                                      <div className="mt-2">
                                          <input
                                              type="text"
                                              name="complemento"
                                              id="complemento"
                                              autoComplete="complemento"
                                              placeholder='Digite o complemento'
                                              value={complemento}
                                              onChange={e => setComplemento(e.target.value)}
                                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                      </div>
                                  </div>

                                  <div className="sm:col-span-2 sm:col-start-1">
                                      <label
                                          htmlFor="city"
                                          className="block text-sm font-medium leading-6 text-gray-900">
                                          Bairro
                                      </label>
                                      <div className="mt-2">
                                          <input
                                              required
                                              type="text"
                                              name="bairro"
                                              id="bairro"
                                              autoComplete="bairro"
                                              placeholder='Digite o bairro'
                                              value={bairro}
                                              onChange={e => setBairro(e.target.value)}
                                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                      </div>
                                  </div>

                                  <div className="sm:col-span-2">
                                      <label
                                          htmlFor="region"
                                          className="block text-sm font-medium leading-6 text-gray-900">
                                          Cidade
                                      </label>
                                      <div className="mt-2">
                                          <input
                                              required
                                              type="text"
                                              name="cidade"
                                              id="cidade"
                                              autoComplete="cidade"
                                              placeholder='Digite a cidade'
                                              value={cidade}
                                              onChange={e => setCidade(e.target.value)}
                                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                      </div>
                                  </div>

                                  <div className="sm:col-span-2">
                                      <label
                                          htmlFor="postal-code"
                                          className="block text-sm font-medium leading-6 text-gray-900">
                                          Estado
                                      </label>
                                      <div className="mt-2">
                                          <select
                                              required
                                              type="text"
                                              name="postal-code"
                                              id="postal-code"
                                              autoComplete="postal-code"
                                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                              value={estado}
                                              onChange={e => setEstado(e.target.value)}>
                                              <option>Acre</option>
                                              <option>Alagoas</option>
                                              <option>Amapá</option>
                                              <option>Amazonas</option>
                                              <option>Bahia</option>
                                              <option>Ceará</option>
                                              <option>Distrito Federal</option>
                                              <option>Espírito Santo</option>
                                              <option>Goiás</option>
                                              <option>Maranhão</option>
                                              <option>Mato Grosso</option>
                                              <option>Mato Grosso do Sul</option>
                                              <option>Minas Gerais</option>
                                              <option>Pará</option>
                                              <option>Paraíba</option>
                                              <option>Paraná</option>
                                              <option>Pernambuco</option>
                                              <option>Piauí</option>
                                              <option>Rio de Janeiro</option>
                                              <option>Rio Grande do Norte</option>
                                              <option>Rio Grande do Sul</option>
                                              <option>Rondônia</option>
                                              <option>Roraima</option>
                                              <option>Santa Catarina</option>
                                              <option>São Paulo</option>
                                              <option>Sergipe</option>
                                              <option>Tocantins</option>
                                          </select>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <div >
                              <div className="flex justify-end gap-x-3">
                                  <button
                                      type="submit"
                                      className="inline-flex justify-center rounded-md bg-blue-500 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                      Enviar
                                  </button>
                              </div>
                          </div>
                      </div>
                  </form>
              </div>
          </div>
      </div> 
    </main>

    <Footer/>
    <SpinnerOverlay/>
    </>
    )
}
