import React from 'react';
import { useRef } from 'react';


export default function FileUpload(props) {

  const inputRef = useRef(null);

  const onFileChange = async (e) => {
    if (e.target.files[0]) {
            var file = e.target.files[0]

            if(props.type === `image`)
            {
                if (file.type === 'image/jpeg' || file.type === 'image/png') 
                {
                  props.setSelectedFile(file)
                } 
                else {
                  alert('Envie uma imagem do tipo PNG ou JPEG')
                  return null
                }
            }
            else if(props.type === `file`)
            {
                if(file.type === 'application/pdf')
                {
                  props.setSelectedFile(file)
                }
                else
                {
                  alert('Envie uma imagem do tipo PDF')
                  return null
                }
            }
            else
            {
              alert('Arquivo Inválido')
              return null
            }
        }
  };

  const limparArquivo = async (e) => {
    props.setSelectedFile(null)
    inputRef.current.value = '';
  };

  const fileData = () => {
    if (props.selectedFile) {
      return (
        <div className='mt-4'>
          <h2>Informações do arquivo:</h2>
          <p className='mt-2 mb-1'>Nome do arquivo: {props.selectedFile.name}</p>
          <p className='mt-1 mb-1'>Tipo do arquivo: {props.selectedFile.type}</p>
          <p className='mt-1 mb-2'>
            Última modificação:{" "}
            {props.selectedFile.lastModifiedDate.toDateString()}
            {/* {props.selectedFile.lastModifiedDate} */}
          </p>
          <div className="pt-4" >
              <div className="flex justify-end gap-x-3">
                <button
                  type="button"
                  onClick={() => limparArquivo()}
                  className="rounded-md bg-white py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  
                >
                  Limpar
                </button>
              </div>
            </div>
        </div>
      );
    } else {
      return (
        <div>
          <br />
          <h4>Nenhum arquivo selecionado</h4>
        </div>
      );
    }
  };

  return (
    <div className='flex flex-col'>
      <input className='input-file-upload' type="file" multiple={false} ref={inputRef} onChange={e=> onFileChange(e)} />
        {fileData()}
    </div>
  );
}
