import { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { AuthContext } from "../../../Context"
import { toast } from 'react-toastify';

import Footer from '../../../Components/Footer/footer'
import Spinner from '../../../Components/Spinner'
import Error from '../../../Components/EmptyGrid'
import tinycolor from 'tinycolor2'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'
import Navbar from '../../../Components/Navbar/navbar';
import { useNavigate } from 'react-router-dom'


const statuses = {
    0: 'text-green-700 bg-green-50 ring-green-600/20',
    1: 'text-red-700 bg-red-50 ring-red-600/10',
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Externo() {
    const { states, setters, listarProdutos, listarProdutosPorNome, getPath } = useContext(AuthContext)
    const [offset, setOffset] = useState(0)
    const [verMais, setVerMais] = useState(false)
    const [urlRedirect, setUrlRedirect] = useState('')

    const [isEmpresa, setIsEmpresa] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        setValues()
        listarProdutosAsync(false)
    }, [])

    const setValues = async () => {
        await setters.setProducts('')
    }

    // useEffect(()=>{
    //     if(states.products == '') listarProdutosAsync()
    // },[states.products])

    useEffect(() => {
        if (offset != 0) listarProdutosAsync(verMais)
    }, [offset])

    const listarProdutosAsync = async () => {
        try {
            const path = window.location.pathname
            const url = path.replaceAll('produtos', '').replaceAll('/', '')

            if (url != 'externo') {
                setUrlRedirect('/produto')
                const result = await listarProdutosPorNome(6, offset, url, verMais)
                if (result.isDeleted) {
                    toast.error('Empresa inativa', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });

                    setIsEmpresa(false)
                    await listarProdutos(states.isVendedor, states.isAdmin, 6, offset, verMais)
                    setVerMais(false)
                }
                else {
                    setIsEmpresa(true)
                    setVerMais(false)
                }
            }
            else {
                localStorage.setItem('companyName', '')
                setUrlRedirect('/externo/produto')
                setIsEmpresa(false)
                await listarProdutos(states.isVendedor, states.isAdmin, 6, offset, verMais)
                setVerMais(false)
            }
        }
        catch (err) {
            if (err.response.data.isDeleted) {
                navigate('/')
            }
            toast.error(err.response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const comprarProduto = (deslogado, produto) => {
        if (deslogado) {
            setters.setCompraDeslogado(true)
        }

        sessionStorage.setItem("product", JSON.stringify(produto));
    }

    const handleVerMais = (e) => {
        e.preventDefault()
        setOffset(offset + 1)
        setVerMais(true)
    }

    // const color = '#E91A1A'
    const color = states.companyColor
    const lighterColor = color

    const corClara = (lighterColor) => {
        return tinycolor(lighterColor).lighten().lighten().toHexString()
    }

    const headerStyle = {
        backgroundImage: color !== ''
            ? `linear-gradient(to right, ${color}, ${corClara(lighterColor)}, ${color})`
            : 'linear-gradient(to right, gray-400, gray-300, gray-400)',
        height: '70px', // Defina a altura desejada
        borderRadius: '0.375rem',
    };

    const headerStyleEmpresa = {
        backgroundImage: color !== ''
            ? `linear-gradient(to right, ${color}, ${corClara(lighterColor)}, ${color})`
            : 'linear-gradient(to right, gray-400, gray-300, gray-400)',
        // height: '70px', // Defina a altura desejada
        borderRadius: '0.375rem',
    };

    const headerStyleProprio = {
        backgroundImage: 'linear-gradient(to right, #a0aec0,  #cbd5e0, #e2e8f0)',
        borderRadius: '0.375rem',
    };



    const imageContainerStyle = {
        flex: 1,
        maxWidth: '100%',
        height: '100%',
    };

    const textContainerStyle = {
        flex: 1,
        padding: '20px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        fontSize: '1.25rem', // Defina o tamanho desejado (por exemplo, '2rem' para tamanho XL)
        color: '#2d3748',
    };

    const [imagem, setImagem] = useState('')

    useEffect(() => {
        constroiLogo()
    }, [])

    const constroiLogo = async () => {
        const origin = await getPath(window)
        setImagem(origin + '/logo.png')
    }

    return (
        <>
            {isEmpresa ?
                <header style={headerStyle}>
                    <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:divide-y lg:divide-gray-700 lg:px-8">
                        <div className="relative flex h-16 justify-between">
                            <div className="relative z-10 flex px-2 lg:px-0">
                                <div className="flex flex-shrink-0 items-center">
                                    <img
                                        className="h-16 w-auto"
                                        src={states.companyUrl == '' ? imagem : states.companyUrl}
                                        crossOrigin="anonymous"
                                        alt="Sua empresa"
                                    />
                                </div>
                            </div>
                            <div className="relative z-0 flex flex-1 items-center justify-center px-2 sm:absolute sm:inset-0">
                                <div className="w-full sm:max-w-xs">
                                    <div className="relative">
                                        {/* md:text-sm md:font-semibold md:leading-6 md:text-gray-700 */}
                                        <div className="flex flex-1 align-center justify-center md:text-sm md:font-semibold md:leading-6 md:text-gray-700"> {states.companyName}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                :
                <Navbar />
            }

            <main>
                <div className="space-y-16 py-16 xl:space-y-20 pt-6">
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                            {states.loading == true ?
                                <Spinner />
                                :
                                states.products.length != 0 ?
                                    <div>
                                        <ul role="list" className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-1 xl:gap-x-8">
                                            {/* <div style={isEmpresa ? headerStyleProprio : headerStyleProprio}>
                                                <div className='flex flex-col sm:flex-row items-center'>
                                                    <div style={imageContainerStyle}>
                                                        <img src={imagem} alt="Imagem" crossOrigin="anonymous" className="object-cover object-center h-50 w-auto" />
                                                    </div>
                                                    <div style={textContainerStyle}>
                                                        {isEmpresa ?
                                                            <p>
                                                                {states.companyDescription}
                                                            </p>
                                                            :
                                                            <p>
                                                                Na SOMA, nossa missão é atender às necessidades e cuidar dos nossos clientes. Para isso, buscando compreender profundamente as demandas e desafios da sociedade para adaptar soluções de forma eficaz e personalizada.
                                                            </p>
                                                        }
                                                    </div>
                                                </div>
                                            </div> */}
                                            {states.products.map((product) => {
                                                return (
                                                    (product.IsDeleted == '0') &&

                                                    <div key={product.id} className="grid w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-12 lg:gap-x-8 border border-gray-200 p-8 rounded-md shadow-md">

                                                        <div className="sm:col-span-4 lg:col-span-5">

                                                            <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg flex flex-1 justify-center align-center">
                                                                {product.Url == '' ?
                                                                    <img
                                                                        src={states.companyUrl}
                                                                        crossOrigin="anonymous"
                                                                        className="object-cover object-center h-80 w-auto" />
                                                                    : <img
                                                                        src={product.Url}
                                                                        crossOrigin="anonymous"
                                                                        className="object-cover object-center h-80 w-auto" />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="sm:col-span-8 lg:col-span-7">
                                                            <div className="flex items-center justify-between">
                                                                <h2 className="text-2xl font-bold text-gray-900 sm:pr-12 capitalize">{product.Title.toLowerCase()}</h2>
                                                            </div>
                                                            {/* <h2 className="text-2xl font-bold text-gray-900 sm:pr-12">{product.name}</h2> */}

                                                            <section aria-labelledby="information-heading" className="mt-3">
                                                                <h3 id="information-heading" className="sr-only">
                                                                    Product information
                                                                </h3>

                                                                <p className="text-2xl text-gray-900">R$ {parseFloat(product.Price / 100).toFixed(2).toString().replace('.', ',')} </p>
                                                                {/* <p className="text-2xl text-gray-900">{product.price}</p> */}

                                                                {!states.isVendedor &&
                                                                    <div className="mt-3">
                                                                        <h4 className="sr-only">Empresa</h4>
                                                                        <p className="text-sm text-gray-700">{product.CompanyID}</p>
                                                                    </div>
                                                                }

                                                                <div className="mt-3">
                                                                    <h4 className="sr-only">Duração</h4>
                                                                    <p className="text-sm text-gray-700">{product.Duration} meses</p>
                                                                </div>

                                                                <div className="flex justify-between gap-x-4 py-3">
                                                                    <dd className="flex items-start gap-x-2">
                                                                        <div
                                                                            className={classNames(
                                                                                statuses[product.IsDeleted],
                                                                                'rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset'
                                                                            )}
                                                                        >
                                                                            {product.IsDeleted ? 'Inativo' : 'Ativo'}
                                                                        </div>
                                                                    </dd>
                                                                </div>

                                                                <div className="mt-12">
                                                                    <h4 className="sr-only">Description</h4>

                                                                    <p className="text-sm text-gray-700">{product.Description}</p>
                                                                    {/* <p className="text-sm text-gray-700">{product.description}</p> */}
                                                                </div>
                                                            </section>

                                                            <section aria-labelledby="options-heading" className="mt-6">
                                                                <h3 id="options-heading" className="sr-only">
                                                                    Product options
                                                                </h3>

                                                                <form>

                                                                    <div className="mt-11">
                                                                        <Link to={`${urlRedirect}/` + product.ID} className="relative ml-auto" onClick={() => comprarProduto(false, product)}>
                                                                            {/* to="/vendas/cadastro/" */}
                                                                            {/* <ShoppingCartIcon className="h-6 w-6 hover:text-gray-500" aria-hidden="true" /> */}
                                                                            <button
                                                                                type="button"
                                                                                className="flex w-full items-center justify-center rounded-md border border-transparent bg-blue-500 px-8 py-3 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-gray-50"
                                                                            >
                                                                                Saiba Mais
                                                                            </button>
                                                                        </Link>
                                                                    </div>

                                                                </form>
                                                            </section>
                                                        </div>
                                                    </div>

                                                )
                                            })
                                            }
                                        </ul>

                                        {states.loadMoreProducts &&
                                            <nav className="flex items-center justify-between px-4 sm:px-0 mt-4">
                                                <div className="-mt-px flex w-0 flex-1" />

                                                <div className="hidden md:-mt-px md:flex">
                                                    <button
                                                        className="inline-flex items-center px-4 pt-4 text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
                                                        onClick={e => handleVerMais(e)}>
                                                        Ver mais
                                                    </button>
                                                </div>

                                                <div className="-mt-px flex w-0 flex-1 justify-end" />
                                            </nav>
                                        }
                                    </div>

                                    :
                                    <Error origin={'produtos a serem exibidos'} />
                            }
                        </div>
                    </div>


                    {/* <Spinner show={loading}/> */}

                </div>
            </main>

            <Footer />

            <SpinnerOverlay />
        </>
    )
}

