import { Fragment, useState ,useContext,useEffect} from 'react'
import { AuthContext} from "../../Context"
import { Menu, Transition } from '@headlessui/react'
import { EllipsisHorizontalIcon} from '@heroicons/react/20/solid'
import ModalSale from '../../Components/Modal/Sale'
import ModalConfirmation from '../../Components/Modal/Util/Confirmation'
import Navbar from '../../Components/Navbar/navbar'
import Footer from '../../Components/Footer/footer'
import Error from '../../Components/EmptyGrid'
import Spinner from '../../Components/Spinner'
import { toast } from 'react-toastify';
import SpinnerOverlay from '../../Components/SpinnerOverlay'
   
  const statuses = {
    0: 'text-green-700 bg-green-50 ring-green-600/20',
    1: 'text-red-700 bg-red-50 ring-red-600/10',
    2: 'text-yellow-700 bg-yellow-50 ring-yellow-600/10',
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

export default function Vendas() {
      const {states, setters, listarVendas, listarClientes, listarProdutos, verificaPermissao, listarEstatisticasVendas, pagarComissao, excluirVenda, getPath} = useContext(AuthContext)
      const [idSaleSelected, setIdSaleSelected] = useState('')
      const [offset, setOffset] = useState(0)
      
      const [confirm, setConfirm] = useState(false)

      const [verMais, setVerMais] = useState(false)

      const listarVendasAsync = async(verMais) => {
        try{
          await listarVendas(6,offset, verMais)
          setVerMais(false)
        }
        catch(err)
        {
          toast.error(err.response.data.msg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        }
      }

      const listarPermissoesAsync = async() => {
        try{
          await verificaPermissao()
        }
        catch(err)
        {
          toast.error(err.response.data.msg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        }
      }

      const addVendaOnClick = () => {
        setters.setModalIsOpen(true)
        setIdSaleSelected('')
      }

      const viewSaleOnClick = (id) => {
        setters.setModalIsOpen(true)
        setIdSaleSelected(id)
      }

      const deleteSaleOnClick = async (id) => {
        try{
            const result = await excluirVenda(id)
            if(result.status)
            {
              await listarVendasAsync(false)
              toast.success('Venda cancelada com sucesso', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
            }
            else
            {
              toast.error(result.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
            }
        }
        catch(err)
        {
            toast.error(err.response.data.msg, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
          }
      }

      const comissionPaidOnClick = (id) => {
        setters.setModalIsOpenAux(true)
        setIdSaleSelected(id)
      }

      useEffect(()=>{
        setValues()
        listarPermissoesAsync()
      },[])

      const setValues = async() =>
        {
          await setters.setSales('')
      }

      useEffect(()=>{
        try{
          listarVendasAsync(false)

          if(states.isAdmin || states.isVendedor)
          {
            listarEstatisticasVendas()
          }
        }
        catch(err)
        {
          toast.error(err.response.data.msg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        }
      },[states.isAdmin, states.isVendedor, states.isComum])

      useEffect(()=>{
        try{
          listarVendasAsync(verMais)

          if(states.isAdmin || states.isVendedor)
          {
            listarEstatisticasVendas()
          }
        }
        catch(err)
        {
          toast.error(err.response.data.msg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        }
      },[offset])

      useEffect(()=>{
        if(states.modalIsOpen == false)
        {
          // setters.setSales('')
          // listarVendasAsync()
        }
      },[states.modalIsOpen])

      useEffect(()=>{
        if(confirm == true)
        {
          pagarComissaoAsync()
        }
      
      },[confirm])

      const pagarComissaoAsync = async () => {
        try{
          const result = await pagarComissao(idSaleSelected)
          if(result.status)
          {
            await listarVendasAsync(false)
            toast.success('Comissão paga com sucesso', {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
          }
          else
          {
            toast.error(result.msg, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
          }
        }
        catch(err)
        {
          toast.error(err.response.data.msg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        }
      }

      const handleVerMais = (e) =>
      {
        e.preventDefault()
        setOffset(offset+1)
        setVerMais(true)
      }

      const [imagem, setImagem] = useState('')

      useEffect(()=>{
          constroiLogo()
      },[])

      const constroiLogo = async () =>
      {
          const origin = await getPath(window)
          setImagem(origin + '/logo.png')
      }

      return (
        <>
          <Navbar/>

          <main>
            <div className="relative isolate overflow-hidden">

              <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5" hidden={(states.isComum)}>
                <dl className="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 lg:px-2 xl:px-0">
                  {states.estatisticasVendas.map((stat, statIdx) => (
                    <div
                      key={stat.name}
                      className={classNames(
                        statIdx % 2 === 1 ? 'sm:border-l' : statIdx === 2 ? 'lg:border-l' : '',
                        'flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8'
                      )}
                    >
                      <div className='flex flex-1'>
                          <dt className="flex flex-1 text-sm font-medium leading-6 text-gray-500 py-1">{stat.name}</dt>
                          <dd className="text-2xl font-medium  tracking-tight text-gray-900">
                              {stat.value}
                          </dd>
                      </div>
                    </div>
                  ))}
                </dl>
              </div>

              <div
                className="absolute left-0 top-full -z-10 mt-96 origin-top-left translate-y-40 -rotate-90 transform-gpu opacity-20 blur-3xl sm:left-1/2 sm:-ml-96 sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu sm:opacity-50"
                aria-hidden="true"
              >
                <div
                  className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-blue-200 to-blue-600"
                  style={{
                    clipPath:
                      'polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)',
                  }}
                />
              </div>
            </div>

            <div className="space-y-16 py-16 xl:space-y-20 pt-6">
                    {/* Recent client list*/}
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                        <div className="flex items-center justify-between">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">
                          {states.isComum? 'Compras':'Vendas'} efetuadas
                        </h2>
                        {/* <button href="#" className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500" onClick={()=> addVendaOnClick()} hidden={!states.isVendedor}>
                            Adicionar venda
                        </button> */}
                        <div className="mt-2 sm:col-span-1 sm:mt-0">
                              <div className="flex max-w rounded-md">
                                <div className="flex-1 flex bg-white pl-2 justify-end items-center">
                                    <button className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                                      onClick={()=> addVendaOnClick()} hidden={!states.isVendedor}  >
                                    Efetuar venda
                                    </button>
                                </div> 
                              </div>
                          </div>
                        </div>

                        {states.loading == true?
                            <Spinner/>
                        :
                        states.sales.length !=0  ?
                        <div>
                          <ul role="list" className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                            {states.sales.map((sale) => (
                                <li key={sale.ID} className="overflow-hidden rounded-xl border border-gray-200 hover:bg-gray-50 hover:shadow">
                                <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                                    <img
                                    src={imagem}
                                    crossOrigin="anonymous"
                                    className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
                                    />
                                    <div className="text-sm font-medium leading-6 text-gray-900">{sale.Origin}</div>
                                    
                                    <Menu as="div" className="relative ml-auto">
                                    <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                                        <span className="sr-only">Abrir opções</span>
                                        <EllipsisHorizontalIcon className="h-6 w-6" aria-hidden="true" />
                                    </Menu.Button>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        {sale.IsDeleted == 0 && 
                                        <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                            <Menu.Item>
                                                {({ active }) => (
                                                <a
                                                    href="#"
                                                    className={classNames(
                                                    active ? 'bg-gray-50' : '',
                                                    'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                    )}
                                                    onClick={()=>viewSaleOnClick(sale.ID)}
                                                >
                                                    Visualizar
                                                </a>
                                                )}
                                            </Menu.Item>
                                            
                                            {states.isAdmin &&
                                            <Menu.Item>
                                                {({ active }) => (
                                                <a
                                                    href="#"
                                                    className={classNames(
                                                    active ? 'bg-gray-50' : '',
                                                    'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                    )}
                                                    onClick={()=>deleteSaleOnClick(sale.ID)}
                                                >
                                                    Excluir
                                                </a>
                                                )}
                                            </Menu.Item>
                                            }

                                            {states.isAdmin && sale.IsPaid == 0 && sale.Origin == 'Vendedor' &&
                                              <Menu.Item>
                                                  {({ active }) => (
                                                  <a
                                                      href="#"
                                                      className={classNames(
                                                      active ? 'bg-gray-50' : '',
                                                      'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                      )}
                                                      onClick={()=>comissionPaidOnClick(sale.ID)}
                                                  >
                                                      Comissão paga
                                                  </a>
                                                  )}
                                              </Menu.Item>
                                            }

                                        </Menu.Items>
                                    }
                                        
                                    </Transition>
                                    </Menu>
                                </div>
                                <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                                    <div className="flex justify-between gap-x-4 py-3">
                                        <dt className="text-gray-500">Observação</dt>
                                        <dd className="text-gray-700">
                                            {sale.Observation}
                                            {/* <time dateTime={client.lastInvoice.dateTime}>{client.lastInvoice.date}</time> */}
                                        </dd>
                                    </div>

                                    <div className="flex justify-between gap-x-4 py-3">
                                        <dt className="text-gray-500">Valor</dt>
                                        <dd className="flex items-start gap-x-2">
                                            <div className="font-medium text-gray-900">
                                            R$ {(sale.Value/100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</div>
                                        </dd>
                                    </div>


                                    <div className="flex justify-between gap-x-4 py-3">
                                        <dt className="text-gray-500">Status</dt>
                                        <dd className="flex items-start gap-x-2">
                                            <div
                                                className={classNames(
                                                statuses[sale.IsDeleted],
                                                'rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset'
                                                )}
                                            >
                                                {sale.IsDeleted == 1 ? 'Inativo':'Ativo'}
                                            </div>
                                        </dd>
                                    </div>
    {/* // aqui nao mostrar o nao existe */}
                                    {(sale.Origin == 'Vendedor') ?
                                      <div className="flex justify-between gap-x-4 py-3">
                                          <dt className="text-gray-500">Comissão</dt>
                                          <dd className="flex items-start gap-x-2">
                                              <div
                                                  className={classNames(
                                                  statuses[sale.IsPaid == 1 ? '0':'1'],
                                                  'rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset'
                                                  )}
                                              >
                                                  {sale.IsPaid == 1 ? 'Pago':'Não pago'}
                                              </div>
                                          </dd>
                                      </div> 
                                      :
                                      <div className="flex justify-between gap-x-4 py-3">
                                      <dt className="text-gray-500">Comissão</dt>
                                      <dd className="flex items-start gap-x-2">
                                          <div
                                              className={classNames(
                                              statuses[2],
                                              'rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset'
                                              )}
                                          >
                                              Não existe
                                          </div>
                                      </dd>
                                  </div>
                                    }

                                </dl>
                                </li>
                            ))}
                          </ul>
                          {states.loadMoreSales && 
                            <nav className="flex items-center justify-between px-4 sm:px-0 mt-4">
                                <div className="-mt-px flex w-0 flex-1"/>

                                <div className="hidden md:-mt-px md:flex">
                                    <button
                                    className="inline-flex items-center px-4 pt-4 text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
                                    onClick={e=> handleVerMais(e)}>
                                      
                                    Ver mais
                                    </button>
                                </div>

                                <div className="-mt-px flex w-0 flex-1 justify-end"/>
                            </nav>
                          }
                        </div>
                        :
                          <Error origin={states.isComum?'compras a serem exibidas': 'vendas a serem exibidas'}/>
                        }
                    </div>
                    </div>
            </div>

          </main>

          <Footer/>

          <ModalConfirmation states={states} setters={setters} setConfirm={setConfirm}/>
          <SpinnerOverlay/>
          <ModalSale states={states} setters={setters} id={idSaleSelected} listarClientes={listarClientes} listarProdutos={listarProdutos}/>
        </>
      )
}




