import { Fragment, useState ,useContext,useEffect} from 'react'
import { AuthContext } from "../../Context"
import { Menu, Transition } from '@headlessui/react'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import ModalClient from '../../Components/Modal/Client'
import { toast } from 'react-toastify';
import { MagnifyingGlassIcon} from '@heroicons/react/24/outline'
import ModalEmail from '../../Components/Modal/Util/Email'

import Navbar from '../../Components/Navbar/navbar'
import Footer from '../../Components/Footer/footer'
import Error from '../../Components/EmptyGrid'
import Spinner from '../../Components/Spinner'

import SpinnerOverlay from '../../Components/SpinnerOverlay'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const statuses = {
  0: 'text-green-700 bg-green-50 ring-green-600/20',
  1: 'text-red-700 bg-red-50 ring-red-600/10',
}

export default function Clients() {
  const {states, setters, listarClientes, verificaPermissao} = useContext(AuthContext)
  const [idClientSelected, setIdClientSelected]= useState('')
  const [searchType, setSearchType] = useState('')
  const [offset, setOffset] = useState(0)

  const [verMais, setVerMais] = useState(false)

  useEffect(()=>{
    setValues()
    listarPermissoesAsync()
  },[])

  const setValues = async() =>
  {
      await setters.setClients('')
  }

  useEffect(()=>{
    if(states.clients == '') listarClientesAsync()
  },[states.clients])

  useEffect(()=>{
    listarClientesAsync(false)
  },[states.isAdmin, states.isVendedor, states.isComum])

  useEffect(()=>{
    listarClientesAsync(verMais)
  },[offset])

  const listarPermissoesAsync = async() => {
    try
    {
        await verificaPermissao()
    }
    catch(err)
    {
        toast.error(err.response.data.msg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
    }
  }

  const listarClientesAsync = async(verMais) => {
    try
    {
      await listarClientes(6,offset,verMais)
      setVerMais(false)
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }

  const viewClientOnClick = async(id) => {
    setters.setModalIsOpen(true)
    setIdClientSelected(id)
  }

  const handleVerMais = (e) =>
  {
    e.preventDefault()
    setOffset(offset+1)
    setVerMais(true)
  }

  const pesquisaCliente= async () => {
    try{
        const cpf = searchType.replace(/[^\d]+/g,'');
        const result = await listarClientes(6, offset, verMais, cpf)
        if(result.status == false)
        {
          toast.error(result.msg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        }
      }
      catch(err)
      {
        toast.error(err.response.data.msg, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      }
  }

  return (
    <>
      <Navbar/>
      <main>

        <div className="space-y-16 py-16 xl:space-y-20 pt-6">
          {/* Recent client list*/}
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Clientes do sistema</h1>
                <h1 className="mt-4 text-sm font-semibold leading-3 text-gray-500">Pesquisar por CPF</h1>
                <div className="sm:grid sm:grid-cols-2 sm:items-start sm:gap-4 mt-3">
                    <div className="sm:col-span-1 sm:mt-0">
                      <div className="flex max-w rounded-md">
                        <form className="flex md:ml-0">
                          <div className="relative w-full text-gray-400 focus-within:text-gray-600 border border-gray-300 rounded-md">
                            <div className="pointer-events-none absolute inset-y-0 left-0 pl-2 flex items-center rounded-md">
                              <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                            </div>
                            <input
                              id="search-field"
                              name="search-field"
                              type="search"
                              className="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 focus:border-transparent focus:outline-none focus:ring-0 focus:placeholder:text-gray-400 sm:text-sm rounded-md"
                              placeholder='Digite ..'
                              maxLength={14}
                              onChange={e => setSearchType(e.target.value)}
                              onBlur={()=>pesquisaCliente()}
                              value={searchType}
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                </div>
              {states.loading == true?
                  <Spinner/>
              :
              states.clients.length !=0 ?
              <div>
                <ul role="list" className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                  {states.clients.map((client) => (
                      <li key={client.ID} className="overflow-hidden rounded-xl border border-gray-200 hover:bg-gray-50 hover:shadow">
                      <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                          <div className="text-sm font-medium leading-6 text-gray-900 capitalize">{client.Name.toLowerCase()}</div>
                          
                          {/* {client.IsDeleted == 0 &&  */}
                          <Menu as="div" className="relative ml-auto">
                          <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                              <span className="sr-only">Abrir opções</span>
                              <EllipsisHorizontalIcon className="h-6 w-6" aria-hidden="true" />
                          </Menu.Button>
                          <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                          >
                              
                              <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                  <Menu.Item>
                                      {({ active }) => (
                                      <a
                                          href="#"
                                          className={classNames(
                                          active ? 'bg-gray-50' : '',
                                          'block px-3 py-1 text-sm leading-6 text-gray-900'
                                          )}
                                          onClick={()=>viewClientOnClick(client.ID)}
                                      >
                                          Visualizar
                                      </a>
                                      )}
                                  </Menu.Item>

                                  {/* <Menu.Item>
                                      {({ active }) => (
                                      <a
                                          href="#"
                                          className={classNames(
                                          active ? 'bg-gray-50' : '',
                                          'block px-3 py-1 text-sm leading-6 text-gray-900'
                                          )}
                                          // onClick={()=>deleteProductOnClick(product.ID)}
                                      >
                                          Excluir
                                      </a>
                                      )}
                                  </Menu.Item> */}

                              </Menu.Items>
                              
                          </Transition>
                          </Menu>
                           {/* } */}
                      </div>
                      <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                          <div className="flex justify-between gap-x-4 py-3">
                              <dt className="text-gray-500">Telefone</dt>
                              <dd className="text-gray-700">
                                  {(client.Phone).replace(/\D/g,'').replace(/(\d{2})(\d)/,"($1) $2").replace(/(\d)(\d{4})$/,"$1-$2")}
                                  {/* <time dateTime={client.lastInvoice.dateTime}>{client.lastInvoice.date}</time> */}
                              </dd>
                          </div>

                          <div className="flex justify-between gap-x-4 py-3">
                              <dt className="text-gray-500">CPF</dt>
                              <dd className="flex items-start gap-x-2">
                                  <div className="font-medium text-gray-900">
                                  {client.CPF.slice(0, 3) + '.' + client.CPF.slice(3, 6) + '.' + client.CPF.slice(6, 9) + '-' + 
                                               client.CPF.slice(9, 11)}
                                  </div>
                              </dd>
                          </div>

                          <div className="flex justify-between gap-x-4 py-3">
                              <dt className="text-gray-500">RG</dt>
                              <dd className="flex items-start gap-x-2">
                                  <div className="font-medium text-gray-900">
                                  {client.RG}
                                  </div>
                              </dd>
                          </div>

                          <div className="flex justify-between gap-x-4 py-3">
                              <dt className="text-gray-500">Status</dt>
                              <dd className="flex items-start gap-x-2">
                                  <div
                                      className={classNames(
                                      statuses[client.IsDeleted],
                                      'rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset'
                                      )}
                                  >
                                      {client.IsDeleted == 1 ? 'Inativo':'Ativo'}
                                  </div>
                              </dd>
                          </div>

                      </dl>
                      </li>
                  ))}
                </ul>

                {states.loadMoreClients && 
                  <nav className="flex items-center justify-between px-4 sm:px-0 mt-4">
                      <div className="-mt-px flex w-0 flex-1"/>

                      <div className="hidden md:-mt-px md:flex">
                          <button
                          className="inline-flex items-center px-4 pt-4 text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
                          onClick={e=> handleVerMais(e)}>
                          Ver mais
                          </button>
                      </div>

                      <div className="-mt-px flex w-0 flex-1 justify-end"/>
                  </nav>
                }
              </div>
              : 
                <Error origin={'clientes a serem exibidos'}/>
                }
          </div>
          </div>
        </div>


      </main>

      <Footer/>
      <SpinnerOverlay/>
      <ModalEmail states={states} setters={setters} /> {/*  setConfirm={setConfirm} */}
      <ModalClient states={states} setters={setters} id={idClientSelected}/>
    </>
  )
}




