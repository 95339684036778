import { Fragment, useState,useEffect,useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { AuthContext } from "../../../Context"
import InputMask from "react-input-mask";
import { toast } from 'react-toastify';
import { useContext } from "react"
import ColorPicker from '../../ColorPicker';
import FileUpload from '../../FileUpload';



export default function ModalCompany(props) {  
  const cancelButtonRef = useRef(null)

  const [color , setColor] = useState('')
  const [selectedFile, setSelectedFile] = useState(null);

  const {cadastrarEmpresa,editarEmpresa,listarEmpresas, validarCnpj} = useContext(AuthContext)
  const [nome,setNome]= useState('')
  const [cnpj,setCnpj]= useState('')
  const [descricao, setDescricao] = useState('')

  const[errorNome,setErrorNome] = useState(false)
  const[errorCnpj,setErrorCnpj] = useState(false)
  const[errorFile,setErrorFile] = useState(false)
  const[errorDescricao, setErrorDescricao] = useState(false)

  useEffect(() => {
    if(props.states.modalIsOpen === false)
    {
        limparStatesModalCompany()
        listarEmpresasAsync()
    }
    else
    {
      if(props.company == '')
      {
        setNome('') 
        setCnpj('')
        setDescricao('')
      }
      else
      {
        setNome(props.company.Nome)
        setCnpj(props.company.CNPJ)
        setColor(props.company.Color)
        setDescricao(props.company.Description)
      }
    }
  }, [props.states.modalIsOpen]);

  const enviarFormOnClick = async (e) =>
  {
    try{
      e.preventDefault()
      const company = {name: nome, cnpj: cnpj.replaceAll('.','').replaceAll('/','').replaceAll('-','').replaceAll('_',''), description: descricao}
      const retorno = validaStatesModalCompany(company)

      if(retorno && (!errorNome && !errorCnpj && !errorFile && !errorDescricao))
      {
        const result = (props.company == '' || props.company == null || props.company == undefined) ? await cadastrarEmpresa(company, color, selectedFile):await editarEmpresa(props.company.ID,company, color, selectedFile)
        if(result.status == true)
        {
          toast.success('Empresa '+(props.company == '' ? 'cadastrada':'editada')+ ' com sucesso!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            props.setters.setModalIsOpen(false)
        }
        else
        {
          toast.error(result.msg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        }
      }
      else
      {
        toast.error('Empresa não pôde ser cadastrada!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      }
      
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }

  const validaStatesModalCompany = (company) =>
  {
    try
    {
      let resultado = true
      if(company.name == '' || company.name == undefined || company.name == null)
      {
        resultado=false
        setErrorNome(true)
      }
      else 
      {
        setErrorNome(false)
      }

      if(company.cnpj == '' || company.cnpj == undefined || company.cnpj == null)
      {
        resultado=false
        setErrorCnpj(true)
      }
      else
      {
        const resultado = validarCnpj(cnpj)
        setErrorCnpj(!resultado.status)
      }

      if(company.description == '' || company.description == undefined || company.description == null)
      {
        resultado=false
        setErrorDescricao(true)
      }
      else
      {
        setErrorDescricao(false)
      }

      return resultado
    }
    catch(err)
    {
      return false
    }
  }

  const limparStatesModalCompany = async () =>
  {
      setNome('') 
      setCnpj('')
      setSelectedFile('')
      setColor('')
      setDescricao('')

      setErrorNome(false)
      setErrorCnpj(false)
      setErrorFile(false)
      setErrorDescricao(false)
  }

  const listarEmpresasAsync = async() => {
    try{
      await listarEmpresas(10,0,'')
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }

  const leaveInput=(valor)=>
  {
    try
    {
      if(valor == '' || valor == undefined || valor == null)
      {
        setErrorCnpj(true)
      }
      else
      {
        const resultado = validarCnpj(cnpj)
        setErrorCnpj(!resultado.status)
      }
    }
    catch(err)
    {

    }
  }
  
  return (
    <Transition.Root show={props.states.modalIsOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={()=>props.setters.setModalIsOpen(false)} >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <form className="space-y-8 divide-y divide-gray-200" onSubmit={e => enviarFormOnClick(e)}>
              {/* onSubmit={()=> cadastrarAssociado()} */}
                      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                        <div className="space-y-6 sm:space-y-5">
                            <div className='flex-col'>
                              <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Empresa</h3>
                              <p className="flex mt-1 text-sm text-gray-500 justify-center">
                                Informações da empresa
                              </p>
                            </div>

                            <div className="space-y-6 sm:space-y-5">
                              {/* 1 LINHA */}
                              <div className="sm:grid sm:grid-cols-2 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                
                                <div className="mt-2 sm:col-span-1 sm:mt-0">
                                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                  Nome
                                </label>
                                  <div className="flex max-w-lg rounded-md shadow-sm">
                                    <input
                                      
                                      type="text"
                                      name="nome"
                                      id="nome"
                                      autoComplete="nome"
                                      placeholder='Digite o nome'
                                      value={nome}
                                      onChange={e => setNome(e.target.value)}
                                      className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                  </div>
                                  {
                                    errorNome &&
                                    <p class="mt-2 ml-2 text-pink-600 text-sm">                     
                                        É obrigatório o preenchimento do nome.
                                    </p>
                                  }
                                </div>
                                
                                <div className="mt-2 sm:col-span-1 sm:mt-0">
                                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                  CNPJ
                                </label>
                                <div className="flex max-w-lg rounded-md shadow-sm">
                                    <InputMask
                                      
                                      type="text"
                                      name="cnpj"
                                      id="cnpj"
                                      autoComplete="cnpj"
                                      placeholder='Digite o CNPJ'
                                      mask="99.999.999/0009-99"
                                      value={cnpj}
                                      onChange={e => setCnpj(e.target.value)}
                                      onBlur={e=> leaveInput(e.target.value)}
                                      className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                   /> 
                                  </div>
                                  {
                                    errorCnpj &&
                                    <p class="mt-2 ml-2 text-pink-600 text-sm">
                                  
                                        É obrigatório o preenchimento de um CNPJ válido.
                                    </p>
                                  }
                                </div>

                                <div className="mt-2 sm:col-span-full sm:mt-0">
                                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                    Descrição
                                </label>
                                <div className="mt-2">
                                <textarea
                                    rows={6}
                                    name="descricao"
                                    id="descricao"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    placeholder='Digite uma descrição para a empresa'
                                    value={descricao}
                                    onChange={e => setDescricao(e.target.value)}
                                    defaultValue={''}
                                    />
                                    {
                                        errorDescricao === true && 
                                        <p class="mt-2 ml-1 text-pink-600 text-sm">
                                            É obrigatório o preenchimento da descrição.
                                        </p>
                                    }
                                </div>
                                </div>

                                <div className="mt-2 sm:col-span-1 sm:mt-0">
                                  <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                    Logo da empresa
                                  </label>
                                  <div className='pr-1 pl-1'>
                                    <div className=''>
                                      <FileUpload type={'image'} selectedFile={selectedFile} setSelectedFile={setSelectedFile}/>
                                    </div>
                                  </div>
                                </div>

                                <div className="mt-2 sm:col-span-1 sm:mt-0">
                                  <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                    Cor de fundo
                                  </label>
                                  <div className='pr-1 pl-1'>
                                    <ColorPicker color={color} setColor={setColor}/>
                                  </div>
                                  {
                                    errorFile &&
                                    <p class="mt-2 ml-2 text-pink-600 text-sm">                     
                                        É obrigatório o preenchimento do nome.
                                    </p>
                                  }
                                </div>
                              
                              </div>
                            </div>
                        </div>
                      </div>

                      <div className="pt-5" >
                        <div className="flex justify-end gap-x-3">
                          <button
                            type="button"
                            className="rounded-md bg-white py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            onClick={()=>props.setters.setModalIsOpen(false)}
                          >
                            Cancelar
                          </button>
                          <button
                            type="submit"
                            className="inline-flex justify-center rounded-md bg-blue-500 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            // onClick={()=>props.setters.setModalIsOpen(true)}
                          >
                            Enviar
                          </button>
                        </div>
                      </div>
              </form>
                
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
