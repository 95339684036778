import { createContext, useState } from "react";
import Axios from "axios";

export const AuthContext = createContext();


const urlApi = 'https://api.somaassist.com.br/api'
const API_KEY = 'eyJhbGciOiJIUzI1NiJ9.JWprZGFnc2tqZGdhc2tqZ2RqaGdqa2VoZzFramgyZ2tqaDFnasOzczAxOcKoJiEqJiFza2phaHNkbGhhbHNrZGhhc2Q3MzgxNzIwOTM3MTk4Mzc4OTQ3MCTCqCQkJibCqCQmwqglJCbCqCUlJipVR0pHREpBS2dramE.fIxaNbb-HmOjv6JDxEKQQ5O5Jkeau4hE2RYdIqENytY'

const AuthProvider = ({ children }) => {

  const navigation = [
    { name: 'Home', href: '/home' },
    { name: 'Produtos', href: '/produtos' },
    { name: 'Vendas', href: '/vendas' },
    { name: 'Clientes', href: '/clientes' },
    { name: 'Usuários', href: '/usuarios' },
    { name: 'Empresas', href: '/empresas' }
  ]

  const [numeroVendas, setNumeroVendas] = useState('')
  const [numeroClientes, setNumeroClientes] = useState('')
  const [numeroUsuarios, setNumeroUsuarios] = useState('')
  const [numeroProdutos, setNumeroProdutos] = useState('')

  const [valorVendas, setValorVendas] = useState('')
  const [valorComissao, setValorComissao] = useState('')

  const estatisticasHome = [
    { id: 1, name: 'Quantidade de Vendas', value: numeroVendas },
    { id: 2, name: 'Quantidade de Produtos', value: numeroProdutos },
    { id: 3, name: 'Quantidade de Clientes', value: numeroClientes },
    { id: 4, name: 'Quantidade de Usuários', value: numeroUsuarios },
  ]

  const estatisticasVendas = [
    { name: 'Quantidade de Vendas', value: numeroVendas },
    { name: 'Valor Total das Vendas', value: 'R$ ' + (valorVendas / 100).toString().replace('.', ',') },
    { name: 'Valor Total das Comissões', value: 'R$ ' + (valorComissao / 100).toString().replace('.', ',') },
  ]

  const estatisticasClientes = [
    { name: 'Revenue', value: '$405,091.00', change: '+4.75%', changeType: 'positive' },
    { name: 'Overdue invoices', value: '$12,787.00', change: '+54.02%', changeType: 'negative' },
    { name: 'Outstanding invoices', value: '$245,988.00', change: '-1.39%', changeType: 'positive' },
    { name: 'Expenses', value: '$30,156.00', change: '+10.18%', changeType: 'negative' },
  ]


  const listarEstatisticasHome = async () => {
    const req = {
      headers: { "apikey": API_KEY },
    }
    try {
      const Result = await Axios.post(`${urlApi}/estatisticas/home`, { "token": localStorage.getItem('token') }, req);
      if (Result.data.status === true) {
        setNumeroUsuarios(Result.data.usuarios)
        setNumeroClientes(Result.data.clientes)
        setNumeroVendas(Result.data.vendas)
        setNumeroProdutos(Result.data.produtos)
        return { status: true }
      }
      else {
        return { status: false, msg: Result.data.msg }
      }

    } catch (err) {
      if (err.response.status == 403) {
        setSigned(false)
      }
      return { status: false, msg: err.response.data.msg }
    }
  }

  const listarEstatisticasVendas = async () => {
    const req = {
      headers: { "apikey": API_KEY },
    }
    try {
      if (isAdmin) {
        const permissao = 'Admin'
        const Result = await Axios.post(`${urlApi}/estatisticas/vendas/${permissao}`, { "token": localStorage.getItem('token') }, req);
        if (Result.data.status === true) {
          setNumeroVendas(Result.data.vendas)
          setValorVendas(Result.data.valorVendas)
          setValorComissao(Result.data.valorComissao)
          return { status: true }
        }
        else {
          return { status: false, msg: Result.data.msg }
        }
      }
      else {
        const permissao = 'Vendedor'
        const Result = await Axios.post(`${urlApi}/estatisticas/vendas/${permissao}`, {
          "token": localStorage.getItem('token')
        }, req);
        if (Result.data.status === true) {
          setNumeroVendas(Result.data.vendas)
          setValorVendas(Result.data.valorVendas)
          setValorComissao(Result.data.valorComissao)
          return { status: true }
        }
        else {
          return { status: false, msg: Result.data.msg }
        }
      }
    } catch (err) {
      return { status: false, msg: err.response.data.msg }
    }
  }

  const listarEstatisticasClientes = async () => {
    const req = {
      headers: { "apikey": API_KEY },
    }
    try {
      const Result = await Axios.post(`${urlApi}/estatisticas/home`, { "token": localStorage.getItem('token') }, req);
      if (Result.data.status === true) {
        setNumeroUsuarios('1')
        setNumeroClientes('2')
        setNumeroVendas('3')
        return { status: true }
      }
      else {
        return { status: false, msg: Result.data.msg }
      }

    } catch (err) {
      return { status: false, msg: err.response.data.msg }
    }
  }


  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [signed, setSigned] = useState(false)
  const [remainSigned, setRemainSigned] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState(true)

  const [isAdmin, setIsAdmin] = useState(false)
  const [isVendedor, setIsVendedor] = useState(false)
  const [isComum, setIsComum] = useState(true)

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalIsOpenAux, setModalIsOpenAux] = useState(false)

  const [productSelected, setProductSelected] = useState('')
  const [productSelectedUser, setProductSelectedUser] = useState('')
  const [productSelectedCompany, setProductSelectedCompany] = useState('')
  const [clientSelected, setClientSelected] = useState('')
  const [clientSelectedUser, setClientSelectedUser] = useState('')
  const [saleSelected, setSaleSelected] = useState('')
  const [saleSelectedProducts, setSaleSelectedProducts] = useState('')
  const [saleSelectedClient, setSaleSelectedClient] = useState('')

  const [products, setProducts] = useState('')
  const [clients, setClients] = useState('')
  const [users, setUsers] = useState('')
  const [sales, setSales] = useState('')
  const [companies, setCompanies] = useState('')

  const [loading, setLoading] = useState(false)
  const [loadMoreProducts, setLoadMoreProducts] = useState(false)
  const [loadMoreClients, setLoadMoreClients] = useState(false)
  const [loadMoreSales, setLoadMoreSales] = useState(false)
  const [loadMoreUsers, setLoadMoreUsers] = useState(false)
  const [loadMoreCompanies, setLoadMoreCompanies] = useState(false)

  const [totalRegisters, setTotalRegisters] = useState(0)

  const [compraDeslogado, setCompraDeslogado] = useState(false)

  const [companyColor, setCompanyColor] = useState('')
  const [companyUrl, setCompanyUrl] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [companyDescription, setCompanyDescription] = useState('')

  const [spinner, setSpinner] = useState(false)

  const [nomeEmail, setNomeEmail] = useState('')
  const [enderecoEmail, setEnderecoEmail] = useState('')

  const states =
  {
    nomeEmail,
    enderecoEmail,
    spinner,
    companyName,
    companyDescription,
    companyColor,
    companyUrl,
    compraDeslogado,
    totalRegisters,
    loadMoreUsers,
    loadMoreClients,
    loadMoreSales,
    loadMoreProducts,
    loadMoreCompanies,
    navigation,
    estatisticasHome,
    estatisticasVendas,
    estatisticasClientes,
    email,
    password,
    signed,
    remainSigned,
    sidebarOpen,
    products,
    productSelected,
    modalIsOpen,
    modalIsOpenAux,
    clients,
    users,
    sales,
    companies,
    saleSelected,
    saleSelectedProducts,
    saleSelectedClient,
    clientSelected,
    clientSelectedUser,
    isAdmin,
    isVendedor,
    isComum,
    loading,
    productSelectedUser,
    productSelectedCompany
  }

  const setters =
  {
    setNomeEmail,
    setEnderecoEmail,
    setSpinner,
    setCompanyName,
    setCompanyDescription,
    setCompanyColor,
    setCompanyUrl,
    setCompraDeslogado,
    setTotalRegisters,
    setEmail,
    setPassword,
    setSigned,
    setRemainSigned,
    setSidebarOpen,
    setProducts,
    setProductSelected,
    setModalIsOpen,
    setModalIsOpenAux,
    setClients,
    setUsers,
    setSales,
    setCompanies,
    setSaleSelected,
    setSaleSelectedProducts,
    setSaleSelectedClient,
    setClientSelected,
    setClientSelectedUser,
    setProductSelectedUser,
    setProductSelectedCompany,
    setLoadMoreProducts,
    setLoadMoreSales,
    setLoadMoreClients,
    setLoadMoreUsers,
    setLoadMoreCompanies
  }

  const checkLogin = async () => {
    const req = {
      headers: { "apikey": API_KEY },
    }
    try {
      await Axios.post(`${urlApi}/checklogin`, { "token": localStorage.getItem('token') }, req);
      return true
    } catch (err) {
      return false
    }
  }

  const login = async (manterLogado, email, senha) => {
    setSpinner(true)
    const req = {
      headers: { "apikey": API_KEY },
    }
    try {
      let Result
      if (email != undefined && senha != undefined) {
        Result = await Axios.post(`${urlApi}/login`, { email: email, password: senha }, req);
      }
      else {
        Result = await Axios.post(`${urlApi}/login`, { email: states.email, password: states.password }, req);
      }

      // const Result = await Axios.post(`${urlApi}/teste`, req);
      if (Result.data.status === true) {
        if (Result.data.changePassword == true) {
          localStorage.removeItem('permission')
          localStorage.removeItem('email');
          localStorage.removeItem('password');
          localStorage.removeItem('companyID')

          setSpinner(false)
          return { status: true, changePassword: true }
        }
        else {
          limparStates()
          localStorage.removeItem('permission')
          localStorage.setItem('token', Result.data.token);
          if (manterLogado) {
            localStorage.setItem('email', states.email);
            localStorage.setItem('password', states.password);
          }
          else {
            localStorage.removeItem('email');
            localStorage.removeItem('password');
          }
          localStorage.setItem('permission', Result.data.permission);
          localStorage.setItem('companyID', Result.data.company)
          setEmail('')
          setPassword('')
          setSigned(true)
          verificaPermissao()

          setSpinner(false)
          return { status: true, changePassword: false }
        }
      }
      else {
        setSpinner(false)
        return { status: false, msg: Result.data.msg }
      }

    } catch (err) {
      setSpinner(false)
      return { status: false, msg: err.response.data.msg }
    }
  }

  const logout = async () => {
    const req = {
      headers: { "apikey": API_KEY }
    }
    try {
      const Result = await Axios.post(`${urlApi}/logout`, { "token": localStorage.getItem('token') }, req);
      if (Result.data.status === true) {
        localStorage.removeItem('token');
        localStorage.removeItem('permission')
        localStorage.removeItem('companyID')
        setSigned(false)
        setModalIsOpen(false)
        setIsAdmin(false)
        setIsVendedor(false)
        setIsComum(false)
        limparStates()
        return { status: true }
      }
      else {
        localStorage.removeItem('token');
        localStorage.removeItem('permission')
        localStorage.removeItem('companyID')
        setSigned(false)
        setModalIsOpen(false)
        setIsAdmin(false)
        setIsVendedor(false)
        setIsComum(false)
        return { status: false, msg: Result.data.msg }
      }

    } catch (err) {
      localStorage.removeItem('token');
      localStorage.removeItem('permission')
      setSigned(false)
      setModalIsOpen(false)
      setIsAdmin(false)
      setIsVendedor(false)
      setIsComum(false)
      return { status: false, msg: err.response.data.msg }
    }
  }

  const listarProdutos = async (isVendedor, isAdmin, limit, offset, verMais) => {
    setLoading(true)
    const req = {
      headers: { "apikey": API_KEY },
    }
    try {
      if (!isVendedor) {
        const Result = await Axios.post(`${urlApi}/product/get`, {
          "token": localStorage.getItem('token'), "limit": limit, "offset": offset, "listarTudo": isAdmin
        }, req);


        if (Result.data.status === true) {
          if (verMais) {
            const arrNew = []
            products.length > 0 && arrNew.push(...products)
            arrNew.push(...Result.data.product)
            setProducts(arrNew)
          }
          else {
            setProducts(Result.data.product)
          }

          setLoading(false)
          setLoadMoreProducts(Result.data.product.length % limit == 0)

          return { status: true }
        }
        else {
          setProducts('')
          setLoading(false)
          setLoadMoreProducts(false)
          return { status: false, msg: Result.data.msg }
        }
      }
      else {
        const Result = await Axios.post(`${urlApi}/product/getbyuser`, {
          "token": localStorage.getItem('token'), "limit": limit, "offset": offset
        }, req);

        if (Result.data.status === true) {
          if (verMais) {
            const arrNew = []
            products.length > 0 && arrNew.push(...products)
            arrNew.push(...Result.data.product)
            setProducts(arrNew)
          }
          else {
            setProducts(Result.data.product)
          }
          setLoading(false)
          setLoadMoreProducts(Result.data.product.length % limit == 0)
          return { status: true }
        }
        else {
          setProducts('')
          setLoading(false)
          setLoadMoreProducts(false)
          return { status: false, msg: Result.data.msg }
        }
      }

    } catch (err) {
      console.log(err)
      if (err.response.status != 400) {
        setProducts('')
      }
      setLoading(false)
      setLoadMoreProducts(false)
      return { status: false, msg: err.response.data.msg }
    }
  }

  const listarClientes = async (limit, offset, verMais, cpf) => {
    setLoading(true)
    const req = {
      headers: { "apikey": API_KEY }
    }
    try {
      let Result
      if (cpf == '' || cpf == null || cpf == undefined) {
        Result = await Axios.post(`${urlApi}/client/getall`, {
          "token": localStorage.getItem('token'), "limit": limit, "offset": offset, "cpf": cpf
        }, req);
      }
      else {
        Result = await Axios.post(`${urlApi}/client/getbycpf`, {
          "token": localStorage.getItem('token'), "cpf": cpf
        }, req);
      }


      if (Result.data.status === true) {
        if (modalIsOpen == true) {
          setClients(Result.data.client)
        }
        else {
          if (verMais) {
            const arrNew = []
            clients.length > 0 && arrNew.push(...clients)
            arrNew.push(...Result.data.client)
            setClients(arrNew)
          }
          else {
            setClients(Result.data.client)
          }

        }

        setLoading(false)
        setLoadMoreClients(Result.data.client.length % limit == 0)
        return { status: true }
      }
      else {
        setLoading(false)
        setLoadMoreClients(false)
        return { status: false, msg: Result.data.msg }
      }
    } catch (err) {
      setLoading(false)
      setLoadMoreClients(false)
      return { status: false, msg: err.response.data.msg }
    }
  }

  const listarUsuarios = async (limit, offset, permission) => {
    setLoading(true)
    const req = {
      headers: { "apikey": API_KEY }
    }
    try {
      const Result = await Axios.post(`${urlApi}/User/Get`, {
        "token": localStorage.getItem('token'), "limit": limit, "offset": offset, "permission": permission
      }, req);

      if (Result.data.status === true) {
        setUsers(Result.data.user)
        setLoading(false)
        setTotalRegisters(Result.data.totalRegisters)
        setLoadMoreUsers(Result.data.user.length % limit == 0)
        return { status: true }
      }
      else {
        setLoading(false)
        setLoadMoreUsers(false)
        return { status: false, msg: Result.data.msg }
      }
    } catch (err) {
      setLoading(false)
      setLoadMoreUsers(false)
      return { status: false, msg: err.response.data.msg }
    }
  }

  const listarVendas = async (limit, offset, verMais) => {
    setLoading(true)
    const req = {
      headers: { "apikey": API_KEY }
    }
    try {
      if (isAdmin) {
        const Result = await Axios.post(`${urlApi}/sale/getall`, {
          "token": localStorage.getItem('token'), "limit": limit, "offset": offset
        }, req);

        if (Result.data.status === true) {
          if (verMais) {
            const arrNew = []
            sales.length > 0 && arrNew.push(...sales)
            arrNew.push(...Result.data.sale)
            setSales(arrNew)
          }
          else {
            setSales(Result.data.sale)
          }
          setLoading(false)
          setLoadMoreSales(Result.data.sale.length % limit == 0)

          return { status: true }
        }
        else {
          setLoading(false)
          setLoadMoreSales(false)
          return { status: false, msg: Result.data.msg }
        }
      }
      else if (isVendedor) {
        const Result = await Axios.post(`${urlApi}/sale/getbyuser`, {
          "token": localStorage.getItem('token'), "limit": limit, "offset": offset
        }, req);

        if (Result.data.status === true) {
          if (verMais) {
            const arrNew = []
            sales.length > 0 && arrNew.push(...sales)
            arrNew.push(...Result.data.sale)
            setSales(arrNew)
          }
          else {
            setSales(Result.data.sale)
          }
          setLoading(false)
          setLoadMoreSales(Result.data.sale.length % limit == 0)
          return { status: true }
        }
        else {
          setLoading(false)
          setLoadMoreSales(false)
          return { status: false, msg: Result.data.msg }
        }
      }
      else if (isComum) {
        const Result = await Axios.post(`${urlApi}/sale/getbyclient`, {
          "token": localStorage.getItem('token'), "limit": limit, "offset": offset
        }, req);
        if (Result.data.status === true) {
          if (verMais) {
            const arrNew = []
            sales.length > 0 && arrNew.push(...sales)
            arrNew.push(...Result.data.sale)
            setSales(arrNew)
          }
          else {
            setSales(Result.data.sale)
          }
          setLoading(false)
          setLoadMoreSales(Result.data.sale.length % limit == 0)
          return { status: true }
        }
        else {
          setLoading(false)
          setLoadMoreSales(false)
          return { status: false, msg: Result.data.msg }
        }
      }
    } catch (err) {
      setLoading(false)
      setLoadMoreSales(false)
      return { status: false, msg: err.response.data.msg }
    }
  }

  const listarEmpresas = async (limit, offset, cnpj) => {
    setLoading(true)
    const req = {
      headers: { "apikey": API_KEY }
    }
    try {
      let Result
      if (cnpj == '' || cnpj == undefined || cnpj == null) {
        Result = await Axios.post(`${urlApi}/company/get`, {
          "token": localStorage.getItem('token'), "limit": limit, "offset": offset, "cnpj": cnpj
        }, req);
      }
      else {
        Result = await Axios.post(`${urlApi}/company/getbycnpj`, {
          "token": localStorage.getItem('token'), "cnpj": cnpj
        }, req);
      }

      if (Result.data.status === true) {
        setCompanies(Result.data.company)
        setLoading(false)
        setTotalRegisters(Result.data.totalRegisters)
        setLoadMoreCompanies(Result.data.company.length % limit == 0)
        return { status: true }
      }
      else {
        setLoading(false)
        setLoadMoreCompanies(false)
        return { status: false, msg: Result.data.msg }
      }
    } catch (err) {
      setLoading(false)
      setLoadMoreCompanies(false)
      return { status: false, msg: err.response.data.msg }
    }
  }



  const cadastrarUsuario = async (user) => {
    setSpinner(true)
    const req = {
      headers: { "apikey": API_KEY }
    }
    try {
      const Result = await Axios.post(`${urlApi}/user/add`, {
        "token": localStorage.getItem('token'), email: user.email, password: user.password, permission: user.permission, "companyID": user.companyID, "comission": user.comission
      }, req);

      if (Result.data.status === true) {
        setSpinner(false)
        return { status: true }
      }
      else {
        setSpinner(false)
        return { status: false, msg: Result.data.msg }
      }
    } catch (err) {
      setSpinner(false)
      return { status: false, msg: err.response.data.msg }
    }
  }

  const cadastrarProduto = async (product, fileFoto, filePdf) => {
    setSpinner(true)
    const req = {
      headers: {
        "apikey": API_KEY,
        'Content-Type': "multipart/form-data"
      }
    }
    try {
      const formData = new FormData();

      formData.append('token', localStorage.getItem('token'));
      formData.append('title', product.title);
      formData.append('description', product.description);
      formData.append('price', product.price);
      formData.append('duration', product.duration);
      formData.append('idsUser', product.idsUser);
      formData.append('comission', product.comission);
      formData.append('idsCompany', product.idsCompany);
      formData.append('clausulas', product.clausulas);
      formData.append('files', fileFoto);
      formData.append('files', filePdf);


      const Result = await Axios.post(`${urlApi}/product/add`, formData, req);

      if (Result.data.status === true) {
        setSpinner(false)
        return { status: true }
      }
      else {
        setSpinner(false)
        return { status: false, msg: Result.data.msg }
      }
    } catch (err) {
      setSpinner(false)
      return { status: false, msg: err.response.data.msg }
    }
  }

  const cadastrarVenda = async (sale, creditCard, creditCardHolderInfo) => {
    setSpinner(true)
    const req = {
      headers: { "apikey": API_KEY }
    }
    try {
      const Result = await Axios.post(`${urlApi}/sale/add`, {
        "token": localStorage.getItem('token'),
        idClient: sale.idClient, origin: sale.origin, value: sale.value, observation: sale.observation, idsProduct: sale.idsProduct, creditCard, creditCardHolderInfo
        , companyID: sale.companyID, dependentes: sale.dependentes
      }, req);


      if (Result.data.status == true) {
        setSpinner(false)
        return { status: true }
      }
      else {
        setSpinner(false)
        return { status: false, msg: Result.data.msg }
      }
    } catch (err) {
      setSpinner(false)
      return { status: false, msg: err.response.data.msg }
    }
  }

  const cadastrarCliente = async (client) => {
    setSpinner(true)
    const req = {
      headers: { "apikey": API_KEY }
    }
    try {
      const Result = await Axios.post(`${urlApi}/client/add`, {
        // "token": localStorage.getItem('token'),
        email: client.email,
        password: client.password,
        name: client.name,
        cpf: client.cpf,
        rg: client.rg,
        phone: client.phone,
        address: client.address
      }, req);

      if (Result.data.status === true) {
        setSpinner(false)
        return { status: true, content: 'Cadastro efetuado com sucesso' }
      }
      else {
        setSpinner(false)
        return { status: false, msg: Result.data.msg }
      }
    } catch (err) {
      setSpinner(false)
      return { status: false, msg: err.response.data.msg }
    }
  }

  const cadastrarEmpresa = async (company, color, file) => {
    setSpinner(true)
    const req = {
      headers: {
        "apikey": API_KEY,
        'Content-Type': "multipart/form-data"
      }
    }
    try {
      const Result = await Axios.post(`${urlApi}/company/add`, {
        name: company.name,
        cnpj: company.cnpj,
        color: color,
        file: file,
        description: company.description
      }, req);


      if (Result.data.status === true) {
        setSpinner(false)
        return { status: true, content: 'Empresa adicionada com sucesso' }
      }
      else {
        setSpinner(false)
        return { status: false, msg: Result.data.msg }
      }
    } catch (err) {
      setSpinner(false)
      return { status: false, msg: err.response.data.msg }
    }
  }

  const editarEmpresa = async (id, company, color, file) => {
    setSpinner(true)
    const req = {
      headers: {
        "apikey": API_KEY,
        'Content-Type': "multipart/form-data"
      },
    }
    try {
      const Result = await Axios.post(`${urlApi}/company/edit/${id}`, {
        "token": localStorage.getItem('token'),
        name: company.name,
        cnpj: company.cnpj,
        color: color,
        file: file,
        description: company.description

      }, req);

      if (Result.data.status === true) {
        setSpinner(false)
        return { status: true }
      }
      else {
        setSpinner(false)
        return { status: false, msg: Result.data.msg }
      }
    } catch (err) {
      setSpinner(false)
      return { status: false, msg: err.response.data.msg }
    }
  }

  const editarUsuario = async (user) => {
    setSpinner(true)
    const req = {
      headers: { "apikey": API_KEY }
    }
    try {
      const Result = await Axios.post(`${urlApi}/user/edit/${user.id}`, {
        "comission": user.comission, editPassword: false
      }, req);

      if (Result.data.status === true) {
        setSpinner(false)
        return { status: true }
      }
      else {
        setSpinner(false)
        return { status: false, msg: Result.data.msg }
      }
    } catch (err) {
      setSpinner(false)
      return { status: false, msg: err.response.data.msg }
    }
  }

  const pagarComissao = async (id) => {
    const req = {
      headers: { "apikey": API_KEY }
    }
    try {
      const Result = await Axios.post(`${urlApi}/sale/comission/${id}`, {
        "token": localStorage.getItem('token'),
      }, req);

      if (Result.data.status === true) {
        return { status: true }
      }
      else {
        return { status: false, msg: Result.data.msg }
      }
    } catch (err) {
      return { status: false, msg: err.response.data.msg }
    }
  }



  const carregarProduto = async (id) => {
    setSpinner(true)
    const req = {
      headers: { "apikey": API_KEY }
    }
    try {
      const Result = await Axios.post(`${urlApi}/product/get/${id}`, {
        "token": localStorage.getItem('token'),
      }, req);

      if (Result.data.status === true) {

        setProductSelected(Result.data.product)
        setProductSelectedUser(Result.data.user)
        setProductSelectedCompany(Result.data.company)
        setSpinner(false)
        return { status: true }
      }
      else {
        setSpinner(false)
        return { status: false, msg: Result.data.msg }
      }
    } catch (err) {
      setSpinner(false)
      return { status: false, msg: err.response.data.msg }
    }
  }

  const carregarVenda = async (id) => {
    setSpinner(true)
    const req = {
      headers: { "apikey": API_KEY }
    }
    try {
      const Result = await Axios.post(`${urlApi}/sale/getbyid/${id}`, {
        "token": localStorage.getItem('token'),
      }, req);

      if (Result.data.status === true) {
        setSaleSelected(Result.data.sale)
        setSaleSelectedProducts(Result.data.products)
        setSpinner(false)
        return { status: true }
      }
      else {
        setSpinner(false)
        return { status: false, msg: Result.data.msg }
      }
    } catch (err) {
      setSpinner(false)
      return { status: false, msg: err.response.data.msg }
    }
  }

  const carregarCliente = async (id) => {
    setSpinner(true)
    const req = {
      headers: { "apikey": API_KEY }
    }
    try {
      const Result = await Axios.post(`${urlApi}/client/getbyid/${id}`, {
        "token": localStorage.getItem('token'),
      }, req);

      if (Result.data.status === true) {
        setClientSelected(Result.data.client)
        setSpinner(false)
        return { status: true }
      }
      else {
        setSpinner(false)
        return { status: false, msg: Result.data.msg }
      }
    } catch (err) {
      setSpinner(false)
      return { status: false, msg: err.response.data.msg }
    }
  }

  const carregarUsuario = async (id) => {
    setSpinner(true)
    const req = {
      headers: { "apikey": API_KEY }
    }
    try {
      const Result = await Axios.post(`${urlApi}/user/get/${id}`, {
        "token": localStorage.getItem('token'),
      }, req);

      if (Result.data.status === true) {
        setSpinner(false)
        setClientSelectedUser(Result.data.user)
        return { status: true, user: Result.data.user }
      }
      else {
        setSpinner(false)
        return { status: false, msg: Result.data.msg }
      }
    } catch (err) {
      setSpinner(false)
      return { status: false, msg: err.response.data.msg }
    }
  }

  const listarClientePorId = async (id) => {
    const req = {
      headers: { "apikey": API_KEY }
    }
    try {
      const Result = await Axios.post(`${urlApi}/client/getbyid/${id}`, {
        "token": localStorage.getItem('token')
      }, req);

      if (Result.data.status === true) {
        setSaleSelectedClient(Result.data.client)
        return { status: true, client: Result.data.client }
      }
      else {
        return { status: false, msg: Result.data.msg }
      }
    } catch (err) {
      return { status: false, msg: err.response.data.msg }
    }
  }

  const listarProdutoPorId = async (id) => {
    setLoading(true)
    const req = {
      headers: { "apikey": API_KEY }
    }
    try {
      const Result = await Axios.post(`${urlApi}/product/getbyid`, {
        "id": id, companyName: localStorage.getItem('companyName')
      }, req);

      if (Result.data.status === true) {
        setProductSelected(Result.data.product)

        setLoading(false)
        setCompanyColor(Result.data.color)
        setCompanyUrl(Result.data.url)
        setCompanyName(Result.data.companyName)
        setCompanyDescription(Result.data.description)
        return { status: true, link: Result.data.urlPdf }
      }
      else {
        setProductSelected('')

        setCompanyName(Result.data.companyName)
        setCompanyUrl(Result.data.url)
        setCompanyDescription(Result.data.description)

        setLoading(false)
        return { status: false, msg: Result.data.msg }
      }
    } catch (err) {
      if (err.response.data.url != '' && err.response.data.url != null && err.response.data.url != undefined) {
        setCompanyUrl(err.response.data.url)
      }

      if (err.response.data.companyName != '' && err.response.data.companyName != null && err.response.data.companyName != undefined) {
        setCompanyName(err.response.data.companyName)
      }

      if (err.response.data.color != '' && err.response.data.color != null && err.response.data.color != undefined) {
        setCompanyColor(err.response.data.color)
      }

      if (err.response.data.description != '' && err.response.data.description != null && err.response.data.description != undefined) {
        setCompanyDescription(err.response.data.description)
      }

      setProductSelected('')
      setLoading(false)
      setLoadMoreProducts(false)
      return { status: false, msg: err.response.data.msg }
    }
  }

  const listarProdutosPorNome = async (limit, offset, nome, verMais) => {
    setLoading(true)
    const req = {
      headers: { "apikey": API_KEY }
    }
    try {
      const Result = await Axios.post(`${urlApi}/product/getbyname`, {
        "limit": limit, "offset": offset, "companyName": nome
      }, req);

      if (Result.data.status === true) {
        if (verMais) {
          const arrNew = []
          products.length > 0 && arrNew.push(...products)
          arrNew.push(...Result.data.product)
          setProducts(arrNew)
        }
        else {
          setProducts(Result.data.product)
        }
        localStorage.setItem('companyName', Result.data.companyName)
        setLoading(false)
        setLoadMoreProducts(Result.data.product.length % limit == 0)
        setCompanyColor(Result.data.color)
        setCompanyUrl(Result.data.url)
        setCompanyName(Result.data.companyName)
        setCompanyDescription(Result.data.description)
        setTotalRegisters(Result.data.totalRegisters)
        return { status: true, isDeleted: Result.data.isDeleted }
      }
      else {
        setCompanyName(Result.data.companyName)
        setCompanyDescription(Result.data.description)
        setCompanyUrl(Result.data.url)
        setProducts('')
        setLoading(false)
        setLoadMoreProducts(false)
        return { status: false, msg: Result.data.msg, isDeleted: Result.data.isDeleted }
      }
    } catch (err) {
      if (err.response.data.url != '' && err.response.data.url != null && err.response.data.url != undefined) {
        setCompanyUrl(err.response.data.url)
      }

      if (err.response.data.companyName != '' && err.response.data.companyName != null && err.response.data.companyName != undefined) {
        setCompanyName(err.response.data.companyName)
      }

      if (err.response.data.color != '' && err.response.data.color != null && err.response.data.color != undefined) {
        setCompanyColor(err.response.data.color)
      }

      setProducts('')
      setLoading(false)
      setLoadMoreProducts(false)
      return { status: false, msg: err.response.data.msg, isDeleted: err.response.data.isDeleted }
    }
  }

  const listarClientePorToken = async () => {
    setLoading(true)
    const req = {
      headers: { "apikey": API_KEY }
    }
    try {
      const Result = await Axios.post(`${urlApi}/client/getbytoken`, {
        "token": localStorage.getItem('token')
      }, req);

      if (Result.data.status === true) {
        return { status: true, client: Result.data.client }
      }
      else {
        return { status: false, msg: Result.data.msg }
      }
    } catch (err) {
      return { status: false, msg: err.response.data.msg }
    }
  }


  const editarSenhaUsuario = async (email, newPassword) => {
    setSpinner(true)
    const req = {
      headers: { "apikey": API_KEY }
    }
    try {
      const Result = await Axios.post(`${urlApi}/user/edit`, {
        email: email, password: newPassword, editPassword: true
      }, req);

      if (Result.data.status === true) {
        setSpinner(false)
        return { status: true }
      }
      else {
        setSpinner(false)
        return { status: false, mensagem: Result.data.msg }
      }
    } catch (err) {
      setSpinner(false)
      return { mensagem: 'Erro: ' + err.response.data.msg }
    }
  }

  // const cadastrarProduto = async (product, fileFoto, filePdf) => {
  //   setSpinner(true)
  //   const req = {
  //     headers: {
  //       "apikey": API_KEY,
  //       'Content-Type': "multipart/form-data"
  //     }
  //   }
  //   try {
  //     const formData = new FormData();

  //     formData.append('token', localStorage.getItem('token'));
  //     formData.append('title', product.title);
  //     formData.append('description', product.description);
  //     formData.append('price', product.price);
  //     formData.append('duration', product.duration);
  //     formData.append('idsUser', product.idsUser);
  //     formData.append('comission', product.comission);
  //     formData.append('idsCompany', product.idsCompany);
  //     formData.append('clausulas', product.clausulas);
  //     formData.append('files', fileFoto); 
  //     formData.append('files', filePdf); 


  //     const Result = await Axios.post(`${urlApi}/product/add`, formData, req);

  const editarProduto = async (id, product, fileFoto, filePdf) => {
    setSpinner(true)
    const req = {
      headers: {
        "apikey": API_KEY,
        'Content-Type': "multipart/form-data"
      }
    }
    try {

      const formData = new FormData();

      formData.append('token', localStorage.getItem('token'));
      formData.append('title', product.title);
      formData.append('description', product.description);
      formData.append('price', product.price);
      formData.append('duration', product.duration);
      formData.append('idsUser', product.idsUser);
      formData.append('comission', product.comission);
      formData.append('idsCompany', product.idsCompany);
      formData.append('clausulas', product.clausulas);
      formData.append('files', fileFoto);
      formData.append('files', filePdf);

      const Result = await Axios.post(`${urlApi}/product/edit/${id}`, formData, req);

      if (Result.data.status === true) {
        setSpinner(false)
        return { status: true }
      }
      else {
        setSpinner(false)
        return { status: false, msg: Result.data.msg }
      }
    } catch (err) {
      setSpinner(false)
      return { status: false, msg: err.response.data.msg }
    }
  }

  const excluirProduto = async (id) => {
    setSpinner(true)
    const req = {
      headers: { "apikey": API_KEY }
    }
    try {
      const Result = await Axios.post(`${urlApi}/product/delete/${id}`, {
        "token": localStorage.getItem('token'),
      }, req);

      if (Result.data.status === true) {
        setSpinner(false)
        return { status: true }
      }
      else {
        setSpinner(false)
        return { status: false, msg: Result.data.msg }
      }
    } catch (err) {
      setSpinner(false)
      return { status: false, msg: err.response.data.msg }
    }
  }

  const ativarProduto = async (id) => {
    setSpinner(true)
    const req = {
      headers: { "apikey": API_KEY }
    }
    try {
      const Result = await Axios.post(`${urlApi}/product/activate/${id}`, {
        "token": localStorage.getItem('token'),
      }, req);

      if (Result.data.status === true) {
        setSpinner(false)
        return { status: true }
      }
      else {
        setSpinner(false)
        return { status: false, msg: Result.data.msg }
      }
    } catch (err) {
      setSpinner(false)
      return { status: false, msg: err.response.data.msg }
    }
  }

  const excluirUsuario = async (id) => {
    setSpinner(true)
    const req = {
      headers: { "apikey": API_KEY }
    }
    try {
      const Result = await Axios.post(`${urlApi}/user/delete/${id}`, req);

      if (Result.data.status === true) {
        setSpinner(false)
        return { status: true }
      }
      else {
        setSpinner(false)
        return { status: false, msg: Result.data.msg }
      }
    }
    catch (err) {
      setSpinner(false)
      return { status: false, msg: err.response.data.msg }
    }
  }

  const ativarUsuario = async (id) => {
    setSpinner(true)
    const req = {
      headers: { "apikey": API_KEY }
    }
    try {
      const Result = await Axios.post(`${urlApi}/user/activate/${id}`, req);

      if (Result.data.status === true) {
        setSpinner(false)
        return { status: true }
      }
      else {
        setSpinner(false)
        return { status: false, msg: Result.data.msg }
      }
    } catch (err) {
      setSpinner(false)
      return { status: false, msg: err.response.data.msg }
    }
  }

  const excluirEmpresa = async (id) => {
    setSpinner(true)
    const req = {
      headers: { "apikey": API_KEY }
    }
    try {
      const Result = await Axios.post(`${urlApi}/company/delete/${id}`, req);

      if (Result.data.status === true) {
        setSpinner(false)
        return { status: true }
      }
      else {
        setSpinner(false)
        return { status: false, msg: Result.data.msg }
      }
    }
    catch (err) {
      setSpinner(false)
      return { status: false, msg: err.response.data.msg }
    }
  }

  const ativarEmpresa = async (id) => {
    setSpinner(true)
    const req = {
      headers: { "apikey": API_KEY }
    }
    try {
      const Result = await Axios.post(`${urlApi}/company/activate/${id}`, req);

      if (Result.data.status === true) {
        setSpinner(false)
        return { status: true }
      }
      else {
        setSpinner(false)
        return { status: false, msg: Result.data.msg }
      }
    } catch (err) {
      setSpinner(false)
      return { status: false, msg: err.response.data.msg }
    }
  }

  const excluirVenda = async (id) => {
    setSpinner(true)
    const req = {
      headers: { "apikey": API_KEY }
    }
    try {
      const Result = await Axios.post(`${urlApi}/sale/delete/${id}`, {
        "token": localStorage.getItem('token')
      }, req);

      if (Result.data.status === true) {
        setSpinner(false)
        return { status: true }
      }
      else {
        setSpinner(false)
        return { status: false, msg: Result.data.msg }
      }
    } catch (err) {
      setSpinner(false)
      return { status: false, msg: err.response.data.msg }
    }
  }

  const editarPagamentoPlano = async (idProduct, idSale, creditCard, creditCardHolderInfo) => {
    setSpinner(true)
    const req = {
      headers: { "apikey": API_KEY, },
    }
    try {
      const Result = await Axios.post(`${urlApi}/sale/editproduct`, {
        "token": localStorage.getItem('token'),
        idProduct: idProduct, idSale: idSale, creditCard: creditCard, creditCardHolderInfo
      }, req);

      if (Result.data.status === true) {
        setSpinner(false)
        return { status: true }
      }
      else {
        setSpinner(false)
        return { status: false, msg: Result.data.msg }
      }
    } catch (err) {
      setSpinner(false)
      return { status: false, msg: err.response.data.msg }
    }
  }



  const verificaPermissao = async () => {
    if (localStorage.getItem('permission') == 'Admin') {
      setIsAdmin(true)
      setIsVendedor(false)
      setIsComum(false)
      setSigned(true)
    }
    else if (localStorage.getItem('permission') == 'Vendedor') {
      setIsAdmin(false)
      setIsVendedor(true)
      setIsComum(false)
      setSigned(true)
    }
    else if (localStorage.getItem('permission') == 'Comum') {
      setIsAdmin(false)
      setIsVendedor(false)
      setIsComum(true)
      setSigned(true)
    }
    else {
      setIsAdmin(false)
      setIsVendedor(false)
      setIsComum(false)
      setSigned(true)
    }
  }

  const buscarCep = async (cep) => {
    try {
      const { data } = await Axios.get(`https://viacep.com.br/ws/${cep}/json/`)
      if (data.erro === undefined) {
        return { status: true, content: data }
      }
      else {
        return { status: false, msg: 'CEP não encontrado' }
      }
    }
    catch (err) {
      return { status: false, msg: 'Erro: ' + err }
    }
  }

  const validarCpf = (cpf) => {
    var soma = 0;
    var resto;
    var inputCPF = cpf.replaceAll(`-`, ``).replaceAll(`.`, ``).replaceAll(`_`, ``)

    if (inputCPF == '00000000000') return { status: false };

    for (let i = 1; i <= 9; i++) soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;

    if ((resto == 10) || (resto == 11)) resto = 0;
    if (resto != parseInt(inputCPF.substring(9, 10))) return { status: false };

    soma = 0;
    for (let i = 1; i <= 10; i++) soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;

    if ((resto == 10) || (resto == 11)) resto = 0;
    if (resto != parseInt(inputCPF.substring(10, 11))) return { status: false };

    return { status: true };
  }

  function validarCnpj(cnpjBruto) {

    const cnpj = cnpjBruto.replace(/[^\d]+/g, '');

    if (cnpj == '') return { status: false };

    if (cnpj.length != 14)
      return { status: false };

    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" ||
      cnpj == "11111111111111" ||
      cnpj == "22222222222222" ||
      cnpj == "33333333333333" ||
      cnpj == "44444444444444" ||
      cnpj == "55555555555555" ||
      cnpj == "66666666666666" ||
      cnpj == "77777777777777" ||
      cnpj == "88888888888888" ||
      cnpj == "99999999999999")
      return { status: false };

    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
      return { status: false };

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
      return { status: false };

    return { status: true };

  }

  const limparStates = () => {
    try {
      setNumeroVendas('')
      setNumeroClientes('')
      setNumeroUsuarios('')
      setNumeroProdutos('')

      setValorVendas('')
      setValorComissao('')

      setEmail('')
      setPassword('')
      setSigned(false)
      setRemainSigned(false)
      setSidebarOpen(true)

      setIsAdmin(false)
      setIsVendedor(false)
      setIsComum(false)

      setModalIsOpen(false)
      setModalIsOpenAux(false)

      setProductSelected('')
      setProductSelectedUser('')
      setProductSelectedCompany('')
      setClientSelected('')
      setClientSelectedUser('')
      setSaleSelected('')
      setSaleSelectedProducts('')
      setSaleSelectedClient('')

      setProducts('')
      setClients('')
      setUsers('')
      setSales('')
      setCompanies('')

      setLoading(false)
      setLoadMoreProducts(false)
      setLoadMoreClients(false)
      setLoadMoreSales(false)
      setLoadMoreUsers(false)

      setTotalRegisters(0)
      setSpinner(false)
    }
    catch (err) {

    }
  }


  const forgotPassword = async (email) => {
    setSpinner(true)
    const req = {
      headers: { "apikey": API_KEY }
    }
    try {
      const Result = await Axios.post(`${urlApi}/user/forgot/password`, { email }, req);
      if (Result.data.status === true) {
        setSpinner(false)
        return { status: true }
      }
      else {
        setSpinner(false)
        return { status: false, msg: Result.data.msg }
      }
    } catch (err) {
      setSpinner(false)
      return { status: false, msg: err.response.data.msg }
    }
  }

  const changePassword = async (token, senha) => {
    setSpinner(true)
    const req = {
      headers: { "apikey": API_KEY }
    }
    try {
      const Result = await Axios.post(`${urlApi}/user/change/password`, { token: token, password: senha }, req);
      if (Result.data.status === true) {
        setSpinner(false)
        return { status: true }
      }
      else {
        setSpinner(false)
        return { status: false, msg: Result.data.msg }
      }
    } catch (err) {
      setSpinner(false)
      return { status: false, msg: err.response.data.msg }
    }
  }

  const enviarEmail = async (email) => {
    setSpinner(true)
    const req = {
      headers: { "apikey": API_KEY }
    }
    try {
      const Result = await Axios.post(`${urlApi}/client/email`, { email }, req);
      if (Result.data.status === true) {
        setSpinner(false)
        return { status: true }
      }
      else {
        setSpinner(false)
        return { status: false, msg: Result.data.msg }
      }
    } catch (err) {
      setSpinner(false)
      return { status: false, msg: err.response.data.msg }
    }
  }

  const getPath = async (window) => {
    const path = window.location.origin
    return path
  }


  return (
    <AuthContext.Provider
      value={{
        states,
        setters,
        login,
        logout,
        checkLogin,
        listarProdutos,
        listarClientes,
        listarUsuarios,
        listarVendas,
        listarEmpresas,
        cadastrarUsuario,
        cadastrarProduto,
        carregarProduto,
        editarProduto,
        excluirProduto,
        cadastrarVenda,
        ativarProduto,
        carregarVenda,
        listarClientePorId,
        carregarCliente,
        carregarUsuario,
        buscarCep,
        validarCpf,
        validarCnpj,
        cadastrarCliente,
        editarSenhaUsuario,
        excluirUsuario,
        ativarUsuario,
        verificaPermissao,
        listarEstatisticasHome,
        listarEstatisticasClientes,
        listarEstatisticasVendas,
        excluirEmpresa,
        ativarEmpresa,
        cadastrarEmpresa,
        editarEmpresa,
        listarProdutosPorNome,
        editarUsuario,
        forgotPassword,
        changePassword,
        listarClientePorToken,
        pagarComissao,
        excluirVenda,
        editarPagamentoPlano,
        listarProdutoPorId,
        getPath,
        enviarEmail
      }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider;
