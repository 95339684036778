import React from 'react';
import { SketchPicker } from 'react-color';

export default function ColorPicker(props) {

  const handleChangeComplete = (color) => {
    props.setColor(color.hex);
  };

  return (
    <div>
      <SketchPicker
        color={props.color == '' || props.color == null || props.color == undefined ? '#D81E1E': props.color}
        onChangeComplete={handleChangeComplete}
      />
    </div>
  );
}
