import { Fragment, useState,useEffect,useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { AuthContext } from "../../../../Context/index"
import { toast } from 'react-toastify';
import { useContext } from "react"


export default function ModalEmail(props) {  
  const cancelButtonRef = useRef(null)

  const {enviarEmail} = useContext(AuthContext)
  const [titulo,setTitulo]= useState('')
  const [corpo, setCorpo] = useState('')

  const[errorTitulo,setErrorTitulo] = useState(false)
  const[errorCorpo, setErrorCorpo] = useState(false)

  useEffect(() => {
    if(props.states.modalIsOpenAux === false)
    {
        limparStatesModalEmail()
    }
  }, [props.states.modalIsOpenAux]);

  const enviarFormOnClick = async (e) =>
  {
    try{
      e.preventDefault()
      const email = {titulo: titulo,  corpo: corpo, nome: props.states.nomeEmail, email:props.states.enderecoEmail}
      const retorno = validaStatesModalEmail(email)

      if(retorno)
      {
        const result = await enviarEmail(email)
        if(result.status == true)
        {
          toast.success('Email enviado com sucesso.', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            props.setters.setModalIsOpenAux(false)
        }
        else
        {
          toast.error(result.msg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        }
      }
      else
      {
        toast.error('Email não pôde ser enviado!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      }
      
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }

  const validaStatesModalEmail = (email) =>
  {
    try
    {
      let resultado = true
      if(email.titulo == '' || email.titulo == undefined || email.titulo == null)
      {
        resultado=false
        setErrorTitulo(true)
      }
      else 
      {
        setErrorTitulo(false)
      }

      if(email.corpo == '' || email.corpo == undefined || email.corpo == null)
      {
        resultado=false
        setErrorCorpo(true)
      }
      else
      {
        setErrorCorpo(false)
      }


      return resultado
    }
    catch(err)
    {
      return false
    }
  }

  const limparStatesModalEmail = async () =>
  {
      setTitulo('') 
      setCorpo('')

      setErrorTitulo(false)
      setErrorCorpo(false)
  }
  
  return (
    <Transition.Root show={props.states.modalIsOpenAux} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => props.setters.setModalIsOpenAux(false)} >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <form className="space-y-8 divide-y divide-gray-200" onSubmit={e => enviarFormOnClick(e)}>
              {/* onSubmit={()=> cadastrarAssociado()} */}
                      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                        <div className="space-y-6 sm:space-y-5">
                            <div className='flex-col'>
                              <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Email</h3>
                              <p className="flex mt-1 text-sm text-gray-500 justify-center">
                                Dados para envio do email
                              </p>
                            </div>

                            <div className="space-y-6 sm:space-y-5">
                              {/* 1 LINHA */}
                              <div className="sm:grid sm:grid-cols-2 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                
                                <div className="mt-2 sm:col-span-full sm:mt-0">
                                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 p-1">
                                  Título
                                </label>
                                  <div className="flex max-w-lg rounded-md shadow-sm">
                                    <input
                                      type="text"
                                      name="title"
                                      id="title"
                                      autoComplete="title"
                                      placeholder='Digite o título'
                                      value={titulo}
                                      onChange={e => setTitulo(e.target.value)}
                                      className="pl-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                  </div>
                                  {
                                    errorTitulo &&
                                    <p class="mt-2 ml-2 text-pink-600 text-sm">                     
                                        É obrigatório o preenchimento do título.
                                    </p>
                                  }
                                </div>

                                <div className="mt-2 sm:col-span-full sm:mt-0">
                                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                    Corpo do email
                                </label>
                                <div className="mt-2">
                                <textarea
                                    rows={14}
                                    name="body"
                                    id="body"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    placeholder='Digite o corpo do email'
                                    value={corpo}
                                    onChange={e => setCorpo(e.target.value)}
                                    defaultValue={''}
                                    />
                                    {
                                        errorCorpo === true && 
                                        <p class="mt-2 ml-1 text-pink-600 text-sm">
                                            É obrigatório o preenchimento do corpo do email.
                                        </p>
                                    }
                                </div>
                                </div>

                              </div>
                            </div>
                        </div>
                      </div>

                      <div className="pt-5" >
                        <div className="flex justify-end gap-x-3">
                          <button
                            type="button"
                            className="rounded-md bg-white py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            onClick={()=>props.setters.setModalIsOpenAux(false)}
                          >
                            Cancelar
                          </button>
                          <button
                            type="submit"
                            className="inline-flex justify-center rounded-md bg-blue-500 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            // onClick={()=>props.setters.setModalIsOpen(true)}
                          >
                            Enviar
                          </button>
                        </div>
                      </div>
              </form>
                
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
