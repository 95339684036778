import { useNavigate } from 'react-router-dom'
import Footer from '../../Components/Footer/footer'
import Navbar from '../../Components/Navbar/navbar'

export default function TermosGerais() {

  const navigate = useNavigate()

  return (
    <>
    <Navbar/>

    <div className="bg-white">
        <div className="w-full pb-8 pt-8 px:64">
            <div className="flex items-center justify-between mx-auto flex w-full max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
                <div className="mt-2 sm:col-span-1 sm:mt-0">
                    <div className="flex max-w rounded-md">
                    <div className="flex-1 flex bg-white justify-end items-center">
                        <button className="text-sm font-semibold leading-6 text-blue-500 hover:text-blue-600" onClick={()=> navigate(-1)}>
                                Voltar
                        </button>
                    </div> 

                    </div>
                </div>
            </div>
        </div>

        <div className="mx-auto max-w-7xl pb-8 pt-4 mb-10 border rounded-md shadow shadow-md ">
            <div className="flex min-h-full flex-1 flex-col justify-center sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    {/* <img
                        className="mx-auto h-10 w-auto"
                        src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                        alt="Your Company"
                    /> */}
                    <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Termos e Condições Gerais
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[720px] px-4 sm:px-0">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla rhoncus semper maximus. In elementum feugiat ipsum. Praesent at urna ultricies, accumsan lorem in, scelerisque arcu. Aenean aliquet mauris tellus, quis ornare enim iaculis ut. Nullam porttitor sem non erat elementum, at consequat lacus vestibulum. Morbi tincidunt turpis vel turpis bibendum efficitur. Sed pretium nisi ipsum, ac laoreet urna commodo eget. Morbi mollis metus arcu. Sed lobortis, ipsum sed venenatis laoreet, quam orci gravida est, eu fringilla mi purus a eros. Vestibulum eu ultrices tortor, eget feugiat diam. Fusce condimentum lacus et enim ornare, nec blandit leo ultricies. Ut sit amet vehicula lectus.</p>
                    <br/>
                    <p>Suspendisse ultricies eu quam ac consectetur. Phasellus eleifend tortor ut facilisis accumsan. In nec purus at metus iaculis tincidunt vitae ac nulla. Integer bibendum diam erat, in tristique sapien aliquam eget. Aenean maximus ex ac bibendum sollicitudin. Quisque tempor risus non congue pellentesque. Sed luctus ac ligula at pulvinar. Mauris at nunc neque. Sed sagittis pellentesque dui nec varius. Donec eu venenatis sapien. Mauris suscipit est at dapibus pulvinar. Sed justo elit, eleifend quis odio tincidunt, ultrices gravida turpis. Maecenas facilisis risus sed cursus lobortis. Mauris eget est elit. Vestibulum non porttitor odio.</p>
                    <br/>
                    <p>Donec id finibus augue. Curabitur ac est sit amet eros sodales elementum quis sit amet dui. In iaculis lectus eget ligula porta dignissim. Mauris volutpat posuere cursus. Nam auctor ac diam at congue. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus lacinia dapibus libero, et blandit tortor sagittis id. Phasellus sagittis odio purus, vitae efficitur nisl laoreet accumsan. Nam tempor erat congue mauris porta, quis efficitur nisi feugiat. Maecenas eget tortor ac velit vehicula sagittis sed ac nisi. Cras lorem orci, dapibus et scelerisque nec, ultricies sodales purus. Curabitur eu urna sem. Morbi justo purus, mattis non tincidunt eget, congue eu sem.</p>
                    <br/>
                    <p>Donec id finibus augue. Curabitur ac est sit amet eros sodales elementum quis sit amet dui. In iaculis lectus eget ligula porta dignissim. Mauris volutpat posuere cursus. Nam auctor ac diam at congue. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus lacinia dapibus libero, et blandit tortor sagittis id. Phasellus sagittis odio purus, vitae efficitur nisl laoreet accumsan. Nam tempor erat congue mauris porta, quis efficitur nisi feugiat. Maecenas eget tortor ac velit vehicula sagittis sed ac nisi. Cras lorem orci, dapibus et scelerisque nec, ultricies sodales purus. Curabitur eu urna sem. Morbi justo purus, mattis non tincidunt eget, congue eu sem.</p>
                    <br/>
                    <p>Donec id finibus augue. Curabitur ac est sit amet eros sodales elementum quis sit amet dui. In iaculis lectus eget ligula porta dignissim. Mauris volutpat posuere cursus. Nam auctor ac diam at congue. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus lacinia dapibus libero, et blandit tortor sagittis id. Phasellus sagittis odio purus, vitae efficitur nisl laoreet accumsan. Nam tempor erat congue mauris porta, quis efficitur nisi feugiat. Maecenas eget tortor ac velit vehicula sagittis sed ac nisi. Cras lorem orci, dapibus et scelerisque nec, ultricies sodales purus. Curabitur eu urna sem. Morbi justo purus, mattis non tincidunt eget, congue eu sem.</p>
                    <br/>
                    <p>Donec id finibus augue. Curabitur ac est sit amet eros sodales elementum quis sit amet dui. In iaculis lectus eget ligula porta dignissim. Mauris volutpat posuere cursus. Nam auctor ac diam at congue. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus lacinia dapibus libero, et blandit tortor sagittis id. Phasellus sagittis odio purus, vitae efficitur nisl laoreet accumsan. Nam tempor erat congue mauris porta, quis efficitur nisi feugiat. Maecenas eget tortor ac velit vehicula sagittis sed ac nisi. Cras lorem orci, dapibus et scelerisque nec, ultricies sodales purus. Curabitur eu urna sem. Morbi justo purus, mattis non tincidunt eget, congue eu sem.</p>
                    <br/>
                    <p>Donec id finibus augue. Curabitur ac est sit amet eros sodales elementum quis sit amet dui. In iaculis lectus eget ligula porta dignissim. Mauris volutpat posuere cursus. Nam auctor ac diam at congue. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus lacinia dapibus libero, et blandit tortor sagittis id. Phasellus sagittis odio purus, vitae efficitur nisl laoreet accumsan. Nam tempor erat congue mauris porta, quis efficitur nisi feugiat. Maecenas eget tortor ac velit vehicula sagittis sed ac nisi. Cras lorem orci, dapibus et scelerisque nec, ultricies sodales purus. Curabitur eu urna sem. Morbi justo purus, mattis non tincidunt eget, congue eu sem.</p>
                    <br/>
                    <p>Donec id finibus augue. Curabitur ac est sit amet eros sodales elementum quis sit amet dui. In iaculis lectus eget ligula porta dignissim. Mauris volutpat posuere cursus. Nam auctor ac diam at congue. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus lacinia dapibus libero, et blandit tortor sagittis id. Phasellus sagittis odio purus, vitae efficitur nisl laoreet accumsan. Nam tempor erat congue mauris porta, quis efficitur nisi feugiat. Maecenas eget tortor ac velit vehicula sagittis sed ac nisi. Cras lorem orci, dapibus et scelerisque nec, ultricies sodales purus. Curabitur eu urna sem. Morbi justo purus, mattis non tincidunt eget, congue eu sem.</p>
                    <br/>
                    <p>Donec id finibus augue. Curabitur ac est sit amet eros sodales elementum quis sit amet dui. In iaculis lectus eget ligula porta dignissim. Mauris volutpat posuere cursus. Nam auctor ac diam at congue. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus lacinia dapibus libero, et blandit tortor sagittis id. Phasellus sagittis odio purus, vitae efficitur nisl laoreet accumsan. Nam tempor erat congue mauris porta, quis efficitur nisi feugiat. Maecenas eget tortor ac velit vehicula sagittis sed ac nisi. Cras lorem orci, dapibus et scelerisque nec, ultricies sodales purus. Curabitur eu urna sem. Morbi justo purus, mattis non tincidunt eget, congue eu sem.</p>
                    <br/>
                    <p>Donec id finibus augue. Curabitur ac est sit amet eros sodales elementum quis sit amet dui. In iaculis lectus eget ligula porta dignissim. Mauris volutpat posuere cursus. Nam auctor ac diam at congue. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus lacinia dapibus libero, et blandit tortor sagittis id. Phasellus sagittis odio purus, vitae efficitur nisl laoreet accumsan. Nam tempor erat congue mauris porta, quis efficitur nisi feugiat. Maecenas eget tortor ac velit vehicula sagittis sed ac nisi. Cras lorem orci, dapibus et scelerisque nec, ultricies sodales purus. Curabitur eu urna sem. Morbi justo purus, mattis non tincidunt eget, congue eu sem.</p>
                    <br/>
                    <p>Donec id finibus augue. Curabitur ac est sit amet eros sodales elementum quis sit amet dui. In iaculis lectus eget ligula porta dignissim. Mauris volutpat posuere cursus. Nam auctor ac diam at congue. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus lacinia dapibus libero, et blandit tortor sagittis id. Phasellus sagittis odio purus, vitae efficitur nisl laoreet accumsan. Nam tempor erat congue mauris porta, quis efficitur nisi feugiat. Maecenas eget tortor ac velit vehicula sagittis sed ac nisi. Cras lorem orci, dapibus et scelerisque nec, ultricies sodales purus. Curabitur eu urna sem. Morbi justo purus, mattis non tincidunt eget, congue eu sem.</p>
                    <br/>
                    <p>Donec id finibus augue. Curabitur ac est sit amet eros sodales elementum quis sit amet dui. In iaculis lectus eget ligula porta dignissim. Mauris volutpat posuere cursus. Nam auctor ac diam at congue. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus lacinia dapibus libero, et blandit tortor sagittis id. Phasellus sagittis odio purus, vitae efficitur nisl laoreet accumsan. Nam tempor erat congue mauris porta, quis efficitur nisi feugiat. Maecenas eget tortor ac velit vehicula sagittis sed ac nisi. Cras lorem orci, dapibus et scelerisque nec, ultricies sodales purus. Curabitur eu urna sem. Morbi justo purus, mattis non tincidunt eget, congue eu sem.</p>
                </div>
            </div>
        </div>
    </div>

    <Footer/>
    </>
  )
}

  