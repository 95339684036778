import { Route, Routes} from 'react-router-dom'
import Login from '../Views/Login'
import Home from '../Views/Home'
import Produtos from '../Views/Produtos/index'
import ProdutosExterno from '../Views/Produtos/Externo/index'
import Clientes from '../Views/Clientes'
import CadastroCliente from '../Views/Clientes/Cadastrar'
import Usuarios from '../Views/Usuarios'
import Vendas from '../Views/Vendas'
import Empresas from '../Views/Empresas'
import CadastroVenda from '../Views/Vendas/Cadastrar'
import RouterWrapper from './auth'
import Forgot from '../Views/Forgot'
import ChangePassword from '../Views/ChangePassword'
import Produto from '../Views/Produtos/Individual/index'
import ProdutoExterno from '../Views/Produtos/IndividualExterno/index'
import TermosGerais from '../Views/TermosGerais'


const NotFound = () => {
    return(
        <div class="bg-gray-200 w-full px-16 md:px-0 h-screen flex items-center justify-center">
        <div class="bg-white border border-gray-200 flex flex-col items-center justify-center px-4 md:px-8 lg:px-24 py-8 rounded-lg shadow-2xl">
            <p class="text-6xl md:text-7xl lg:text-9xl font-bold tracking-wider text-gray-300">404</p>
            <p class="text-2xl md:text-3xl lg:text-5xl font-bold tracking-wider text-gray-500 mt-4">Página não encontrada</p>
            <p class="text-gray-500 mt-4 pb-4 border-b-2 text-center">A página que você procura não pôde ser encontrada</p>
            <a href="/home" class="flex items-center space-x-2 bg-blue-600 hover:bg-blue-700 text-gray-100 px-4 py-2 mt-6 rounded transition duration-150" title="Return Home">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd"></path>
                </svg>
                <span>Voltar para Home</span>
            </a>
        </div>
    </div>
    )
}

const Router = () => {

    return(
            <Routes>
                <Route path="/" element={<RouterWrapper component={Login} isPrivate={false} />} />
                <Route path="/termosgerais" element={<RouterWrapper component={TermosGerais} isPrivate={false} />} />
                <Route path="/home" element={<RouterWrapper component={Home} isPrivate={true} />} />
                <Route path="/clientes" element={<RouterWrapper component={Clientes} isPrivate={true} />} />
                <Route path="/clientes/cadastro" element={<RouterWrapper component={CadastroCliente} isPrivate={false} />} />
                <Route path="/usuarios" element={<RouterWrapper component={Usuarios} isPrivate={true} />} />
                <Route path="/vendas" element={<RouterWrapper component={Vendas} isPrivate={true} />} />
                <Route path="/vendas/cadastro" element={<RouterWrapper component={CadastroVenda} isPrivate={true} />} />
                <Route path="/produto/:id" element={<RouterWrapper component={Produto} isPrivate={false} />} />
                <Route path="/externo/produto/:id" element={<RouterWrapper component={ProdutoExterno} isPrivate={false} />} />
                <Route path="/produtos" element={<RouterWrapper component={Produtos} isPrivate={false} />} />
                <Route path="/produtos/*" element={<RouterWrapper component={ProdutosExterno} isPrivate={false} />} />
                <Route path="/empresas" element={<RouterWrapper component={Empresas} isPrivate={true} />} />
                <Route path="/forgot" element={<RouterWrapper component={Forgot} isPrivate={false} />} />
                <Route path="/change/:token" element={<RouterWrapper component={ChangePassword} isPrivate={false} />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
    )
}

export default Router