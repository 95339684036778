import { useState ,useContext,useEffect, useRef } from 'react'
import { AuthContext} from "../../Context"
import ModalCompany from '../../Components/Modal/Company'
import Navbar from '../../Components/Navbar/navbar'
import { toast } from 'react-toastify';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { LockOpenIcon, LockClosedIcon, MagnifyingGlassIcon} from '@heroicons/react/24/outline'

import Footer from '../../Components/Footer/footer'
import Error from '../../Components/EmptyGrid'
import Spinner from '../../Components/Spinner'

import SpinnerOverlay from '../../Components/SpinnerOverlay';

const statuses = {
  0: 'text-green-700 bg-green-50 ring-green-600/20 ',
  1: 'text-red-700 bg-red-50 ring-red-600/10 ',
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Empresas() {
  const {states, setters, listarEmpresas, ativarEmpresa, excluirEmpresa, verificaPermissao} = useContext(AuthContext)
  const [companySelected, setCompanySelected] = useState('')
  const [searchType, setSearchType] = useState('')
  const [offset, setOffset] = useState(0)
  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) {
      listarEmpresasAsync()
    } else {
      mounted.current = true;
    }
  }, [states.isAdmin, states.isVendedor, states.isComum, offset]);

  useEffect(()=>{
    verificarPermissaoAsync()
    listarEmpresasAsync()
  },[])

  const verificarPermissaoAsync = async() =>
  {
    try{
      await verificaPermissao()
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined, 
        theme: "light",
        });
    }
  }

  const listarEmpresasAsync = async() => {
    try{
        await listarEmpresas(10, offset, '')
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }

  const activateCompanyOnClick = async (id) => {
    try{
      const result = await ativarEmpresa(id)
      if(result.status == true)
      {
        await listarEmpresasAsync()
        toast.success('Empresa ativada com sucesso!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
             
      }
      else
      {
          toast.error(result.msg, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
      }
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
    
  }

  const deleteCompanyOnClick = async (id) => {
  try{
      const result = await excluirEmpresa(id)
      if(result.status == true)
      {
        await listarEmpresasAsync()
        toast.success('Empresa inativada com sucesso!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
      }
      else
      {
          toast.error(result.msg, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
      }
    }
    catch(err)
    {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  }

  const pesquisaEmpresa = async () => {
    try{
        const cnpj = searchType.replace(/[^\d]+/g,'');
        const result = await listarEmpresas(10, offset, cnpj)
        if(result.status == false)
        {
          toast.error(result.msg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        }
      }
      catch(err)
      {
        toast.error(err.response.data.msg, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      }
  }

  const viewCompanyOnClick = (company) => {
    setters.setModalIsOpen(true)
    setCompanySelected(company)
  }

  return (
    <>
     <Navbar/>

      <main>

        <div className="pt-6 xl:space-y-20">
          {/* Recent activity table */}
          <div className="">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h1 className="text-base font-semibold leading-6 text-gray-900">Empresas do sistema</h1>
              <h1 className="mt-4 text-sm font-semibold leading-3 text-gray-500">Pesquisar por CNPJ</h1>
              <div className="sm:grid sm:grid-cols-2 sm:items-start sm:gap-4 mt-3">
                  <div className="sm:col-span-1 sm:mt-0">
                    <div className="flex max-w rounded-md">

                    <form className="flex md:ml-0">
                      <div className="relative w-full text-gray-400 focus-within:text-gray-600 border border-gray-300 rounded-md">
                        <div className="pointer-events-none absolute inset-y-0 left-0 pl-2 flex items-center rounded-md">
                          <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                        </div>
                        <input
                          id="search-field"
                          name="search-field"
                          type="search"
                          className="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 focus:border-transparent focus:outline-none focus:ring-0 focus:placeholder:text-gray-400 sm:text-sm rounded-md"
                          placeholder='Digite ..'
                          maxLength={18}
                          onChange={e => setSearchType(e.target.value)}
                          onBlur={()=>pesquisaEmpresa()}
                          value={searchType}
                        />
                      </div>
                    </form>
                    </div>
                  </div>

                  <div className="mt-2 sm:col-span-1 sm:mt-0">
                    <div className="flex max-w rounded-md">
                      
                      <div className="flex-1 flex bg-white pl-2 justify-end items-center">
                        <button className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                          onClick={()=>viewCompanyOnClick('')}  >
                          Adicionar empresa
                        </button>
                      </div> 

                    </div>
                  </div>
                  
              </div>
              {states.loading == true?
                  <Spinner/>
              :
              states.companies.length !== 0  ?
                <div className='flex flex-1 flex-col'>
                  <div className=''>
                    <div className="py-2 mt-6 flow-root">
                      <div className="-my-4 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg"> 
                              <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Status
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Nome
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        CNPJ
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Data Criação
                                    </th>

                                    <th scope="col" className="text-left text-sm font-semibold text-gray-900">
                                        <span className="sr-only">Verificar</span>
                                    </th>
                                  
                                    <th scope="col" className="">
                                        <span className="sr-only">Visualizar</span>
                                    </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                  {states.companies.map((company) => (
                                    <tr key={company.ID} className="hover:bg-gray-50">
                                        <td className={classNames(
                                            statuses[company.IsDeleted],
                                            'ml-1 inline-flex items-center rounded-full px-3 py-1.5 mt-3 text-xs font-medium capitalize'
                                            )} >
                                              {company.IsDeleted == 0 ? 'Ativo':'Inativo'}
                                          </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{company.Nome}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                          {company.CNPJ.slice(0, 2) + '.' + company.CNPJ.slice(2, 5) + '.' + company.CNPJ.slice(5, 8) + '/' + 
                                               company.CNPJ.slice(8, 12)+ '-' + company.CNPJ.slice(12, 14)}</td>
                                        
                                        <td className='whitespace-nowrap px-3  text-sm text-gray-500'>
                                            {company.CreatedAt.replace('T', ' ').replace('.000Z', '')}
                                        </td>
                                        
                                        <td className="relative whitespace-nowrap text-right text-sm font-medium sm:pr-6">

                                        <button  className="hidden sm:inline p-2">
                                            <MagnifyingGlassIcon className="w-3.5" aria-hidden="true" onClick={()=>viewCompanyOnClick(company)}/>  
                                        </button>
                                        {/* {company.IsDeleted ? 
                                        <button  className="hidden sm:inline p-2">
                                            <LockOpenIcon className="w-3.5" aria-hidden="true" onClick={()=>activateCompanyOnClick(company.ID)}/>  
                                        </button>
                                        :
                                        <button  className="hidden sm:inline p-2">
                                            <LockClosedIcon className="w-3.5" aria-hidden="true" onClick={()=>deleteCompanyOnClick(company.ID)}/>  
                                        </button>
                                        } */}
                                        </td>
                                    </tr>
                                    ))
                                  }
                                </tbody>
                              </table>
                          </div>
                          </div>
                      </div>

                      <nav
                        className="flex items-center justify-between border-t border-gray-200 bg-white py-6"
                        aria-label="Pagination"
                      >
                        <div className="hidden sm:block">
                          <p className="text-sm text-gray-700 flex-1 ">
                            Mostrando <span className="font-medium">
                              {(offset*10)+1}
                            </span> a <span className="font-medium">
                            {states.loadMoreCompanies
                            ?
                            (offset+1)*10
                            :
                            (offset*10)+states.companies.length%10
                           }
                            
                              </span> de{' '}
                            <span className="font-medium">
                              {states.totalRegisters !== '' && states.totalRegisters}
                              </span> Empresas
                          </p>
                        </div>
                        <div className="flex flex-1 justify-between sm:justify-end">
                          {/* {offset > 0 && */}
                            <button
                              disabled = {!(offset > 0)}
                              type="button"
                              className="relative inline-flex items-center rounded-l-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                              onClick={()=> setOffset(offset-1)}
                           >
                              <span className="sr-only">Previous</span>
                              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                          {/* } */}

                          <button
                            disabled = {!states.loadMoreCompanies}
                            type="button"
                            className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                            onClick={()=> setOffset(offset+1)}
                          >
                            <span className="sr-only">Next</span>
                            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                          </button>

                        </div>
                      </nav>
                    </div>
                  </div>
                </div>
              :
              <Error origin={'usuários a serem exibidos'}/>
              }

            </div>
          </div>
        </div>
      </main>

      <Footer/>

      <ModalCompany states={states} setters={setters} company={companySelected}/>
      <SpinnerOverlay/>
    </>
  )
}




