
import { useState, useContext, useEffect } from 'react'
import { AuthContext } from "../../Context"
import { Dialog, } from '@headlessui/react'
import { Bars3Icon, } from '@heroicons/react/20/solid'
import { ArrowRightOnRectangleIcon, ArrowLeftOnRectangleIcon } from '@heroicons/react/24/outline'
import { BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useNavigate, Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import { Menu } from '@headlessui/react'


export default function Navbar() {
    const { states, logout, getPath } = useContext(AuthContext)
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const hist = useNavigate()

    const logoutClick = async () => {
        try {
            const result = await logout()
            if (result.status) {
                hist('/')
            }
            else {
                toast.error(result.msg, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
        catch (err) {
            toast.error(err.response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const loginClick = async () => {
        try {
            hist('/')
        }
        catch (err) {

        }
    }

    const [imagem, setImagem] = useState('')

    useEffect(() => {
        constroiLogo()
    }, [])

    const constroiLogo = async () => {
        const origin = await getPath(window)
        setImagem(origin + '/logo.png')
    }

    return (
        <>
            <header className="inset-x-0 top-0 z-50 flex h-16 border-b border-gray-900/10">
                <div className="mx-auto flex w-full max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
                    <div className="flex flex-1 items-center gap-x-6">
                        <button type="button" className="-m-3 p-3 md:hidden" onClick={() => setMobileMenuOpen(true)}>
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon className="h-5 w-5 text-gray-900" aria-hidden="true" />
                        </button>
                        <img
                            className="h-8 w-auto"
                            src={imagem}
                            crossOrigin="anonymous"
                            alt="Your Company"
                        />
                    </div>
                    <nav className="hidden md:flex md:gap-x-11 md:text-sm md:font-semibold md:leading-6 md:text-gray-700">
                        {states.navigation.map((item, itemIdx) => (
                            <Link key={itemIdx} to={item.href}
                                hidden={

                                    (item.name == 'Vendas' && !((localStorage.getItem('permission') == 'Admin') || (localStorage.getItem('permission') == 'Vendedor') || localStorage.getItem('permission') == 'Comum')) ||
                                    (item.name == 'Clientes' && !((localStorage.getItem('permission') == 'Admin'))) ||
                                    (item.name == 'Usuários' && !((localStorage.getItem('permission') == 'Admin'))) ||
                                    (item.name == 'Empresas' && !((localStorage.getItem('permission') == 'Admin'))) ||
                                    (item.name == 'Home' && !((localStorage.getItem('permission') == 'Admin'))) ||
                                    (item.name == 'Produtos' && !((localStorage.getItem('permission') == 'Admin') || (localStorage.getItem('permission') == 'Vendedor') || localStorage.getItem('permission') == 'Comum')) ||
                                    ((localStorage.getItem('permission') == 'Admin') == false && (localStorage.getItem('permission') == 'Vendedor') == false && (localStorage.getItem('permission') == 'Comum') == false)
                                }
                            >
                                {(item.name == 'Vendas' && (localStorage.getItem('permission') == 'Comum')) ? 'Compras' : item.name}
                            </Link>
                        ))}
                    </nav>
                    {/* Profile dropdown */}
                    <div className="flex flex-1 items-center justify-end gap-x-8">
                        <Menu as="div" className="relative ml-3">
                            <div>
                                {((localStorage.getItem('permission') == 'Comum') || (localStorage.getItem('permission') == 'Vendedor') || (localStorage.getItem('permission') == 'Admin')) ?
                                    <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none lg:rounded-md lg:p-2 " onClick={() => logoutClick()}>
                                        <span className="ml-3 hidden text-sm font-medium text-gray-700 md:block">
                                            <span className="sr-only hover:bg-gray-200" />Logout
                                        </span>
                                        <ArrowRightOnRectangleIcon
                                            className="ml-1 hidden h-5 w-5 flex-shrink-0 text-gray-400 md:block"
                                            aria-hidden="true"
                                        />
                                    </Menu.Button>
                                    :
                                    <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none lg:rounded-md lg:p-2" onClick={() => loginClick()}>
                                        <span className="ml-3 hidden text-sm font-medium text-gray-700 md:block">
                                            <span className="sr-only hover:bg-gray-200" />Login
                                        </span>
                                        <ArrowLeftOnRectangleIcon
                                            className="ml-1 hidden h-5 w-5 flex-shrink-0 text-gray-400 md:block"
                                            aria-hidden="true"
                                        />
                                    </Menu.Button>
                                }
                            </div>
                        </Menu>
                    </div>
                </div>
                <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                    <div className="fixed inset-0 z-50" />
                    <Dialog.Panel className="fixed inset-y-0 left-0 z-50 w-full overflow-y-auto bg-white px-4 pb-6 sm:max-w-sm sm:px-6 sm:ring-1 sm:ring-gray-900/10">
                        <div className="-ml-0.5 flex h-16 items-center gap-x-6">
                            <button type="button" className="-m-2.5 p-2.5 text-gray-700" onClick={() => setMobileMenuOpen(false)}>
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                            <div className="-ml-0.5">
                                <a href="#" className="-m-1.5 block p-1.5">
                                    <span className="sr-only">Your Company</span>
                                    <img
                                        className="h-8 w-auto"
                                        src={imagem}
                                        crossOrigin="anonymous"
                                        alt="Your Company"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="mt-2 space-y-2 flex flex-col">
                            {((localStorage.getItem('permission') == 'Comum') || (localStorage.getItem('permission') == 'Vendedor') || (localStorage.getItem('permission') == 'Admin')) ?
                                <>
                                    <Link
                                        key={'logout'}
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-200 border border-gray-300 bg-gray-100"
                                        onClick={() => logoutClick()}
                                    >
                                        Logout
                                    </Link>
                                </>
                                :
                                <Link
                                    key={'login'}
                                    className="-mx-3 block rounded-md px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-200 border border-gray-300 bg-gray-100"
                                    onClick={() => loginClick()}
                                >
                                    Login
                                </Link>
                            }

                            {states.navigation.map((item, itemIdx) => (
                                <Link key={itemIdx} to={item.href}
                                    hidden={
                                        (item.name == 'Vendas' && !((localStorage.getItem('permission') == 'Admin') || (localStorage.getItem('permission') == 'Vendedor') || localStorage.getItem('permission') == 'Comum')) ||
                                        (item.name == 'Clientes' && !((localStorage.getItem('permission') == 'Admin'))) ||
                                        (item.name == 'Usuários' && !((localStorage.getItem('permission') == 'Admin'))) ||
                                        (item.name == 'Empresas' && !((localStorage.getItem('permission') == 'Admin'))) ||
                                        (item.name == 'Home' && !((localStorage.getItem('permission') == 'Admin'))) ||
                                        (item.name == 'Produtos' && !((localStorage.getItem('permission') == 'Admin') || (localStorage.getItem('permission') == 'Vendedor') || localStorage.getItem('permission') == 'Comum')) ||
                                        ((localStorage.getItem('permission') == 'Admin') == false && (localStorage.getItem('permission') == 'Vendedor') == false && (localStorage.getItem('permission') == 'Comum') == false)
                                    }
                                >
                                    {(item.name == 'Vendas' && (localStorage.getItem('permission') == 'Comum')) ? 'Compras' : item.name}
                                </Link>
                            ))}

                        </div>
                    </Dialog.Panel>
                </Dialog>
            </header>
        </>
    )
}